/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { StepperComponent } from '../../../../../../_metronic/assets/ts/components';
import { useToast } from '../../../Toats';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../Loadings/loadingSpan';

import Papa from 'papaparse';
import { postFile } from '../../../../../services/files';
import { postImportedChartsService } from '../../../../../services/influxdb.service';
import { postImportedChartsPortalOne } from '../../../../../services/plantsService';

type Props = {
    show: boolean;
    id: string;
    plantData?: any;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalImportCharts = ({show, handleClose, id, plantData}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingImported, setLoadingImported] = useState(false);
  const [data, setData] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dateImportationMonitor, setDateImportantionMonitor] = useState<any>();

  const [pages, setPages] = useState<number>(0);

  const fileInputRef : any = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo.");

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
    setData({});
  }

  const postImportedChartsFile = async () => {
   try {
    setLoadingImported(true);
    await postImportedChartsService(data);

   } catch (error) {
    console.log(error)
   }finally{
    setLoadingImported(false);
    closed()
   }
  }

  const postImportedChartsPortals = async () => {
    try {
     setLoadingImported(true);
     await postImportedChartsPortalOne({
      idCredential: plantData && plantData.credential && plantData.credential.id ? plantData.credential.id : "",
      plants: [plantData && plantData.plantId ? plantData.plantId : ""]
     });

    } catch (error) {
     console.log(error)
    }finally{
     setLoadingImported(false);
     closed()
    }
   }

  const submit = () => {
    if(pages === 0){
      postImportedChartsFile();
    }else{
      if(pages === 1){
        postImportedChartsPortals();
      }
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `/media/csvs/planilhaDiaria.csv`;
    link.download = 'planilhaDiaria.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
        setTextArquive(file.name);

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
                const data = results.data;

                setDataTable(data);

                const jsonData = {
                    type: "month_charts",
                    plant: {
                        plantId: id
                    },
                    charts: [{}]
                };

                data.forEach((row: any) => {
                    const date = new Date(row.Dia);
                    const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    const day = String(date.getDate()).padStart(2, '0');
                    const key = `${yearMonth}-${day}`;

                    if (!jsonData.charts[0][yearMonth]) {
                        jsonData.charts[0][yearMonth] = {};
                    }

                    jsonData.charts[0][yearMonth][key] = parseFloat(row["Gerado"]);
                });

                setData(jsonData);
            },
            error: function (error) {
                console.error("Erro ao processar o arquivo:", error);
            },
        });
    }
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Importação de dados (geração)</span>
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='flex-row-fluid py-lg-0'>
            <ul className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap`}>
              <li className="nav-item">
                <a
                  className="nav-link active text-active-primary me-6 border-active-primary border-hover-primary"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_01"
                  onClick={() => setPages(0)}
                >
                  Por arquivo
                </a>
              </li>
    
              <li className="nav-item">
                <a
                  className="nav-link text-active-primary me-6 border-active-primary border-hover-primary"
                  data-bs-toggle="tab"
                  href="#kt_tab_pane_02"
                  onClick={() => setPages(1)}
                >
                  Por portal de monitoramento
                </a>
              </li>
            </ul>
            
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane active fade show pt-10"
                id="kt_tab_pane_01"
                role="tabpanel"
              >
                <div style={{marginBottom: '3rem', display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                  
                  <div style={{display: 'flex', gap: '0.7rem', alignItems: 'center'}} className='text-hover-primary'>
                    <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3x' />
                    <h3 className='card-title' style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                      <a 
                        href="#" 
                        onClick={() => {handleDownload()}}
                      >
                        <span className='text-dark text-hover-primary card-label fw-bold fs-4 mb-1'>Planilha de importacao diaria.csv </span>
                      </a>
                      <span className='text-muted mt-1 fw-semibold fs-8'>Faça o download da planilha de dados de geração, <br />ara o preenchimento e importação.</span>
                    </h3>
                    {/* <a href='#' className='text-muted fw-bold text-hover-primary d-block fs-6'>
                      Planilha_Padrão.csv
                    </a> */}
                  </div>

                  <div className='border border-gray-200 border-dashed rounded p-1'>
                    <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '10rem'}}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      {/* <Button 
                        onClick={handleButtonClick}
                      >
                        Escolher Arquivo
                      </Button> */}

                      <a
                        onClick={() =>{handleButtonClick()}}
                        href='#'
                        style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                        className='btn btn-sm btn-light-primary text-hover-white'
                      >
                        <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-3x' />
                        <h3 className='card-title align-items-start flex-column'>
                          {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                          <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                        </h3>
                      </a>
                    </div>
                  </div>

                  <div style={{height: '15rem', overflow: 'auto'}}>
                    <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                      <thead>
                        <tr className='fw-bolder text-dark fs-7 text-uppercase gs-0'>
                          <th className='min-w-200px'>Data</th>
                          <th className='min-w-300px'>Geração (KWh)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dataTable.length === 0 ?
                          <tr>
                            <td colSpan={5}>
                              <div className='d-flex justify-content-center' style={{padding: '1rem'}}>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Sem dados de geração!
                                </span>
                              </div>
                            </td>
                          </tr>
                          :
                          dataTable.map((data: any) => {
                            return (
                              <tr>
                                <td>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {data.Dia ? data.Dia : ""}
                                  </span>
                                </td>
                                <td>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {data.Gerado ? data.Gerado : ""}
                                  </span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            
              <div className="tab-pane fade" id="kt_tab_pane_02" role="tabpanel">
                <div className='fv-row mb-10 mt-10' style={{flex: '1'}}>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='text-muted mt-1 fw-semibold fs-5'>Selecione o período para a exportação: </span>
                    </h3>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Digite um nome para a unidade(filial)'
                    ></i>
                  </label>
                  <input
                    //type='datetime-local'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='dateInit'
                    placeholder=''
                    value={dateImportationMonitor}
                    onChange={(e: any) => {
                      setDateImportantionMonitor(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <button
              type='button'
              className='btn btn-lg btn-light'
              disabled={loading}
              onClick={() => {
                  closed()
              }}
          >
              <KTSVG
                path='/media/icons/duotune/arrows/arr063.svg'
                className='svg-icon-3 ms-2 me-0'
              />
              <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
              
          </button>

          <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={loadingImported}
              onClick={submit}
          >
              Importar dados
              {
                loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
              }
          </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalImportCharts}
