import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import useSize from '../../../hooks/useSize';

interface ToastContextType {
  showToast: boolean;
  toggleToastModal: (message?: string, subMessage?: string, type?: string) => void;
  closeToast: () => void;
}

const ToastContext = createContext<ToastContextType>({
  showToast: false,
  toggleToastModal: () => {},
  closeToast: () => {},
});

const modalsRoot = document.getElementById('root-modals') || document.body;

export const useToastModal = () => {
  return useContext(ToastContext);
};

export const ToastProviderModal = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const stepperRef = useRef(null);
  const {width} = useSize()

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        closeToast();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const toggleToastModal = (message?: string, subMessage?: string, type?: string) => {
    setToastMessage(message || '');
    setToastSubMessage(subMessage || '');
    setToastType(type ? type : '');
    setShowToast(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const truncateText = (text: string, maxLength = 50) =>
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <ToastContext.Provider value={{ showToast, toggleToastModal, closeToast }}>
      {children}
      {createPortal(
        <Modal
          id='toast-modal'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px'
          show={showToast}
          onHide={closeToast}
          backdrop='static'
        >
          <div className='modal-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>
                {toastType === 'success'
                  ? 'Sucesso!'
                  : toastType === 'error'
                  ? 'Erro!'
                  : 'Alerta!'}
              </span>
            </h3>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={closeToast}
            >
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            {/* Exibição visual da mensagem principal e submensagem */}
            <div style={{ marginBottom: '3rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
              <KTSVG
                path={toastType === 'success' ? '/media/icons/duotune/arrows/arr016.svg' : '/media/icons/duotune/arrows/arr017.svg'}
                className={`svg-icon-5x ms-2 me-0 ${toastType === 'success' ? 'svg-icon-success' : 'svg-icon-danger'}`}
              />
              <h3 className='card-title flex-column' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <span className='card-label fw-bold fs-3 mb-1'>{toastMessage}</span>
                <span className='text-muted mt-1 fw-semibold fs-5' style={width < 1200 ?{whiteSpace: 'nowrap', width: '20rem', overflow: 'auto'} : {}}>
                  {toastSubMessage.startsWith('http') ? (
                    <a
                      href={toastSubMessage}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{textDecoration: 'underline'}}
                    >
                      {truncateText(toastSubMessage, 50)}
                    </a>
                  ) : (
                    toastSubMessage
                  )}
                </span>
              </h3>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                type='button'
                className='btn btn-lg btn-light'
                onClick={closeToast}
              >
                Fechar
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
                />
              </button>
            </div>
          </div>
        </Modal>,
        modalsRoot
      )}
    </ToastContext.Provider>
  );
};
