/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css'
import { Plants } from '../../../@types/plants';
import { getplantsAllPagination } from '../../../services/plantsService';
import Map, {FullscreenControl, GeolocateControl, NavigationControl, MapRef} from "react-map-gl"
import { getAlarmStatus } from '../../../services/alarm';
import { ModalPlant } from '../../components/modalPlant';
import './styles.css';
import useSize from '../../../hooks/useSize';
import { useQuery } from 'react-query';
import { AlarmStatus } from '../../../@types/alarmStatus';
import { Loading } from '../../components/Loadings/loading';

import styles from './maps.module.css';
import { MarkerMaps } from './components/MarkerMaps';
import { MenuFilterPlantsPhoneGlobal } from '../../components/MenuFilterPhonePlantsGlobal';
import { MenuFilterPlantsComputerGlobal } from '../../components/MenuFilterPlantsComputerGlobal';
import { PaginationGlobal } from '../../components/PaginationGlobal';

type Props = {
    page: number
}

interface ViewPort {
    latitude: number,
    longitude: number,
    zoom: number,
}

type Paginations = {
    page: number,
    limit: number,
    total: number,
    qtdPages: number
  }
  
  type SetPaginations = {
    start: number;
    end: number;
  }

export function Maps({page}: Props) {
    const [filterAlarms, setFilterAlarms] = useState<number>(-1);
    const mapRef = useRef<MapRef>(null);
    const [myPlant, setMyPlant] = useState<string>("")
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const {width} = useSize();
    const [viewport, setViewport] = useState<ViewPort>({
        latitude: -6.771433,
        longitude: -43.024926,
        zoom: width < 1200 ? 2 : 4,
    });

    const [pagination, setPagination] = useState<Paginations>({
        page: 1,
        limit: 30,
        total: 0,
        qtdPages: 1
      });
  
      const [qtd, setQtd] = useState<SetPaginations>({
        start: 0,
        end: 30,
      });

    const { data: plants = [], isLoading: loadingPlants } = useQuery<Plants[] | []>(
        ["getPlantsMaps", filterAlarms, pagination.page],
        async () => {
          if(filterAlarms === -1){
            const myPlants = await getplantsAllPagination(`?limit=${pagination.limit}&page=${pagination.page}`)
            setPagination({...pagination, qtdPages: myPlants.lastPage, total: myPlants.total})
            
            return myPlants.data.filter((plant: Plants) => plant.plantLat && plant.plantLon);

          }else{
            const url = `?status=${filterAlarms !== -1 && filterAlarms !== -2 ? filterAlarms : ""}&alert=${filterAlarms === -2 ? true : false}&limit=${pagination.limit}&page=${pagination.page}`
            const myPlants = await getplantsAllPagination(url)
            setPagination({...pagination, qtdPages: myPlants.lastPage, total: myPlants.total})

            return myPlants.data.filter((plant: Plants) => plant.plantLat && plant.plantLon)
          }
        },
        {
          onError: (error) => {
            console.log(error);
          },
        }
    )

    const { 
        data: alarmStatus = {
          attention: 0,
          alarmNum: 0,
          normalNum: 0,
          offlineNum: 0,
          total: 0,
          info: 0,
          alertCount: 0
        }

    } = useQuery<AlarmStatus>(
        ["getSomeAlarmStatus"],
        async () => {
            const alarmStatusResult = await getAlarmStatus();
            return alarmStatusResult;
        },
        {
            onError: (error) => {
            console.log(error);
            },
        }
    )

    useEffect(() => {
        if(page === 3){
            if(mapRef.current){
                mapRef.current.resize();
            }
        }
    }, [page]);


    useEffect(() => {
        const start = (pagination.page - 1) * pagination.limit + 1;
        const end = Math.min(pagination.page * pagination.limit, pagination.total);
    
        setQtd({start: start, end: end === 0 ? pagination.limit : end})
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [pagination.page]);

  return (
    <div
        className={`row g-xl-8 card py-5 px-5 ${styles.divGlobalMap}`} 
    >
        <div className={`${styles.divGlobalMapV2}`}>
            <h3 
                className='card-title d-flex align-items-start flex-column'
            >
                <span className={`card-label fw-bold fs-${width < 1200 ? '5' : '3'} mb-1`}>Mapa das Usinas</span>
                <span className={`text-muted fw-semibold fs-7`}>Aproximadamente {plants ? plants.length : 0} usinas localizadas.</span>
            </h3>

            {
                width > 1200 ? 
                    <MenuFilterPlantsComputerGlobal 
                        setFilterAlarms={setFilterAlarms}
                        alarmStatus={alarmStatus}
                    />
                :
                <MenuFilterPlantsPhoneGlobal 
                    setFilterAlarms={setFilterAlarms}
                    alarmStatus={alarmStatus}
                />
            }
                       
        </div>

        <div 
            className={`mt-5 border border-gray-300 border-dashed rounded p-1 ${styles.responsiveHeight}`}
        >
            {
                <Map
                    {...viewport}
                    ref={mapRef}
                    mapLib={import('mapbox-gl')}
                    onMove={evt => setViewport(evt.viewState)}
                    mapboxAccessToken={"pk.eyJ1IjoibGVra293aWxsaWFtdG9ycmVzIiwiYSI6ImNsdThnazlyZDBmcXEya29qMmZndGtmMmkifQ.kwHp_K24hVrkVHWqTE9yEQ"}
                    style={{width: "100%"}}
                    mapStyle={"mapbox://styles/lekkowilliamtorres/clu8gy4e8000e01qt6p0p28hi"}
                >    

                {loadingPlants? 
                <div className={styles.divSpinnerTablePlants}>
                    <Loading myClass={`spinner-border ${styles.spinnerTablePlants}`}/>
                </div>
                :
                    plants &&
                        plants.map((plant: Plants) => {
                            return (
                                <MarkerMaps
                                    key={plant.id} 
                                    plant={plant}
                                    setMyPlant={setMyPlant}
                                    setShowCreateAppModal={setShowCreateAppModal}
                                />
                            )
                            
                        })
                }
                    <NavigationControl />
                    <GeolocateControl />
                    <FullscreenControl />    
                </Map>
            }

        </div>

            <div className='card-footer pt-0 pb-1'>
                <PaginationGlobal 
                    pagination={pagination}
                    setPagination={setPagination}
                    qtd={qtd}
                />
            </div>
            
            <ModalPlant 
                show={showCreateAppModal} 
                handleClose={() => {
                    setMyPlant("")
                    setShowCreateAppModal(false)
                }} 
                plant={myPlant}
            />
    </div>
  );
}