import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { ptBR } from "date-fns/locale";

interface Props {
  selectedDate: Date;
  setSelectedDate: any;
  disabled?: boolean;
}

interface PropsCustomBottom {
  value: any; 
  onClick: any; 
  disabled?: boolean
}

const CustomDatePickerInput = ({ value, onClick, disabled }: PropsCustomBottom) => {
  return (
    <div className="input-group">
      <input
        type="text"
        value={value}
        disabled={disabled !== undefined ? disabled : false}
        className="form-control bg-body"
        onClick={onClick}
        readOnly
      />
      <div className="input-group-append" >
        <span className="input-group-text bg-dark svg-icon svg-icon-1" style={{height: '100%'}}>
          <KTSVG path="/media/icons/duotune/files/fil002.svg" className="svg-icon-muted svg-icon-1hx" />
        </span>
      </div>
    </div>
  );
}

const DateSelector: React.FC<Props> = ({selectedDate, setSelectedDate, disabled}) => {
  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        locale={ptBR}
        customInput={React.cloneElement(
          <CustomDatePickerInput value={selectedDate} onClick={() => {}} />,
          { disabled }
        )}
      />
    </div>
  );
};

export default DateSelector;
