import React, { useState } from 'react';
import {Marker} from "react-map-gl"
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Plants } from '../../../../../@types/plants';
import useSize from '../../../../../hooks/useSize';

type Props = {
    plant: Plants,
    setMyPlant: React.Dispatch<React.SetStateAction<string>>,
    setShowCreateAppModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function MarkerMaps({
    plant,
    setMyPlant,
    setShowCreateAppModal
}: Props) {
    const {width} = useSize();

    function corrigirCoordenada(coordenadaString: string) {
        if (coordenadaString.includes(',')) {
            return coordenadaString.replace(',', '.');
        } else {
            return coordenadaString;
        }
    }

    const [myPosition] = useState({
        lat: plant.plantLat && plant.plantLat !== null && plant.plantLat !== undefined
            ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLat.toString()))) ? -6.771433 : Number(corrigirCoordenada(plant.plantLat.toString())))
            : -6.771433,
        lng: plant.plantLon && plant.plantLon !== null && plant.plantLon !== undefined
            ? (Number.isNaN(Number(corrigirCoordenada(plant.plantLon.toString()))) ? -43.024926 : Number(corrigirCoordenada(plant.plantLon.toString())))
            : -43.024926
    });

    return (
        <Marker
            key={plant.plantId} 
            latitude={myPosition.lat} 
            longitude={myPosition.lng} 
            draggable
        >
            <i 
                title={`${plant.name}`}
                onClick={() => {
                    setMyPlant(plant.id)
                    setShowCreateAppModal(true)
                }} 
            >
                <div className={`symbol ${width < 1200 ? "symbol-40px" : "symbol-70px"} me-5`}>
                    <img
                        src={toAbsoluteUrl(`/media/imgs/plant-${
                            plant.status !== null
                            ? plant.status === 0
                            ? "sucess"
                            : plant.status === 1
                            ? "danger"
                            : plant.status === 2
                            ? "warning"
                            : plant.status === 7 || plant.status === 3
                            ? "info"
                            : "indentify"
                            : "indentify"
                        }.png`)}
                        className=''
                        alt=''
                    />
                </div>
            </i>
        </Marker>
    )
}