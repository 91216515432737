import React, { useEffect, useState } from 'react';
import useSize from '../../../../hooks/useSize';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { postUser } from '../../../../services/usersService';
import { useToast } from '../../../components/Toats';
import { ModalDetailsCreate } from './components/ModalDetailsCreate';

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    corporateName: Yup.string(),
    plano: Yup.string(),
    cnpj: Yup.string(),
    qtd_plants_plano: Yup.number(),
    qtd_plants_gratuita: Yup.number(),
    qtd_plants_plano_starter: Yup.number(),
});

export function AddCompanies({myRefresh, setMyRefresh}: any) {
    const {width} = useSize();
    const [loading, setLoading] = useState<boolean>(false);
    const { toggleToast } = useToast();
    const [showDetailsAppModal, setShowDetailsAppModal] = useState(false);
    const [saveResult, setSaveResult] = useState<any>(null)

  const [initialValues, setInitialValues] = useState<any>({
    name: "Meu Usuário",
    email: "",
    corporateName: "Minha Empresa",
    plano: "",
    cnpj: "",
    qtd_plants_plano: 0,
    qtd_plants_gratuita: 0,
    qtd_plants_plano_starter: 0,
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const AddEmpresaUser = async () => {
    try {
      setLoading(true)
      const myData = {
        name: formik.values.name,
        email: formik.values.email,
        corporateName: formik.values.corporateName,
        plano: "BASE",
        planos: "BASE",
        cnpj: formik.values.cnpj,
        qtd_plants_plano: formik.values.qtd_plants_plano,
        qtd_plants_gratuita: formik.values.qtd_plants_gratuita,
        qtd_plants_plano_starter: formik.values.qtd_plants_plano_starter
      }

      const result = await postUser(myData, "EMPRESA")
      setSaveResult(result)
      toggleToast("Sucesso ao cadastrar a empresa ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar a empresa. ", "error")
    }finally{
      setLoading(false)
      
      formik.setValues(initialValues);
      setMyRefresh((prev: boolean) => !prev);
      setShowDetailsAppModal(true)
    }
  }

  const submit = () => {
    AddEmpresaUser()
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`} style={{paddingBottom: '1rem'}}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-6'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Cadastro de Empresa</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Cadastre uma nova empresa (usuário criado automaticamente)</span>
            </h3>
            <div 
                style={{
                    display: 'flex', 
                    gap: '1rem',  
                    justifyContent: 'space-between', 
                    width: '100%',
                    flexDirection: width > 1200 ? 'row' : 'column'
                }}
            >
            </div>
            
        </div>

        <div className='card-body py-3' style={width < 1200 ? {height: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
            <div className='separator border-gray-200 mb-3'></div>
            <div style={{display: 'flex' , gap: '1rem'}}>
                <div>
                    <h3 className='card-title align-items-start flex-column'>
                        {/* <span className='card-label fw-bold fs-3 mb-1'>Cadastro de Empresa</span> */}
                        <span className='text-muted mt-1 fw-semibold fs-7'>Informações da Empresa</span>
                    </h3>
                    {/* <div className='separator border-gray-200'></div> */}

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='row mb-1'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nome da Empresa</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Minha Empresa'
                                    defaultValue={formik.values.corporateName}
                                    value={formik.values.corporateName}
                                    onChange={formik.handleChange("corporateName")}
                                />
                                {/* {formik.touched.company && formik.errors.company && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.company}</div>
                                </div>
                                )} */}
                            </div>
                        </div>

                        <div className='row mb-1'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='000.000.000/00'
                                    defaultValue={formik.values.cnpj}
                                    value={formik.values.cnpj}
                                    onChange={formik.handleChange("cnpj")}
                                />
                                {/* {formik.touched.company && formik.errors.company && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.company}</div>
                                </div>
                                )} */}
                            </div>
                        </div>                
                    </div>
                </div>

                <div>
                    <h3 className='card-title align-items-start flex-column'>
                        {/* <span className='card-label fw-bold fs-3 mb-1'>Cadastro de Empresa</span> */}
                        <span className='text-muted mt-1 fw-semibold fs-7'>Informações do Usuário Empresa</span>
                    </h3>
                    
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='row'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nome do Usuário</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Proprietário Solar Vision'
                                    defaultValue={formik.values.name}
                                    value={formik.values.name}
                                    onChange={formik.handleChange("name")}
                                />
                                {/* {formik.touched.company && formik.errors.company && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.company}</div>
                                </div>
                                )} */}
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>E-mail do Usuário</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='email'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='email@email.com'
                                    defaultValue={formik.values.email}
                                    value={formik.values.email}
                                    onChange={formik.handleChange("email")}
                                />
                                {/* {formik.touched.company && formik.errors.company && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.company}</div>
                                </div>
                                )} */}
                            </div>
                        </div>            
                    </div>
                </div>

                <div>
                    <h3 className='card-title align-items-start flex-column'>
                        {/* <span className='card-label fw-bold fs-3 mb-1'>Cadastro de Empresa</span> */}
                        <span className='text-muted mt-1 fw-semibold fs-7'>Informações do Plano da Empresa</span>
                    </h3>
                    
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {/* <div className='row mb-3'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Plano</label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    //style={{width: largura < 1200 ? '100%' : '20rem'}}
                                    className='form-select form-select-solid'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    defaultValue={''}
                                    value={formik.values.plano}
                                    onChange={formik.handleChange("plano")}
                                >
                                    <option value={''} disabled>Selecione um plano</option>
                                    <option value={'PREMIUM'}>PREMIUM</option>
                                    <option value={'STARTER'}>STARTER</option>
                                    <option value={'BASE'}>BASE</option>
                                </select>
                            </div>
                        </div> */}
                        <div className='row mb-5'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Qtd. de Usinas PREMIUM</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='0'
                                        defaultValue={formik.values.qtd_plants_plano}
                                        value={formik.values.qtd_plants_plano}
                                        onChange={formik.handleChange("qtd_plants_plano")}
                                        // {...formik.getFieldProps('company')}
                                    />
                                    {/* {formik.touched.company && formik.errors.company && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.company}</div>
                                    </div>
                                    )} */}
                                </div>
                            </div>


                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='row'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Qtd. de Usinas STARTER</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='0'
                                        defaultValue={formik.values.qtd_plants_plano_starter}
                                        value={formik.values.qtd_plants_plano_starter}
                                        onChange={formik.handleChange("qtd_plants_plano_starter")}
                                        // {...formik.getFieldProps('company')}
                                    />
                                    {/* {formik.touched.company && formik.errors.company && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.company}</div>
                                    </div>
                                    )} */}
                                </div>
                            </div>

                            <div className='row'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Qtd. de Usinas BÁSICAS</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='0'
                                        defaultValue={formik.values.qtd_plants_gratuita}
                                        value={formik.values.qtd_plants_gratuita}
                                        onChange={formik.handleChange("qtd_plants_gratuita")}
                                        // {...formik.getFieldProps('company')}
                                    />
                                    {/* {formik.touched.company && formik.errors.company && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.company}</div>
                                    </div>
                                    )} */}
                                </div>
                            </div> 
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {/* <button 
                type='button' 
                onClick={() => {
                    setShowDetailsAppModal(true)
                }} 
                className='btn btn-primary'
            >
                teste
            </button> */}
            
            <button 
                type='button' 
                onClick={() => {
                    submit()
                    //setMyRefresh((prev: boolean) => !prev);
                }} 
                className='btn btn-primary'
            >
                {!loading && 'Salvar Empresa'}
                {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
            </button>
        </div>

        <ModalDetailsCreate 
          show={showDetailsAppModal}
          data={saveResult ? saveResult : undefined}
          handleClose={() => {
            setSaveResult(null)
            setShowDetailsAppModal(false)
            setMyRefresh((prev: boolean) => !prev);
          }} 
        />
    </div>
  );
}