/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'


import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
//import { defaultCreateAppData, ICreateAppData } from '../../../../../_metronic/partials/modals/create-app-stepper/IAppModels'

//import { postCredentialVerificad } from '../../../../services/credentialService'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { getCompanyAll } from '../../../../services/companyService'
import { postUnitBranch, updateUnitBranch } from '../../../../services/unitBranchService'
import { StepModal } from './components/stepModal'

type DataForm = {
  emailCompany: string | null,
  dateOfBirth: string | null,
  companyName: string | null,
  cnpj: string | null,
  telephone: string | null,
  totalEmployees: number | null,
  site: string | null,
  zipcode: string | null,
  street: string | null,
  number: string | null,
  district: string | null,
  city: string | null,
  state: string | null,
  country: string | null,
  idCompany: string | null,
  idUser: string | null
  idsPlants: string[]
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  emailCompany: Yup.string().email(),
  dateOfBirth: Yup.string(),
  companyName: Yup.string().required("Informe um nome para a filial"),
  cnpj: Yup.string(),
  telephone: Yup.string(),
  totalEmployees: Yup.number(),
  site: Yup.string(),
  zipcode: Yup.string(),
  street: Yup.string(),
  number: Yup.string(),
  district: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  idCompany: Yup.string(),
  idUser: Yup.string(),
  idPlants: Yup.array()
  .of(Yup.string())
  .notRequired()
  .default([]),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUnitBranch = ({show, handleClose, data, id}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState<DataForm>({
    emailCompany: null,
    dateOfBirth: null,
    companyName: null,
    cnpj: null,
    telephone: null,
    totalEmployees: 0,
    site: null,
    zipcode: null,
    street: null,
    number: null,
    district: null,
    city: null,
    state: null,
    country: null,
    idCompany: null,
    idUser: null,
    idsPlants: []
  })
  const [hasError, setHasError] = useState(false)
  //const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.companyName /*|| !formik.values.emailCompany || !formik.values.cnpj*/) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postUnitBranchSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        emailCompany: formik.values.emailCompany,
        dateOfBirth: formik.values.dateOfBirth,
        companyName: formik.values.companyName,
        cnpj: formik.values.cnpj,
        telephone: formik.values.telephone,
        totalEmployees: formik.values.totalEmployees,
        site: formik.values.site,
        zipcode: formik.values.zipcode,
        street: formik.values.street,
        number: formik.values.number,
        district: formik.values.district,
        city: formik.values.city,
        state: formik.values.state,
        country: formik.values.country,
        idCompany: formik.values.idCompany,
        idUser: formik.values.idUser
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await postUnitBranch(myData)
      toggleToast("Sucesso ao cadastrar a filial. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar filial. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 2000);
    }
  }

  const updateUnitBranchSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        emailCompany: formik.values.emailCompany,
        dateOfBirth: formik.values.dateOfBirth,
        companyName: formik.values.companyName,
        cnpj: formik.values.cnpj,
        telephone: formik.values.telephone,
        totalEmployees: formik.values.totalEmployees,
        site: formik.values.site,
        zipcode: formik.values.zipcode,
        street: formik.values.street,
        number: formik.values.number,
        district: formik.values.district,
        city: formik.values.city,
        state: formik.values.state,
        country: formik.values.country,
        idCompany: formik.values.idCompany,
        idUser: formik.values.idUser,
        idsPlants: formik.values.idsPlants
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateUnitBranch(myData, id)
      toggleToast("Sucesso ao atualizar a filial. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar filial. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 3000);
    }
  }

  const getCompany = async () => {
    try {
      const myCompany = await getCompanyAll()
      formik.setFieldValue("idCompany", myCompany[0].id)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar empresa vinculada. ", "error")
    }
  }

  const submit = () => {
    if(id){
      updateUnitBranchSubmit(id)
    }else{
      postUnitBranchSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(show === true){
      const searhCompany = async () => {
        await getCompany()
      }
      searhCompany()
    }
    if(data){
      const myObject = {
        emailCompany: data.emailCompany ? data.emailCompany :  null,
        dateOfBirth: data.dateOfBirth ? data.dateOfBirth : null,
        companyName: data.companyName ? data.companyName : null,
        cnpj: data.cnpj ? data.cnpj : null,
        telephone: data.telephone ? data.telephone : null,
        totalEmployees: data.totalEmployees ? data.totalEmployees : 0,
        site: data.site ? data.site : null,
        zipcode: data.zipcode ? data.zipcode : null,
        street: data.street ? data.street : null,
        number: data.number ? data.number : null,
        district: data.district ? data.district : null,
        city: data.city ? data.city : null,
        state: data.state ? data.state : null,
        country: data.country ? data.country : null,
        idCompany: data.idCompany ? data.idCompany : null,
        idUser: data.idUser ? data.idUser : null,
        idsPlants: data.idsPlants ? data.idsPlants : []
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Unidade Filial {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal 
                data={initialValues} 
                updateData={updateData} 
                hasError={hasError} 
                formik={formik}
              />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  {
                    // <div className='fv-plugins-message-container'>
                    //   <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    //     o CNPJ é obrigatório!
                    //   </div>
                    // </div>
                  }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUnitBranch}
