/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { Loading } from '../components/Loadings/loading'
import { getplantsAll, getplantsOne } from '../../services/plantsService'
import { getReportsAll } from '../../services/reports'
import { StatisticsMenuCompany } from './StatisticsMenu/StatisticsMenuCompany'
import { StatisticsMenuUnitBranch } from './StatisticsMenu/StatisticsMenuUnitBranch'
import { StatisticsMenuClient } from './StatisticsMenu/StatisticsMenuClient'
import { StatisticsMenuAdm } from './StatisticsMenu/StatisticsMenuAdm'
import useSize from '../../hooks/useSize'
import { useQuery } from 'react-query'
import { PaginationDashboard } from './PaginationDashboard'
import { Plants } from '../../@types/plants'

import styles from './dashboard.module.css';

const DashboardPage: FC = () => {
  const [loadingPlants, setLoadingPlants] = useState<boolean>(true);
  const [idPlantSelected, setIdPlantSelected] = useState<string | null>(null);
  const user = useAuth();
  
  const { data: dataFilter, isLoading, refetch: refreshPlantsFilter } = useQuery<Plants[]>(
    "getPlantsFilter",
    async () => {
      const plants = await getplantsAll();
      return plants;
    },
    {
      enabled: Boolean(
        user.currentUser?.role &&
        user.currentUser.role.slug === "CLIENTE" &&
        user.currentUser?.client
      ),
      onSuccess: (data) => {
        if (data && data.length > 0) {
          handleSeletedPlant(data[0].id)
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  )

  const { data: myPlant, isLoading: loadingCharts } = useQuery<Plants>(
    ["getMyPlant", idPlantSelected],
    async () => {
      if(idPlantSelected){
        const myPlant = await getplantsOne(idPlantSelected ? idPlantSelected : "")
        return myPlant;
      }
    },
    {
      enabled: Boolean(
        user.currentUser?.role &&
        user.currentUser.role.slug === "CLIENTE" &&
        user.currentUser?.client
      ) && !!idPlantSelected,

      onError: (error) => {
        console.log(error);
      },
    }
  )
  
  const { data: myReport = [], isLoading: loadingReport } = useQuery(
    ["getMyReport", idPlantSelected],
    async () => {
      if(idPlantSelected){
        const myReport = await getReportsAll(
          'GERADO',
          new Date(),
          idPlantSelected ? idPlantSelected : ""
        )
        return myReport;
      }
    },
    {
      enabled: Boolean(
        user.currentUser?.role &&
        user.currentUser.role.slug === "CLIENTE" &&
        user.currentUser?.client
      ) && !!idPlantSelected,

      onError: (error) => {
        console.log(error);
      },
    }
  )

  const handleSeletedPlant = (plant : string) => {
    if(plant){
      setIdPlantSelected(plant)
    }
  }

  const refresh = async () => {
    refreshPlantsFilter()
  }

  if (user.currentUser?.role.slug === "CLIENTE" && isLoading) {
    return (
      <div className={styles.spinnerLoadingDashboard}>
        <Loading myClass={`spinner-border ${styles.spinnerDashboard}`}/>
      </div>
    );
  }

  return (
    <div className='divGlobal'>
      {
        user && user.currentUser && user.currentUser.role && user.currentUser.role.slug === "EMPRESA" ?
          <StatisticsMenuCompany 
            company={
              user.currentUser.role 
              && user.currentUser.role.slug === "EMPRESA"
              && user.currentUser.companies
              && user.currentUser.companies.length > 0
              ? user.currentUser.companies[0] : null
            } 
          />
        : user && user.currentUser && user.currentUser.role && user.currentUser.role.slug === "FILIAL" ?
            <StatisticsMenuUnitBranch 
              unitBranch={
                user.currentUser.role 
                && user.currentUser.role.slug === "FILIAL"
                && user.currentUser.unitBranchs
                && user.currentUser.unitBranchs.length > 0
                ? user.currentUser.unitBranchs[0] : null
              } 
            />
        : user && user.currentUser && user.currentUser.role && user.currentUser.role.slug === "CLIENTE" ?
            <StatisticsMenuClient 
              client={
                user.currentUser.role 
                && user.currentUser.role.slug === "CLIENTE"
                && user.currentUser.client
                ? user.currentUser.client : null
              }
            />
        :
          <StatisticsMenuAdm 
            userAny={
              user.currentUser ?
              {
                email: user.currentUser.email ? user.currentUser.email : "", 
                name: user.currentUser.name ? user.currentUser.name : "",
                role:  user.currentUser.role.slug ? user.currentUser.role.slug : ""
              } : null
            }
          />
      }
      <PaginationDashboard 
        loadingPlants={loadingPlants}
        setLoadingPlants={setLoadingPlants}
        myPlant={myPlant}
        loadingCharts={loadingCharts}
        dataFilter={dataFilter ? dataFilter : []}
        idPlantSelected={idPlantSelected}
        handleSeletedPlant={handleSeletedPlant}
        loadingReport={loadingReport}
        refresh={refresh}
        myReport={myReport}
      />
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const {width} = useSize();

  return (
    <div>
      <div className={styles.divGlobalPrincipal}>
        {/* {intl.formatMessage({id: 'MENU.DASHBOARD'})} */}
        {width > 1200 && 
          <PageTitle breadcrumbs={[]}>
            Painel de Controle
          </PageTitle>
        }
      </div>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
