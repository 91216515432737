/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {KTSVG} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import { useNavigate } from 'react-router-dom'

type Props = {
  className: string;
  chartSize?: number;
  chartLine?: number;
  chartRotate?: number;
  requestServices: any;
  type: string;
  largura?: any
}

const CardsRequestService: FC<Props> = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  requestServices,
  type,
  largura
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const navigate = useNavigate()

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  return (
    <div 
      className={`card card-flush ${className}`} 
      style={{
        display: 'flex',  
        minHeight: largura && largura < 1200 ? '' : '8rem', 
        width: largura && largura < 1200 ? '100%' : '',
        padding: '0rem !important',
        paddingTop: '0rem !important',
        flex: "1"
      }}
    >
      <div className={largura && largura < 1200 ? 'card-header p-3' : "card-header pt-5"}  
        style={{gap: type === 'cell' ? '0rem' : '3rem', paddingLeft: largura < 1200 ? '1rem' : '2rem', minHeight: '0rem'}}>
        
        {largura && largura < 1200 ? <></> : 
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center' style={{gap: '0.5rem'}}>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}
            <KTSVG 
              path={'/media/icons/duotune/communication/com007.svg'} 
              className={`svg-icon-body svg-icon-2x ms-n1`} 
            />
            <span className={`fs-${largura && largura < 1200 ? '5' : '1'} fw-bold text-dark me-2 lh-1 ls-n2`}>{requestServices && requestServices.total ? requestServices.total : 0}</span>
          </div>
          {type === 'desk' ? <span className={`text-gray-400 pt-1 fw-semibold fs-${largura && largura < 1200 ? '8' : '6'}`}>Solicitações de Serviços</span> : <></>}
        </div>}

        {
          type === "cell" ?
          (
            <div style={{width: '100%'}}>
              <div
                className='border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
                style={{width: '100%'}}
              >
                <div className='card-title m-0' style={{width: '100%'}}>
                  {largura && largura < 1200 ? 
                  <div className=' d-flex flex-column' style={{width: '100%'}}>
                    <div className='d-flex align-items-center' style={{width: '100%', gap: '2rem', justifyContent: 'space-between'}}>
                
                      <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <KTSVG 
                          path={'/media/icons/duotune/communication/com007.svg'} 
                          className={`svg-icon-body svg-icon-2x ms-n1`} 
                        />

                        <span className={`fs-${largura && largura < 1200 ? '5' : '1'} fw-bold text-dark me-2 lh-1 ls-n2`}>{requestServices && requestServices.total ? requestServices.total : 0}</span>
                        <span className={`text-gray-400 pt-1 fw-semibold fs-${largura && largura < 1200 ? '8' : '6'}`}>Solicitações de Serviços</span>
                      </div>
                      

                      <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        style={{padding: '0.3rem', display: 'flex', gap: '', alignItems: 'center'}}
                        //disabled={loading}
                        onClick={() => {
                            //closed()
                            navigate('/storeServices/1')
                        }}
                        //data-kt-stepper-action='submit'
                        >
                          <span className='fs-7'>Solicitar</span>
                          <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-4 ms-2 me-0'
                          />
                        
                    
                      </button>
                    </div>
                  </div> :
                  <span className={`text-gray-400 fw-semibold fs-${largura && largura < 1200 ? '8' : '6'}`} style={{cursor: 'pointer'}}>Solicitações de Serviços</span>
                  }

                </div>
              </div>

              <div id='kt_account_profile_details' className='collapse'>
                  <div className='border-top' style={{paddingTop: '1rem'}}>
                    <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                      <div className='d-flex fw-semibold align-items-center'>
                        <div className='bullet w-8px h-3px rounded-2 bg-info me-3'></div>
                        <div className={`text-gray-500 flex-grow-1 me-4 fs-${largura && largura < 1200 ? '9' : ''}`}>PENDENTE</div>
                        <div className={`fw-bolder text-gray-700 text-xxl-end fs-${largura && largura < 1200 ? '9' : ''}`}>{requestServices && requestServices.pendente ? requestServices.pendente : 0}</div>
                      </div>
                      <div className='d-flex fw-semibold align-items-center my-3'>
                        <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                        <div className={`text-gray-500 flex-grow-1 me-4 fs-${largura && largura < 1200 ? '9' : ''}`}>ATENDIDO</div>
                        <div className={`fw-bolder text-gray-700 text-xxl-end fs-${largura && largura < 1200 ? '9' : ''}`}>{requestServices && requestServices.atendido ? requestServices.atendido : 0}</div>
                      </div>
                      <div className='d-flex fw-semibold align-items-center'>
                        {/* <div
                          className='bullet w-8px h-3px rounded-2 me-3'
                          style={{backgroundColor: '#E4E6EF'}}
                        ></div> */}
                        <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
                        <div className={`text-gray-500 flex-grow-1 me-4 fs-${largura && largura < 1200 ? '9' : ''}`}>CANCELADO</div>
                        <div className={`fw-bolder text-gray-700 text-xxl-end fs-${largura && largura < 1200 ? '9' : ''}`}>{requestServices && requestServices.cancelado ? requestServices.cancelado : 0}</div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          ) :
          (
            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-info me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>PENDENTE</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{requestServices && requestServices.pendente ? requestServices.pendente : 0}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center my-3'>
                <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>ATENDIDO</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{requestServices && requestServices.atendido ? requestServices.atendido : 0}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center'>
                {/* <div
                  className='bullet w-8px h-3px rounded-2 me-3'
                  style={{backgroundColor: '#E4E6EF'}}
                ></div> */}
                <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>CANCELADO</div>
                <div className=' fw-bolder text-gray-700 text-xxl-end'>{requestServices && requestServices.cancelado ? requestServices.cancelado : 0}</div>
              </div>
            </div>
          )
        }
      </div>

      {/* <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        

      </div> */}
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle(getCSSVariableValue('--bs-info'), options.lineWidth, 100 / 100)
  drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 150)
  drawCircle(getCSSVariableValue('--bs-danger'), options.lineWidth, 100 / 250)
}

export {CardsRequestService}
