/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";

//import * as Yup from "yup";
//import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
import { getUsersAllRole } from "../../../../../../services/usersService";
import { Loading } from "../../../../../components/Loadings/loading";
import { ListPlantsLinked } from "../PlantsLinked";


const StepModal = ({hasError, formik}: any) => {
  //const [unitBranch, setUnitBranch] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  
  const getUsersAllFilial = async () => {
    try {
      setLoading(true)
      const users = await getUsersAllRole("FILIAL")
      setUsers(users)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de usuarios. ", "error")
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsersAllFilial()
  }, []);

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>
        {/*begin::Form Group */}
        
        <div style={{display: 'flex', gap: '1rem'}}>
          
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Name</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='companyName'
              placeholder=''
              value={formik.values.companyName}
              onChange={formik.handleChange("companyName")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para a filial
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>E-mail</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um e-mail para unidade(filial)'
              ></i>
            </label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='email'
              placeholder='name@example.com'
              value={formik.values.emailCompany}
              onChange={formik.handleChange("emailCompany")}
              
            />
            {/* {!formik.values.emailCompany && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  O e-mail é obrigatório!
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>CNPJ</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o CNPJ da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='cnpj'
              placeholder='00.000.000/0000-00'
              value={formik.values.cnpj}
              onChange={formik.handleChange("cnpj")}
            />
            {/* {!formik.values.cnpj && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  o CNPJ é obrigatório!
                </div>
              </div>
            )} */}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Telefone</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um telefone para a Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='telephone'
              placeholder='(00) 0 0000-0000'
              value={formik.values.telephone}
              onChange={formik.handleChange("telephone")}
            />
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Data de Abertura</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite uma data de abertura da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              name='dateOfBirth'
              placeholder=''
              value={formik.values.dateOfBirth}
              onChange={formik.handleChange("dateOfBirth")}
            />
          </div>
        </div>

        <div style={{display: "flex", gap: "1rem"}}>

          {/* <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Total de Funcionarios</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o CNPJ da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='senha'
              placeholder=''
              value={data.senha}
              onChange={(e) =>
                updateData({
                  senha: e.target.value
                })
              }
            />
            {!data.senha && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  a senha é obrigatória!
                </div>
              </div>
            )}
          </div> */}

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Site</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o site da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='url'
              className='form-control form-control-lg form-control-solid'
              name='site'
              placeholder='www.exemple.com'
              value={formik.values.site}
              onChange={formik.handleChange("site")}
            />
          </div>
        </div>

        <div style={{marginBottom: '4rem', marginTop: '2rem'}}>
          <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
              <span className='card-label fw-bold fs-3 mb-1'>Localidade {" "}</span>
              <span className='text-muted mt-1 fw-semibold fs-5'>Algumas informações sobre a localidade da unidade (filial)</span>
          </h3>
        </div>

        
        <div style={{display: 'flex', gap: '1rem'}}>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Rua</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite a rua da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='street'
              placeholder='Av. Exemple'
              value={formik.values.street}
              onChange={formik.handleChange("street")}
            />
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>CEP</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o CEP da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='zipcode'
              placeholder='0000-0000'
              value={formik.values.zipcode}
              onChange={formik.handleChange("zipcode")}
            />
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Número</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o número do local da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='number'
              placeholder='000'
              value={formik.values.number}
              onChange={formik.handleChange("number")}
            />
          </div>
        </div>


        <div style={{display: 'flex', gap: "1rem"}}>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span >Cidade</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite a cidade da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='city'
              placeholder='exemple'
              value={formik.values.city}
              onChange={formik.handleChange("city")}
            />
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Estado</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite estado da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='state'
              placeholder='exemple'
              value={formik.values.state}
              onChange={formik.handleChange("state")}
            />
          </div>

          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>País</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite o país da Unidade (Filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='country'
              placeholder='exemple'
              value={formik.values.country}
              onChange={formik.handleChange("country")}
            />
          </div>
        </div>

        <div style={{marginBottom: '4rem', marginTop: '2rem'}}>
          <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
              <span className='card-label fw-bold fs-3 mb-1'>Responsáveis {" "}</span>
              <span className='text-muted mt-1 fw-semibold fs-5'>Usuário vinculado à unidade filial</span>
          </h3>
        </div>

        <div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Lista de Usuarios</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formik.values.idUser ? formik.values.idUser : '1'}
                onChange={formik.handleChange("idUser")}
              >
                <option value={'1'}></option>
                {users && users.map((myUsers: any) => {
                  return (
                    <option key={myUsers.id} value={myUsers.id}>{myUsers.name}</option>
                  )
                })}
                
              </select>
              {/* {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione a unidade filial!
                </div>
              </div>
            )} */}
            </div>
          </div>
        </div>

        <div>
          <ListPlantsLinked 
            formik={formik}
            className="bg-light"
          />
        </div>

        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {StepModal}
function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

