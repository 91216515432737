/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../../../../../../../../_metronic/helpers';


type Props = {
  className: string;
  unitBranchs: any[]
  formik: any;
}

const ListUnitBranchLinked: React.FC<Props> = ({className, unitBranchs, formik}) => {
  
  const updateData = (value: string, checked: boolean) => {
    let valuesFormik = formik.values.idsUnitBranchs
    if(checked === true){
      valuesFormik.push(value)
    }else{
      valuesFormik = valuesFormik.filter((id: string) => id !== value)
    }
    formik.setFieldValue('idsUnitBranchs', valuesFormik)
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Lista de Unidades (Filiais)</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Quais unidades terão essa regra. </span>
        </h3>

      </div>
      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {
          unitBranchs && unitBranchs.length > 0 ?
          unitBranchs.map((unitBranch: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm009.svg')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {unitBranch.companyName}
                      </a>
                      <span className='text-muted fw-semibold'>{unitBranch.city ? unitBranch.city : "Não localizado."}, {unitBranch.state ? unitBranch.state : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-primary form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idsUnitBranchs.some((id: string) => id === unitBranch.id)  ? 'bg-primary' : 'bg-secondary'}`} 
                      type='checkbox'
                      checked={formik.values.idsUnitBranchs.some((id: string) => id === unitBranch.id) ? true : false}
                      value={unitBranch.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                    />
                  </div>
                </div>
              )
            })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há unidades vinculadas.</span>
            </h3>
          </div>
        }
      </div>
    </div>
  )
}

export {ListUnitBranchLinked}
