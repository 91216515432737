import React, { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';

import JSZip from "jszip";
import { formatarData } from '../../../../../../../../utils/date';
import { postConvertPdfV3 } from '../../../../../../../../services/ConvertPDFService';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { uploadDocumentInvoice } from '../../../../../../../../services/uploadService';
import { useAuth } from '../../../../../../../../modules/auth';
import { useToast } from '../../../../../../../components/Toats';
import { getUnitBranchAll } from '../../../../../../../../services/unitBranchService';
import { FormGenerateTemplate } from './components/FormGenerateTemplate';
import { FormAtualizationTemplate } from './components/FormAtualizationsTemplate';
import { generateHexSequence } from '../../../../../../../../utils/generateNumberHex';
import { getInvoicePDFFile } from '../../../../../../../../services/invoices';
import { postTemplateOSOne, putTemplateOSAll } from '../../../../../../../../services/templateOS';

type Props = {
    formik: any;
    setLinkPDF: any;
    initialHtml: any;
    setMyTemplate: any;
    mySelectTemplate: any
    myTemplates: any;
    refresh: any
    setLoadingDocs: any
}


const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  idUnitBranch: Yup.string()
});

export function DocumentLayout({formik, setLinkPDF, initialHtml, setMyTemplate, mySelectTemplate, myTemplates, refresh, setLoadingDocs}: Props) {
    const [loadingUploadWord, setLoadingUploadWord] = useState(false)
    const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo (.docx).");
    const fileInputRef : any = useRef(null);
    const [generateTemplate, setGenerateTemplate] = useState(false);
    const [atualizationTemplate, setAtualizationTemplate] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
    const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
    const user = useAuth()
    const { toggleToast } = useToast();

    const [initialValues] = useState<any>({
      textHTML: initialHtml,
      description: null,
      type: "private",
      typeDoc: "docx",
      titleDocx: null,
      linkDocx: null,
      idUnitBranch : null
    })

    const formikTemplate = useFormik({
      initialValues,
      validationSchema,
      validateOnChange: false,
      onSubmit: () => {},
    });
  
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
    
    const processDocx = async (fileOrUrl: any, placeholders: any) => {
      try {
        setLoadingDocs(true)

        let file;
    
        if (typeof fileOrUrl === "string") {
          const response: any = await getInvoicePDFFile(fileOrUrl);
          if (response) {
            const blob = response.data;
            file = new File([blob], mySelectTemplate && mySelectTemplate.titleDocx ? mySelectTemplate.titleDocx : "original-document.docx", { type: blob.type });
          }

        } else {
          file = fileOrUrl;

          const formData = new FormData();
          const key = `template-${generateHexSequence(10)}.docx`; 
          formData.append("file", file);
          formData.append('key', key);
          
          const upload_file = await uploadDocumentInvoice(formData)
      
          if(upload_file && upload_file.success === true){
            formikTemplate.setValues({
              ...formikTemplate.values, linkDocx: key});
          }
        }

        if(file){
          const fileName = file.name;
          setTextArquive(fileName);
        }
    
        const arrayBuffer = await file.arrayBuffer();
        const zip: any = await JSZip.loadAsync(arrayBuffer);
    
        const documentXml = await zip.file("word/document.xml").async("string");
        //let updatedXml = documentXml;
        // let updatedXml = documentXml.replace(/\u00A0/g, " ");

        // console.log('teste: ',documentXml.match(/\{\{.*?\}\}/g));

        let cleanedXml = documentXml.replace(/<w:t[^>]*>({{)?<\/w:t>\s*<w:r[^>]*><w:t[^>]*>(.*?)<\/w:t>\s*<w:r[^>]*><w:t[^>]*>(}})?<\/w:t>/g, "{{$2}}");
        cleanedXml = cleanedXml.replace(/<w:proofErr[^>]*>/g, "");

        for (const [placeholder, value] of Object.entries(placeholders)) {
          const regex = new RegExp(`\\{\\{\\s*${placeholder}\\s*\\}\\}`, "g");
          cleanedXml = cleanedXml.replace(regex, value);
        }
    
        zip.file("word/document.xml", cleanedXml);
        const updatedDocx = await zip.generateAsync({ type: "blob" });
    
        const pdfFormData = new FormData();
        pdfFormData.append("file", updatedDocx, "processed-document.docx");
    
        const response = await postConvertPdfV3(pdfFormData);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
    
        setLinkPDF(url);
        
        console.log("Documento processado e convertido para PDF com sucesso!");
      } catch (error) {
        console.error("Erro ao processar o documento:", error);
      }
      setLoadingDocs(false)
    };

    const substituteValues = async (file: any) => {
      setLoadingUploadWord(true);
      if (file) {
        try {
          let placeholderData = {
            corporateName: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.company && formik.values.unitBranch.company.corporateName ? formik.values.unitBranch.company.corporateName : "",
            cnpj: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.company && formik.values.unitBranch.company.cnpj ? formik.values.unitBranch.company.cnpj : "",
            street: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.street ? formik.values.unitBranch.street : "",
            district: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.district ? formik.values.unitBranch.district : "",
            number: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.number ? formik.values.unitBranch.number : "",
            telephone: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.telephone ? formik.values.unitBranch.telephone : "",
            emailCompany: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.emailCompany ? formik.values.unitBranch.emailCompany : "",
            number_order_service: formik && formik.values && formik.values.number_order_service ? formik.values.number_order_service : "",
            dateCreate: formik && formik.values && formik.values.created_at ? formatarData(formik.values.created_at) : "",
            nameResponsible: formik && formik.values && formik.values.client && formik.values.client.nameResponsible ? formik.values.client.nameResponsible : "",
            cpfResponsible: formik && formik.values && formik.values.client && formik.values.client.cpfResponsible ? formik.values.client.cpfResponsible : "",
            telephoneClient: formik && formik.values && formik.values.client && formik.values.client.telephone ? formik.values.client.telephone : "0000-000",
            cellphoneClient: formik && formik.values && formik.values.client && formik.values.client.cellphone ? formik.values.client.cellphone : "(00) 0 0000-0000",
            emailClient: formik && formik.values && formik.values.client && formik.values.client.email ? formik.values.client.email : "",
            namePlant: formik && formik.values && formik.values.plant && formik.values.plant.name ? formik.values.plant.name : "",
            addressPlant: formik && formik.values && formik.values.plant && formik.values.plant.address ? formik.values.plant.address : "",
            titulo: formik && formik.values && formik.values.title ? formik.values.title : "",
            status: formik && formik.values && formik.values.status ? formik.values.status : "",
            description: formik && formik.values && formik.values.description ? formik.values.description : "",
            companyName: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.companyName ? formik.values.unitBranch.companyName : "",
            
            value_sub_final: formik && formik.values.value_sub_final && Number(formik.values.value_sub_final) > 0 
            ? formik.values.value_sub_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
            : '0,00',
          
            value_discont: formik && formik.values.value_discont && Number(formik.values.value_discont) > 0 
            ? formik.values.value_discont.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
            : '0,00',
    
            value_final: formik && formik.values.value_final && Number(formik.values.value_final) > 0 
                ? formik.values.value_final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                : '0,00',
            
            // value_sub_final: formik && formik.values && formik.values.value_sub_final ? formik.values.value_sub_final : "0",
            // value_discont: formik && formik.values && formik.values.value_discont ? formik.values.value_discont : "0",
            // value_final: formik && formik.values && formik.values.value_final ? formik.values.value_final : "0",
            
            ...(
                formik &&
                formik.values &&
                formik.values.itensServices
                    ? formik.values.itensServices.reduce((acc, item, index) => {
                        const idx = index + 1;
                        return {
                            ...acc,
                            [`itemDescription${idx}`]: item && item.description ? item.description : "",
                            [`itemService${idx}`]: item && item.tipo === "SERVICO" && item.typeService && item.typeService.description ? item.typeService.description : "",
                            [`itemProduct${idx}`]: item && item.tipo === "PRODUTO" && item.product && item.product.description ? item.product.description : "",
                            [`itemValue${idx}`]: item && item.value && Number(item.value) > 0 
                            ? item.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                            : '0,00',
                            [`itemAmount${idx}`]: item && item.amount ? item.amount : "",
                            [`itemSubValue${idx}`]: item && item.sub_value && Number(item.sub_value) > 0 
                            ? item.sub_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                            : '0,00',
                        };
                    }, {})
                    : {}
            )
          };
  
          await processDocx(file, placeholderData);
        } catch (error) {
          console.error("Erro ao processar o documento:", error);
        }finally{
          setLoadingUploadWord(false);
        }
      }
    }

      // const convertDocxToPdf = async (docxBlob) => {
      //   return new Promise((resolve, reject) => {
      //     const file = new File([docxBlob], "temp.docx");
    
      //     convert(file, "temp.pdf", (err, result) => {
      //       if (err) {
      //         console.error("Erro ao converter para PDF:", err);
      //         reject(err);
      //       } else {
      //         resolve(new Blob([result], { type: "application/pdf" }));
      //       }
      //     });
      //   });
      // };

    const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        await substituteValues(file)
    };

    const postTemplate = async () => {
      try {
        setLoading(true)
        const myData = {
          textHTML: "<div >Crie o seu template ou escolha um template disponivel. </div>",
          description: formikTemplate.values.description,
          type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
          typeDoc: "docx",
          titleDocx: formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : null,
          linkDocx: formikTemplate.values.linkDocx ? formikTemplate.values.linkDocx : null,
          //type_template: formik.values.type_template,
          idUnitBranch : formikTemplate.values.idUnitBranch
        }
   
        const myTemplate = await postTemplateOSOne(myData)
        //setInitialHtml(myTemplate.textHTML)
  
        if(myTemplate){
          let formik_templates = formik.values.idsTemplates;
          const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
  
          const index = result.findIndex(template => template.type_template === myTemplate.type_template);
          if(index === -1){
            formik_templates.push(myTemplate.id)
          }else{
            formik_templates[index] = myTemplate.id
          }
          formik.setValues({...formik.values, idsTemplates: formik_templates})
  
          //formikReport.setValues({...formik.values, idTemplate: template.id})
  
          setMyTemplate(myTemplate)
        }
  
        toggleToast("Sucesso ao cadastrar o template. ", "success")
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao cadastrar template. ", "error")
      }finally{
        setLoading(false)
        refresh()
        setGenerateTemplate(false)
      }
    }

    const putTemplate = async (id: string) => {
      try {
        setLoading(true)
        const myData = {
          description: formikTemplate.values.description,
          titleDocx: formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : null,
          linkDocx: formikTemplate.values.linkDocx ? formikTemplate.values.linkDocx : null,
          idUnitBranch : formikTemplate.values.idUnitBranch
        }
   
        await putTemplateOSAll(id, myData)
        toggleToast("Sucesso ao atualizar o template. ", "success")
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao atualizar o template. ", "error")
      }finally{
        setLoading(false)
        setAtualizationTemplate(false)
        refresh()
      }
    }

    const getAllUnitBranchs = async () => {
      try {
        setLoadingUnitBranchs(true)
        const unitBranchs = await getUnitBranchAll()
        setUnitBranchs(unitBranchs)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar a lista de unidades. ", "error")
  
      }finally{
        setLoadingUnitBranchs(false)
      }
    }

    const submit = (type: number) => {
      if(type === 1){
        if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
          putTemplate(mySelectTemplate.id)
        }
      }else if (type === 0){
        postTemplate()
      }
    }

    useEffect(() => {
      if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
        const data = {
          textHTML: "<h1>Não encontrado.. </h1>",
          description: mySelectTemplate.description ? mySelectTemplate.description : null,
          type: mySelectTemplate.type ? mySelectTemplate.type : null,
          type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
          idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null,
          typeDoc: "docx",
          titleDocx: mySelectTemplate.titleDocx ? mySelectTemplate.titleDocx.id : null,
          linkDocx: mySelectTemplate.linkDocx ? mySelectTemplate.linkDocx.id : null,
        }
        formikTemplate.setValues(data)

        if(mySelectTemplate.linkDocx){
          substituteValues(mySelectTemplate.linkDocx)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySelectTemplate]);

    // useEffect(() => {
    //   console.log(formikTemplate.values)
    // }, [formikTemplate.values])

    useEffect(() => {
      getAllUnitBranchs()
    }, []);

  return (
    <div className='bg-light' style={{ display: 'flex', gap: '1rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
        <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
            <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                <span className='card-label fw-bold fs-3 mb-1'>Template em documento</span>
                <span className='text-muted fw-semibold fs-7'>Suba um documento .docx como template</span>
            </h3>
        </div>

        <div className='border border-gray-300 border-dashed rounded'>
          <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '41rem', flexDirection: 'row'}}>
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              disabled={loadingUploadWord}
              onChange={handleWordUpload}
              style={{ display: 'none' }}
            />
            {/* <Button 
              onClick={handleButtonClick}
            >
              Escolher Arquivo
            </Button> */}

            <button
              onClick={() =>{handleButtonClick()}}
              disabled={loadingUploadWord}
              style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}
              className='btn btn-sm btn-light-primary text-hover-white'
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2x' />
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                  <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                </h3>
              </div>

              {loadingUploadWord && (
                <div style={{marginRight: '1rem'}}>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
            )}
            </button>

          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <button
            type='button'
            className='btn btn-outline-success'
            onClick={() => {
              setAtualizationTemplate(false)
              setGenerateTemplate(true)
            }}
          >
            Gerar Template
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
          </button>

          <button
              type='button'
              className='btn btn-outline-primary'
              disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
              //data-kt-stepper-action='submit'
              onClick={() => {
                  setGenerateTemplate(false)
                  setAtualizationTemplate(true)
                  //submit(1)
              }}
              >
              Atualizar Template
              {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
              }
          </button>
      </div>

      {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <FormGenerateTemplate 
          loading={loading}
          formikTemplate={formikTemplate}
          unitBranchs={unitBranchs}
          setGenerateTemplate={setGenerateTemplate}
          submit={submit}
        />

      : 
      atualizationTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <FormAtualizationTemplate 
          loading={loading}
          formikTemplate={formikTemplate}
          unitBranchs={unitBranchs}
          setGenerateTemplate={setGenerateTemplate}
          submit={submit}
        />
        : <></>
    }
    </div>
  );
}