/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useToast } from '../../../../../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../../../components/Loadings/loadingSpan';
import { StepModal } from './Components/StepModal';
import { postRulePlantOne, putRulePlantAll } from '../../../../../../../../../../services/RulePlantService';

type Props = {
    id?: string;
    data?: any;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  rule_type: Yup.string(),
  min_value: Yup.number(),
  max_value: Yup.number(),
  max_days_without_generation: Yup.number(),
  is_general: Yup.boolean(),
  idsUnitBranchs: Yup.array()
  .of(Yup.string())
  .notRequired()
  .default([]),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateRulePlant = ({show, handleClose, data, id}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues] = useState<any>({
    rule_type: null,
    min_value:  0,
    max_value:  0,
    max_days_without_generation:  0,
    is_general:  true,
    idsUnitBranchs: []
  })

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  }

  const checkInputs = (): boolean => {
    if (!formik.values.rule_type) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postRulePlantSubmit = async () => {
    try {
      setLoading(true)
      const myData = {

        rule_type: formik.values.rule_type,
        min_value:  formik.values.min_value,
        max_value:  formik.values.max_value,
        max_days_without_generation:  formik.values.max_days_without_generation,
        is_general:  formik.values.is_general,
        idsUnitBranchs: formik.values.idsUnitBranchs,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await postRulePlantOne(myData)
      toggleToast("Sucesso ao cadastrar a regra. ", "success")
    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar a regra. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const updateRulePlantSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        rule_type: formik.values.rule_type,
        min_value:  formik.values.min_value,
        max_value:  formik.values.max_value,
        max_days_without_generation:  formik.values.max_days_without_generation,
        is_general:  formik.values.is_general,
        idsUnitBranchs: formik.values.idsUnitBranchs,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await putRulePlantAll(id, myData)
      toggleToast("Sucesso ao atualizar a regra. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizara a regra. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const submit = () => {
    if(id){
      updateRulePlantSubmit(id)
    }else{
      postRulePlantSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data && id){
      const myObject = {
        rule_type: data.rule_type ? data.rule_type :  null,
        min_value: data.min_value ? data.min_value : 0,
        max_value: data.max_value ? data.max_value : 0,
        max_days_without_generation: data.max_days_without_generation ? data.max_days_without_generation : 0,
        is_general: data.is_general !== null && data.is_general !== undefined ? data.is_general : true,
        idsUnitBranchs: data.idsUnitBranchs ? data.idsUnitBranchs : null,
      }

      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Regra de Desempenho {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={initialValues} hasError={hasError} formik={formik} />

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                <div style={{display: 'flex', gap: '1rem'}}>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateRulePlant}
