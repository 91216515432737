/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getUsersAll, getUsersAllCompany, getUsersAllPagination } from '../../services/usersService';
import { Loading } from '../components/Loadings/loading';
import { TableUsers } from './TableUsers';

type Paginations = {
  page: number,
  limit: number,
  total: number,
  qtdPages: number
}

type SetPaginations = {
  start: number;
  end: number;
};

const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

const UsersPage: FC = () => {
    const [users, setUsers] = useState<any>([]);
    const [usersLoading, setUsersLoading] = useState<any>(true);
    
    const [filterCompany, setFilterCompany] = useState<string | null>("")
    const [filterDateInit, setFilterDateInit] = useState<string | null>("")
    const [filterDateEnd, setFilterDateEnd] = useState<string | null>("")
    const [filterTipo, setFilterTipo] = useState<string | null>("")
    const [filterStatus, setFilterStatus] = useState<string | null>("")

    const [pagination, setPagination] = useState<Paginations>({
      page: 1,
      limit: 25,
      total: 0,
      qtdPages: 1
    });

    const [qtd, setQtd] = useState<SetPaginations>({
      start: 0,
      end: 25,
    });

    const getUsers = async () => {
        try {
          setUsersLoading(true)
          if(filterCompany && filterCompany !== ""){
            const myUsers = await getUsersAllCompany(filterCompany);
            setUsers(myUsers)
          }else{
            const myUsers = await getUsersAllPagination(
              filterTipo ? filterTipo : undefined,
              filterDateInit ? filterDateInit : undefined,
              filterDateEnd ? filterDateEnd : undefined,
              filterStatus ? filterStatus : undefined,
              pagination.limit,
              pagination.page
            )
            setPagination({...pagination, qtdPages: myUsers.lastPage, total: myUsers.total})
            setUsers(myUsers.data)
          }
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os usuários!", "error")
        }finally{
          setUsersLoading(false)
        }
    }

    const refresh = () => {
      getUsers()
    }

    useEffect(() => {
      getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);

    useEffect(() => {
      getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filterCompany, 
      filterTipo,
      filterDateInit,
      filterDateEnd,
      filterStatus
    ]);

    useEffect(() => {
      const start = (pagination.page - 1) * pagination.limit + 1;
      const end = Math.min(pagination.page * pagination.limit, pagination.total);
  
      setQtd({start: start, end: end === 0 ? pagination.limit : end})
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);

  return (
    <div className='column g-5 g-xl-8'>
        {usersLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            // 
            <TableUsers
              className='card-xl-stretch mb-xl-8'
              users={users}
              refresh={refresh} 
              
              setFilterCompany={setFilterCompany}
              filterCompany={filterCompany}

              setFilterTipo={setFilterTipo}
              filterTipo={filterTipo}

              setFilterDateInit={setFilterDateInit}
              filterDateInit={filterDateInit}

              setFilterDateEnd={setFilterDateEnd}
              filterDateEnd={filterDateEnd}

              setFilterStatus={setFilterStatus}
              filterStatus={filterStatus}

              pagination={pagination}
              setPagination={setPagination}
              qtd={qtd}

            />
        )
    }
    </div>
  )
}

const UsersWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Gerenciamento de Usuários</PageTitle>
      <UsersPage />
    </>
  )
}

export {UsersWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

