/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { StepperComponent } from '../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';

//import { deleteUnitBranch } from '../../../../services/unitBranchService'
//import { deleteClient } from '../../../../services/clientService'

type Props = {
    data: any;
    show: boolean;
    type?: string;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalSaveRedefinationPassword = ({show, handleClose, data, type}: Props) => {

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{type && type === "reenvio" ? "Reenvio de Senha" : "Usuário Cadastrado"}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem'}}>
              <KTSVG
                path='/media/icons/duotune/arrows/arr016.svg'
                className='svg-icon-5x svg-icon-success ms-2 me-0'
              />
              <h3 className='card-title flex-column' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <span className='card-label fw-bold fs-3 mb-1'>{type && type === "reenvio" ? "Senha reenviada para o e-mail do usuário!" : "Novo usuário cadastrado!"}</span>
                <span className='text-muted mt-1 fw-semibold fs-5'><span className='fw-bold text-dark'>E-mail: </span>{data && data.email ? data.email : ""}</span>
                <span className='text-muted mt-1 fw-semibold fs-5'><span className='fw-bold text-dark'>Senha:</span> {data && data.senha ? data.senha : ""}</span>
              </h3>
            </div>
            {/*begin::Form */}


            <div style={{display: 'flex', justifyContent: 'end'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-success'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <span style={{marginLeft: '0.5rem'}}>OK</span>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                    
                    
                </button>

                {/* <button
                  type='button'
                  className='btn btn-lg btn-danger'
                  disabled={loading}
                  //data-kt-stepper-action='submit'
                  onClick={submit}
                >
                    Deletar
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button> */}
            </div>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalSaveRedefinationPassword }
