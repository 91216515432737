/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import { searchSubstitut } from '../../../utils/search';
import { SearchInput } from '../../components/SearchInput';
import { ModalAddUpdateUser } from '../components/ModalAddEditUser';
import { ModalDeleteUser } from '../components/ModalDeleteUser';
// import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
// import { ModalAddUpdateClient } from '../ModalAddEditClient';
// import { ModalDeleteClient } from '../ModalDeleteClient';
// import { ModalDetailsClient } from '../ModalDetailsClient';
import { TableBody } from './TableBody';
import { ModalActiveUser } from '../components/ModalActiveUser';
import { getCompanyAll } from '../../../services/companyService';
import { useToast } from '../../components/Toats';
import DateSelectorDays from '../../components/DateInputDays';
import { getRoleAll } from '../../../services/roles';
import { useAuth } from '../../../modules/auth';
import { ModalSaveRedefinationPassword } from '../components/ModalAddEditUser/components/stepModal/Components/ModalSaveRedefinationPassword';
import { PaginationGlobal } from '../../components/PaginationGlobal';


type Props = {
  className: string;
  users: any[];
  refresh: any;
  setFilterCompany: any;
  filterCompany: any;
  setFilterTipo: any;
  filterTipo: any;
  setFilterDateInit: any;
  filterDateInit: any;
  setFilterDateEnd: any;
  filterDateEnd: any;
  setFilterStatus: any;
  filterStatus: any

  pagination: any,
  setPagination: any,
  qtd: any
}

const TableUsers: React.FC<Props> = ({
  className, 
  users, 
  refresh, 
  setFilterCompany, 
  filterCompany,
  setFilterTipo,
  filterTipo,
  setFilterDateInit,
  filterDateInit,
  setFilterDateEnd,
  filterDateEnd,
  setFilterStatus,
  filterStatus,

  pagination,
  setPagination,
  qtd

}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalActive, setShowCreateAppModalActive] = useState<boolean>(false)
  //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myUser, setMyUser] = useState<any | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);
  const [companies, setCompanies] = useState<any[]>([])
  const [roles, setRoles] = useState<any[]>([])
  const { toggleToast } = useToast();
  const user = useAuth();

  const [showCreateAppModalPassword, setShowCreateAppModalPassword] = useState<boolean>(false);
  const [myUserPassword, setMyUserPassword] = useState<any>(null);
  const [typeModalPassword, setTypeModalPassword] = useState<string | null>(null);


  const getCompanys = async () => {
    try {
      const myCompanies = await getCompanyAll()
      setCompanies(myCompanies)
      
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os usuários!", "error")
    }finally{
      //setUsersLoading(false)
    }
  }

  const getRoles = async () => {
    try {
      const myRoles = await getRoleAll()
      setRoles(myRoles)
      
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os usuários!", "error")
    }finally{
      //setUsersLoading(false)
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(()=> {
    getCompanys()
    getRoles()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])
  
  useEffect(()=> {
    const search_users = mySearch.length > 0 ? 
    searchSubstitut(users, mySearch)
      : []
    setMySearch(search_users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '68vh', paddingBottom: '0rem'}}>
      <div className='card-header border-0 pt-6 mb-2'>
        {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Usuários</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{users.length} Usuários(s)</span>
        </h3> */}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem',  
            justifyContent: 'space-between', 
            width: '100%',
            flexDirection: largura > 1200 ? 'row' : 'column'
          }}>
            <div style={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
              <SearchInput 
                className='form-control form-control-solid bg-body ps-14'
                placeholder='Buscar Usuários'
                myDate={users}
                setMySearch={setMySearch}
                control={control}
                style={{width: largura > 1200 ? "300px" : "100%"}}
                setControl={setControl}
                searchParameter="name"
              />

              {user.currentUser?.role.slug === "ADMIN" &&
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  //defaultValue={formik.values.idUser ? formik.values.idUser : '1'}
                  value={filterCompany}
                  onChange={(e: any) => {
                    setFilterCompany(e.target.value)
                  }}
                >
                  <option value={''}> Selecione uma Empresa</option>
                  {companies && companies.map((company: any) => {
                    return (
                      <option key={company.id} value={company.id}>{company.corporateName}</option>
                    )
                  })}    
                </select>
              }

              <div>
                <div className="dropdown">
                  <button 
                    type="button" 
                    className="btn btn-lg btn-light btn-active-color-primary btn-sm dropdown-toggle" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false" 
                    data-bs-auto-close="outside"
                  >
                    <span><KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-3'/></span>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end p-4" style={{width: 'auto', minWidth: '375px', maxWidth: '400px'}}>
                    <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Outros Filtros</label>
                    <div className='separator my-2'></div>

                    <div style={{display: 'flex',  gap: '1rem'}}>
                      <div>
                        <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Data de Inicio (Intervalo)</label>
                        <DateSelectorDays 
                            selectedDate={filterDateInit !== "" ? new Date(filterDateInit) : new Date()}
                            setSelectedDate={(date: any) => {
                                setFilterDateInit(date.toString())
                            }}
                        />
                      </div>

                      <div>
                        <label className='form-label' style={{marginBottom: '0.5rem'}}>Data de Fim (Intervalo)</label>
                        <DateSelectorDays 
                            selectedDate={filterDateEnd !== "" ? new Date(filterDateEnd) : new Date()}
                            setSelectedDate={(date: any) => {
                                setFilterDateEnd(date.toString())
                            }}
                        />
                      </div>
                    </div>

                    <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                      <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Tipo</label>
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          defaultValue={filterTipo !== "" ? filterTipo : ""}
                          value={filterTipo}
                          onChange={(e: any) => {
                            setFilterTipo(e.target.value)
                          }}
                        >
                          <option value={""}></option>
                          {roles && roles.map((role: any) => {
                            return (
                              <option key={role.id} value={role.slug}>{role.slug}</option>
                            )
                          })}    
                        </select>
                      </div>
                    </div>

                    <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                      <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Status</label>
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          defaultValue={filterStatus !== "" ? filterStatus : ""}
                          value={filterStatus}
                          onChange={(e: any) => {
                            setFilterStatus(e.target.value)
                          }}
                        >
                          <option value={""}></option>
                          <option value={"active"}>ATIVO</option>
                          <option value={"inactive"}>INATIVO</option>
                          
                        </select>
                      </div>
                    </div>

                    <div className='separator my-2'></div>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <a 
                        href="#" 
                        className='btn btn-color-gray-600 btn-active-color-primary' 
                        onClick={() => {
                          setFilterDateEnd("")
                          setFilterDateInit("")
                          setFilterTipo("")
                          setFilterStatus("")
                        }}>
                        Limpar todos os filtros <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              
            </div>
          

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={largura < 1200 ? {width: "100%", display: 'flex', justifyContent: 'end'} : {}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Usuário
            </a>
          </div>
        </div>
        
      </div>

      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-150px'>Tipo</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-50px'>Data de criação</th>
                <th className='min-w-150px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    users={users} 
                    setMyUser={setMyUser}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalActive={setShowCreateAppModalActive}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    users={mySearch} 
                    setMyUser={setMyUser}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalActive={setShowCreateAppModalActive}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
              }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

      <div className='card-footer pt-0'>
        <PaginationGlobal 
          pagination={pagination}
          setPagination={setPagination}
          qtd={qtd}
        />
      </div>

        <ModalAddUpdateUser
          show={showCreateAppModal}
          id={myUser ? myUser.id : undefined} 
          data={myUser ? myUser : undefined}
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
          setMyUserPassword={setMyUserPassword}
          setTypeModalPassword={setTypeModalPassword}
          setShowCreateAppModalPassword={setShowCreateAppModalPassword}
        />
        <ModalDeleteUser 
          show={showCreateAppModalDelete}
          data={myUser}
          id={myUser ? myUser.id : undefined} 
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />
        <ModalActiveUser 
          show={showCreateAppModalActive}
          data={myUser}
          id={myUser ? myUser.id : undefined} 
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModalActive(false)
            refresh()
          }} 
        />

        <ModalSaveRedefinationPassword 
          show={showCreateAppModalPassword}
          data={myUserPassword}
          type={typeModalPassword ? typeModalPassword : undefined}
          handleClose={() => {            
            setShowCreateAppModalPassword(false);
            setMyUserPassword(null);
            
            if(typeModalPassword === "cadastro"){
              setMyUser(null)
              setShowCreateAppModal(false)
              refresh()
            }
            setTypeModalPassword(null);
          }} 
        />

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableUsers}
