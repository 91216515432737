/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../../../../_metronic/partials/content/dropdown/Dropdown1'
import { SearchInput } from '../../../../../components/SearchInput';
import useSize from '../../../../../../hooks/useSize';


type Props = {
  className: string;
  plants: any[]
  formik: any
}

const ListPlantsLinked: React.FC<Props> = ({className, plants, formik}) => {
  const [control, setControl] = useState(false);
  const [mySearch, setMySearch] = useState<any[]>([]);
  const {width} = useSize()
  
  const updateData = (value: string, checked: boolean) => {
    let valuesFormik = formik.values.idsPlants
    if(checked === true){
      valuesFormik.push(value)
    }
    else{
      valuesFormik = valuesFormik.filter((id: string) => id !== value)
    }
    formik.setFieldValue('idsPlants', valuesFormik)
  }

  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5' style={{display: 'flex', alignItems: 'center'}}>
        
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Lista de usinas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Vincular usinas ao cliente</span>
        </h3>

        <div>
          <SearchInput 
            className={`form-control form-control-solid bg-body ps-14 ${width < 1200 ? "fs-7" : ""}`}
            placeholder='Buscar'
            myDate={plants}
            setMySearch={setMySearch}
            control={control}
            style={{width: '150px', height: '3rem'}}
            setControl={setControl}
            searchParameter="name"
          />
        </div>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {/* begin::Item */}
        {
          plants && plants.length > 0 ?
            control ?
              mySearch.map((plant: any) =>{
                return (
                  <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                    {/* begin::Symbol */}
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                          </div>
                        </span>
                      </div>
                      {/* end::Symbol */}
                      {/* begin::Text */}
                      <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                          {plant.name}
                        </a>
                        <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                      </div>
                    </div>
                    <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                      <input 
                        className={`form-check-input ${formik.values.idsPlants.some((id: string) => id === plant.id) ? 'bg-primary' : 'bg-secondary'}`} 
                        type='checkbox'
                        //checked={plant.imported}
                        checked={formik.values.idsPlants.some((id: string) => id === plant.id) ? true : false}
                        value={plant.id}
                        onChange={(e) =>{
                          updateData(e.target.value, e.target.checked)
                          //console.log(e.target.checked)
                        }}
                        //onChange={formik.handleChange("idsPlants")}
                      />
                    </div>
                    {/* end::Text */}
                  </div>
                )
              })
            :
              plants.map((plant: any) =>{
                return (
                  <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                    {/* begin::Symbol */}
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                          </div>
                        </span>
                      </div>
                      {/* end::Symbol */}
                      {/* begin::Text */}
                      <div className='d-flex flex-column'>
                        <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                          {plant.name}
                        </a>
                        <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                      </div>
                    </div>
                    <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                      <input 
                        className={`form-check-input ${formik.values.idsPlants.some((id: string) => id === plant.id) ? 'bg-primary' : 'bg-secondary'}`} 
                        type='checkbox'
                        //checked={plant.imported}
                        checked={formik.values.idsPlants.some((id: string) => id === plant.id) ? true : false}
                        value={plant.id}
                        onChange={(e) =>{
                          updateData(e.target.value, e.target.checked)
                          //console.log(e.target.checked)
                        }}
                        //onChange={formik.handleChange("idsPlants")}
                      />
                    </div>
                    {/* end::Text */}
                  </div>
                )
              })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas vinculadas.</span>
            </h3>
          </div>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListPlantsLinked}
