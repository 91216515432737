import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { useAuth } from '../../../../../../modules/auth';
import { Loading } from '../../../../../components/Loadings/loading';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { putCompanyOne } from '../../../../../../services/companyService';
import { getFile, postFile } from '../../../../../../services/files';
import { PreferencesCompany } from './components/PreferencesCompany';
import { RulesPlant } from './components/RulesPlant';

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required("Informe um nome para a empresa. "),
  cnpj: Yup.string().required("Informe um CNPJ. "),
  site: Yup.string(),
  image: Yup.string(),
  image_cell: Yup.string(),
  image_dark: Yup.string(),
  color_primary: Yup.string(),
  color_primary_variet: Yup.string(),
  dateOfBirth: Yup.string(),
  key: Yup.string(),
  keyV2: Yup.string(),
  keyV3: Yup.string(),

  telephone: Yup.string(),
  link_facebook: Yup.string(),
  link_instagram: Yup.string(),
  link_linkedin: Yup.string(),
  link_youtube: Yup.string(),
});

export function BodySettingsCompany() {
  const { currentUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImg, setLoadingImg] = useState<boolean>(false);
  const [loadingImgGet, setLoadingImgGet] = useState<boolean>(false);

  
  const [initialValues] = useState<any>({
    id: null,
    name: null,
    cnpj: null,
    site: null,
    dateOfBirth: null,
    image: null,
    image_cell: null,
    image_dark: null,
    color_primary: null,
    color_primary_variet: null,
    key: null,
    keyV2: null,
    keyV3: null,

    telephone: null,
    link_facebook: null,
    link_instagram: null,
    link_linkedin: null,
    link_youtube: null,
  })


  const getImage = async () => {
    try {
      setLoadingImgGet(true)
      
      const url = formik.values.key ? await getFile(formik.values.key) : null
      const urlV2 = formik.values.keyV2 ? await getFile(formik.values.keyV2) : null
      const urlV3 = formik.values.keyV3 ? await getFile(formik.values.keyV3) : null

      formik.setValues({...formik.values, image: url.url, image_cell: urlV2.url, image_dark: urlV3.url})

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a imagem. ", "error")

    }finally{
      setLoadingImgGet(false)
    }
  }

  const updateCompanySubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        id: formik.values.id,
        corporateName: formik.values.name,
        cnpj: formik.values.cnpj,
        site: formik.values.site,
        image: formik.values.key,
        image_cell: formik.values.keyV2,
        image_dark: formik.values.keyV3,
        color_primary: formik.values.color_primary,
        color_primary_variet: formik.values.color_primary_variet,
        dateOfBirth: formik.values.dateOfBirth,
        telephone: formik.values.telephone,
        link_facebook: formik.values.link_facebook,
        link_instagram: formik.values.link_instagram,
        link_linkedin: formik.values.link_linkedin,
        link_youtube: formik.values.link_youtube,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await putCompanyOne(id, myData)
      toggleToast("Sucesso ao atualizar a empresa. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar a empresa. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      setTimeout(()=>{
        window.location.reload()
      }, 700);
    }
  }

  const postFileOne = async (fileData: any, type: string) => {
    try {
      setLoadingImg(true)
      const url = await postFile(fileData)
      
      if(type === 'cell'){
        formik.setValues({...formik.values, keyV2: url.key})
      }else{
        if(type === 'dark'){
          formik.setValues({...formik.values, keyV3: url.key})
        }else{
          formik.setValues({...formik.values, key: url.key})
        }    
      }
      

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao subir a logo. ", "error")
    }finally{
      setLoadingImg(false)
      //handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 500);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
});

  const submit = () => {
    updateCompanySubmit(currentUser && currentUser.companies && currentUser.companies.length > 0 ? currentUser.companies[0].id : "")
  }

  const handleSubmitImage = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    postFileOne(formData, "computer");
  }

  const handleSubmitImageV2 = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    postFileOne(formData, "cell");
  }

  const handleSubmitImageV3 = (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    postFileOne(formData, "dark");
  }


  useEffect(() => {
    const myDate = {
      id: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].id ? currentUser?.companies[0].id : null,
      name: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].corporateName ? currentUser?.companies[0].corporateName : null,
      cnpj: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].cnpj ? currentUser?.companies[0].cnpj : null,
      site: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].site ? currentUser?.companies[0].site : null,
      dateOfBirth: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].dateOfBirth ? currentUser?.companies[0].dateOfBirth : null,
      image: null,
      color_primary: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].color_primary ? currentUser?.companies[0].color_primary : '#ffc700',
      color_primary_variet: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].color_primary_variet ? currentUser?.companies[0].color_primary_variet : '#f1bc00',
      
      telephone: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].telephone ? currentUser?.companies[0].telephone : null,
      link_facebook: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_facebook ? currentUser?.companies[0].link_facebook : null,
      link_instagram: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_instagram ? currentUser?.companies[0].link_instagram : null,
      link_linkedin: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_linkedin ? currentUser?.companies[0].link_linkedin : null,
      link_youtube: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].link_youtube ? currentUser?.companies[0].link_youtube : null,
      key: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].image ? currentUser?.companies[0].image : null,
      keyV2: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].image_cell ? currentUser?.companies[0].image_cell : null,
      keyV3: currentUser && currentUser.companies && currentUser?.companies?.length > 0 && currentUser?.companies[0].image_dark ? currentUser?.companies[0].image_dark : null,
    }
    formik.setValues(myDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getImage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.key, formik.values.keyV2, formik.values.keyV3])

  if ("") {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  
  return (
    <div>
      <div className='card mb-5 mb-xl-10' style={{}}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Dados da Empresa</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div>
          <div className='card-body border-top p-9'>
              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Foto de Perfil</label>
                <div className='col-lg-8'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      // style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                    ></div>
                  </div>
                </div>
              </div> */}

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nome da empresa'
                        defaultValue={formik.values.name}
                        onChange={formik.handleChange("name")}
                        // {...formik.getFieldProps('fName')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>Necessário informar um nome de usuário</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='CNPJ da empresa'
                    defaultValue={formik.values.cnpj}
                    onChange={formik.handleChange("cnpj")}
                    // {...formik.getFieldProps('company')}
                  />
                  {/* {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )} */}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Data da Criação</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Data de criação da empresa'
                    defaultValue={formik.values.dateOfBirth}
                    onChange={formik.handleChange("dateOfBirth")}
                    // {...formik.getFieldProps('company')}
                  />
                  {/* {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )} */}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Site</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Site da empresa'
                    defaultValue={formik.values.site}
                    onChange={formik.handleChange("site")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Telefone</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='telefone da empresa'
                    defaultValue={formik.values.telephone}
                    onChange={formik.handleChange("telephone")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Facebook</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Facebook da empresa'
                    defaultValue={formik.values.link_facebook}
                    onChange={formik.handleChange("link_facebook")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Intagram</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Intagram da empresa'
                    defaultValue={formik.values.link_instagram}
                    onChange={formik.handleChange("link_instagram")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Linkedin</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Linkedin da empresa'
                    defaultValue={formik.values.link_linkedin}
                    onChange={formik.handleChange("link_linkedin")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Linkedin</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='url'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Youtube da empresa'
                    defaultValue={formik.values.link_youtube}
                    onChange={formik.handleChange("link_youtube")}
                    // {...formik.getFieldProps('company')}
                  />
                </div>
              </div>

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Tipo de Usuário</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    defaultValue={formik.values.idRole}
                    // {...formik.getFieldProps('country')}
                  >
                    {
                      roles.length > 0 && 
                        roles.map((role: any) => {
                          return (<option value={role.id} disabled>{role.slug}</option>)
                        })
                    }      
                  </select>
                </div>
              </div> */}

              {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

                <div className='col-lg-8 fv-row'>
                <a
                    href='#'
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    {formik.values.status && formik.values.status ? formik.values.status : "Não identificado"}
                  </a>
                </div>
              </div> */}

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button /*type='submit'*/ onClick={submit} className='btn btn-primary' /*disabled={loading}*/>
                {!loading && 'Salvar Dados'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10' style={{}}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Estilização da Empresa</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='card-body border-top p-9'>
            
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo da Empresa (Ambientes Claros)</label>
              <div className='col-lg-8' style={{}}>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, marginRight: '1rem'}}
                >
                  {
                    formik.values.image ? 
                    (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${toAbsoluteUrl(formik.values.image)})`}}
                      ></div>
                    )
                    : 
                    (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${toAbsoluteUrl('/media/imgs/sua-logo-preta.png')})`}}
                      ></div>
                    )
                  }
                </div>
                <input 
                  type="file" 
                  disabled={loadingImg || loadingImgGet}
                  id="imagem" 
                  name="imagem" 
                  accept="image/*" 
                  placeholder="Selecione uma imagem para enviar..."
                  onChange={handleSubmitImage}
                >
                </input>
              </div>
              
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo da Empresa (Ambientes Escuros)</label>
              <div className='col-lg-8' style={{}}>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, marginRight: '1rem'}}
                >
                  {
                    formik.values.image ? 
                    (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${toAbsoluteUrl(formik.values.image_dark)})`}}
                      ></div>
                    )
                    : 
                    (
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{backgroundImage: `url(${toAbsoluteUrl('/media/imgs/sua-logo-preta.png')})`}}
                      ></div>
                    )
                  }
                </div>
                <input 
                  type="file" 
                  disabled={loadingImg || loadingImgGet}
                  id="imagem" 
                  name="imagem" 
                  accept="image/*" 
                  placeholder="Selecione uma imagem para enviar..."
                  onChange={handleSubmitImageV3}
                >
                </input>
              </div>
              
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo da Empresa (Celular)</label>
              <div className='col-lg-8' style={{}}>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, marginRight: '1rem'}}
                >
                  {
                    formik.values.image ? 
                    (
                      <div
                        className='image-input-wrapper w-300px h-70px'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(formik.values.image_cell)})`, 
                          backgroundSize: '100% auto',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      ></div>
                    )
                    : 
                    (
                      <div
                        className='image-input-wrapper w-200px h-70px'
                        style={{backgroundImage: `url(${toAbsoluteUrl('/media/imgs/sua-logo-preta.png')})`}}
                      ></div>
                    )
                  }
                </div>
                <input 
                  type="file" 
                  disabled={loadingImg || loadingImgGet}
                  id="imagem" 
                  name="imagem" 
                  accept="image/*" 
                  placeholder="Selecione uma imagem para enviar..."
                  onChange={handleSubmitImageV2}
                >
                </input>
              </div>
              
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Cor Primaria (Empresa)</label>
              <div className='col-lg-8'>
              <input
                type="color"
                value={formik.values.color_primary}
                onChange={formik.handleChange("color_primary")}
                //onChange={handleChange}
              />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Cor Primaria Variância (Empresa)</label>
              <div className='col-lg-8'>
              <input
                type="color"
                value={formik.values.color_primary_variet}
                onChange={formik.handleChange("color_primary_variet")}
                //onChange={handleChange}
              />
              </div>
            </div>


            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button /*type='submit'*/ onClick={submit} className='btn btn-primary' /*disabled={loading}*/>
                {!loading && 'Salvar Estilização'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <RulesPlant />

      <PreferencesCompany />


    </div>

  );
}

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
