import api from "./api";

export const getUsersAll = async (tipo?: string, dateInit?: string, dateEnd?: string, status?: string) => {
    const response = await api.get(`users/listAll/?role=${tipo ? tipo : ""}&dateInit=${dateInit ? dateInit : ""}&dateEnd=${dateEnd ? dateEnd : ""}&status=${status ? status : ""}`);
    return response.data;
};

export const getUsersAllPagination = async (tipo?: string, dateInit?: string, dateEnd?: string, status?: string, limit?: number, page?: number) => {
    const response = await api.get(`users/listAllPagination/?role=${tipo ? tipo : ""}&dateInit=${dateInit ? dateInit : ""}&dateEnd=${dateEnd ? dateEnd : ""}&status=${status ? status : ""}${limit ? `&limit=${limit}` : ""}${page ? `&page=${page}` : ""}`);
    return response.data;
};


export const getUsersAllCompany = async (idCompany: string) => {
    const response = await api.get(`users/listAllCompany/?idCompany=${idCompany ? idCompany : ""}`);
    return response.data;
};

export const getUsersAllRole = async (filter: string, unitBranch?: string) => {
    const response = await api.get(`users/listAll/?role=${filter}&unitBranch=${unitBranch ? unitBranch : ""}`);
    return response.data;
};

export const postUsersAll = async (body: any) => {
    const role = body.role;
    delete body.role
    const response = await api.post(`users/?role=${role}`, body);
    return response.data;
};

export const updateUsersAll = async (id: string, body: any) => {
    const response = await api.put(`users/${id}`, body);
    return response.data;
};

export const deleteUserAll = async (id: string) => {
    const response = await api.delete(`users/${id}`);
    return response.data;
};

export const postUser = async (body: any, role: string) => {
    const response = await api.post(`users/?role=${role}`, body);
    return response.data;
};


export const updatePasswordOne = async (body: any) => {
    const response = await api.put(`users/password/`, body);
    return response.data;
};





