/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SearchInput } from '../../../components/SearchInput';
import { useToast } from '../../../components/Toats';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
import { ModalAddUpdateClient } from '../ModalAddEditTypeService';
import { ModalDeleteTypeService } from '../ModalDeleteTypeService';
import { TableBody } from './TableBody';
import { getProductsAll } from '../../../../services/productsService';
import { ModalAddUpdateProduct } from '../ModalAddEditProduct';
import { ModalDeleteProduct } from '../ModalDeleteProduct';
import useSize from '../../../../hooks/useSize';
import { PaginationTablePlants } from '../../../dashboard/TablePlants/components/Pagination';

type Props = {
  className: string;
  refresh: any
  refreshPage : boolean; 
  setRefreshPage: any;
}

type Paginations = {
  page: number,
  limit: number,
  total: number,
  qtdPages: number
}

type SetPaginations = {
  start: number;
  end: number;
}

const TableProducts: React.FC<Props> = ({className, refreshPage}) => {
  
  const [mySearch, setMySearch] = useState([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myTypeService, setMyTypeService] = useState<any | null>(null);
  const [produtos, setProdutos] = useState<any>([]);
  const { toggleToast } = useToast();
  const [produtosLoading, setProdutosLoading] = useState<boolean>(false)
  const [largura, setLargura] = useState(window.innerWidth);

  const {height, width} = useSize();

  const [pagination, setPagination] = useState<Paginations>({
    page: 1,
    limit: 25,
    total: 0,
    qtdPages: 1
  });

  const [qtd, setQtd] = useState<SetPaginations>({
    start: 0,
    end: 25,
  });


  const getProdutos = async () => {
    try {
      setProdutosLoading(true)
      const myProdutos = await getProductsAll()
      setProdutos(myProdutos)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os produtos!", "error")
    }finally{
      setProdutosLoading(false)
    }
}

const refresh = () => {
  getProdutos()
}

const atualizarTamanhoDaTela = () => {
  setLargura(window.innerWidth);
};

useEffect(() => {
  getProdutos()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  getProdutos()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [refreshPage]);

useEffect(() => {
  window.addEventListener('resize', atualizarTamanhoDaTela);
  return () => {
    window.removeEventListener('resize', atualizarTamanhoDaTela);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  
  return (
    <div className={`${className}`}>
      {/* begin::Header */}
      <div className='border-0'>
        {/*<h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{clients.length} Cliente(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem', 
            alignItems: 'center', 
            width: "100%", 
            justifyContent: 'space-between',
            flexDirection: largura < 1200 ? 'column' : 'row'
          }}>
          <SearchInput 
            className='form-control form-control-solid bg-body ps-14'
            placeholder='Buscar Produtos'
            myDate={produtos}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="description"
          />

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar um produto'
            style={{width: largura > 1200 ? '' : '100%', display: 'flex', justifyContent: 'end', paddingBottom: '0.5rem'}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Produto
            </a>
          </div>
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='py-3' 
        //style={largura < 1200 ? {overflow: 'auto', height: '26rem'}: {overflow: 'auto', minHeight: '20rem', maxHeight: '45vh'}}
        style={{
          overflow: 'auto', 
          height: width < 1200 ? 
            height >= 750 ? '43vh'
          : height < 750 && height >= 650 ? '40vh'
          : height < 650 && height >= 300 ? '30vh'
          : `10vh`

          : height <= 650 ? '20vh'
          : height < 750  && height > 650 ? '30vh' 
          : height > 750 && height <= 850 ? '35vh'
          : height > 850 && height <= 930 ? '41vh'
          : `45vh`,
        }} 
      >
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-250px'>Preço</th>
                <th className='min-w-250px'>Quantidade</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                produtosLoading ? 
                  <>
                    Carregando....
                  </> 
                :
                control === false ? (
                  <TableBody 
                    produtos={produtos} 
                    setMyTypeService={setMyTypeService}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={() => {}}
                  />
                )
                : (
                  <TableBody 
                    produtos={mySearch} 
                    setMyTypeService={setMyTypeService}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={() => {}}
                  />
                )
                }
          </table>
        </div>
      </div>

      <div className='card-footer pt-0 pb-2'>
        <PaginationTablePlants 
          pagination={pagination}
          setPagination={setPagination}
          qtd={qtd}
        />
      </div>
      
        <ModalAddUpdateProduct
          show={showCreateAppModal}
          id={myTypeService ? myTypeService.id : undefined} 
          data={myTypeService ? myTypeService : undefined}
          handleClose={() => {
            setMyTypeService(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        <ModalDeleteProduct 
          show={showCreateAppModalDelete}
          data={myTypeService}
          id={myTypeService ? myTypeService.id : undefined} 
          handleClose={() => {
            setMyTypeService(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableProducts}
