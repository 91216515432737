/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { postClient, postClientsAll, updateClient } from '../../../../services/clientService'
import { Button } from 'react-bootstrap';
import Papa from 'papaparse';
import { getUnitBranchAll } from '../../../../services/unitBranchService'

type DataForm = {
  email: string | null,
  nameResponsible: string | null,
  cellphone: string | null,
  cpfResponsible: string | null,
  telephone: string | null,
  idUser: string | null,
  idUnitBranch: string | null,
  idsPlants: string[] | []
}

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddClientPlanilha = ({show, handleClose}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo.");
  const [myClientsImported, setMyClientsImported] = useState<any[]>([]);
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false)
  const [selectedUnitBranch, setSelectedUnitBranch] = useState(true)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const getUsersAllUnitBranchs = async () => {
    try {
      //setLoadingUnitBranchs(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      //setLoadingUnitBranchs(false)
    }
  }

  const fileInputRef : any = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTextArquive(file.name)

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          //setData(results.data);
          if(results && results.data && results.data.length > 0){
            const newArray: any = [];
            
            results.data.map((clientResult: any) => {
              newArray.push({
                nameResponsible: clientResult.nome ? clientResult.nome : null,
                cpfResponsible: clientResult.cpf ? clientResult.cpf : null,
                email: clientResult.email ? clientResult.email : null,
                telephone: clientResult.telefone ? clientResult.telefone  : null,
                cellphone: clientResult.celular ? clientResult.celular : null,
              })
            })
            setMyClientsImported(newArray)
          }
        },
        error: function (error) {
          console.error("Erro ao processar o arquivo:", error);
        },
      });

    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `/media/csvs/clients.csv`;
    link.download = 'clients.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUnitBranch = (id: string) => {
    if(id !== ""){
      const myClientsResult = myClientsImported.map((client : any) => {
        const client_base = client;
        client_base.idUnitBranch = id;
        return client_base
      });
      
      setMyClientsImported(myClientsResult)
      setSelectedUnitBranch(false)
    }
  }

  const closed = () => {
    //formik.setValues(initialValues)
    setMyClientsImported([])
    handleClose()
  }

  const postClientsSubmit = async () => {
    try {
      setLoading(true)
      console.log(myClientsImported)
      await postClientsAll(myClientsImported)
      toggleToast("Sucesso ao cadastrar os clientes. ", "success")
    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar os clientes. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const submit = () => {
    postClientsSubmit()
  }

  useEffect(() => {
    getUsersAllUnitBranchs()
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <div style={{height: '15rem', overflow: 'auto'}}>
              <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                    <th className='min-w-200px'>Nome</th>
                    <th className='min-w-140px'>CPF</th>
                    <th className='min-w-140px'>E-mail</th>
                    <th className='min-w-140px'>Telefone</th>
                    <th className='min-w-140px'>Celular</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    myClientsImported.length === 0 ?
                    <tr>
                      <td colSpan={5}>
                        <div className='d-flex justify-content-center' style={{padding: '1rem'}}>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Clientes não importados!
                          </span>
                        </div>
                      </td>
                    </tr>
                    :
                    myClientsImported.map((client: any) => {
                      return (
                        <tr>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {client.nameResponsible ? client.nameResponsible : ""}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {client.cpfResponsible ? client.cpfResponsible : ""}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {client.email ? client.email : ""}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {client.telephone ? client.telephone : ""}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {client.cellphone ? client.cellphone : ""}
                            </span>
                          </td>
                        </tr>
                      )
                    })
                  }
                  
                </tbody>
              </table>
            </div>

            <div className='separator border-gray-200 mb-4'></div>

            <div className='border border-gray-200 border-dashed rounded p-1'>
              <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '10rem'}}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {/* <Button 
                  onClick={handleButtonClick}
                >
                  Escolher Arquivo
                </Button> */}

                <a
                  onClick={() =>{handleButtonClick()}}
                  href='#'
                  style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                  className='btn btn-sm btn-light-primary text-hover-white'
                >
                  <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-3x' />
                  <h3 className='card-title align-items-start flex-column'>
                    {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                    <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                  </h3>
                </a>
              </div>
            </div>

            <div className='separator border-gray-200 mb-4 mt-4'></div>

            <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 mb-1'>Filial: </span>
                {/* <span className='mt-1 fw-semibold fs-7'>{textArquive}</span> */}
              </h3>
              <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  disabled={myClientsImported.length > 0 ? false : true}
                  //defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                  //onChange={formik.handleChange("idUnitBranch")}
                  onChange={(event: any) => {
                    handleUnitBranch(event.target.value)
                  }}
                >
                  <option value={''}></option>
                  {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                    return (
                      <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                    )
                  })}      
              </select>
            </div>

            {/* <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} />

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
 
                <div style={{display: 'flex', gap: '1rem'}}>
                
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form> */}
            
          </div>
        </div>
      </div>

      <div className='modal-footer py-lg-10 px-lg-10' style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', gap: '0.7rem', alignItems: 'center'}} className='text-hover-primary'>
          <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3x' />
          <h3 className='card-title' style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            <a href="#" onClick={() => {handleDownload()}}>
              <span className='text-dark text-hover-primary card-label fw-bold fs-4 mb-1'>clients.csv</span>
            </a>
            <span className='text-muted mt-1 fw-semibold fs-8'>Faça o download da planilha, <br />para preencher com os dados dos clientes</span>
          </h3>
          {/* <a href='#' className='text-muted fw-bold text-hover-primary d-block fs-6'>
            Planilha_Padrão.csv
          </a> */}
        </div>
        
        <div>
          <button
            type='button'
            className='btn btn-lg btn-primary'
            disabled={loading || selectedUnitBranch}
            //data-kt-stepper-action='submit'
            onClick={submit}
          >
            Salvar Clientes
            {
              loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
                />
            }
          </button>
        </div>

        
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddClientPlanilha}
