import React from 'react';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';

type Props = {
    loading: any, 
    formikTemplate: any, 
    unitBranchs: any, 
    setGenerateTemplate: any, 
    submit: any
}

export function FormGenerateTemplate({
    loading, 
    formikTemplate, 
    unitBranchs, 
    setGenerateTemplate, 
    submit
} : Props) {
  return (
    <div className='p-6'>
        <h3 className='align-items-start flex-column border-bottom border-gray-300 pb-2 mb-6' style={{display: "flex"}}>
            <span className='text-muted fw-semibold fs-7'>Geração de Template</span>
        </h3>
        <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <div style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>Descrição do template</span>
                <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Fim do período de análise do relatório'
                ></i>
            </label>
        </div>
        <div style={{flex: '3'}}>
            <input
                type='text'
                className='form-control form-control-lg'
                name='endPeriod'
                disabled={loading}
                placeholder=''
                value={formikTemplate.values.description ? formikTemplate.values.description : ""}
                onChange={formikTemplate.handleChange("description")}
            />
        </div>
        </div>

        <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <div style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>Titulo do documento</span>
                <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Fim do período de análise do relatório'
                ></i>
            </label>
        </div>
        <div style={{flex: '3'}}>
            <input
                type='text'
                className='form-control form-control-lg'
                name='endPeriod'
                disabled={loading}
                placeholder=''
                value={formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : ""}
                onChange={formikTemplate.handleChange("titleDocx")}
            />
        </div>
        </div>

        <div style={{}}>
        <div style={{}}>
            <div className='mb-10' style={{display: 'flex', gap: '1rem'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Unidade (Filial)</span>
                <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
                ></i>
            </label>
            
            <div style={{flexGrow: '1', display: 'flex', gap: '1rem'}}>
              <select
                className='form-select form-select-solid bg-white'
                data-kt-select2='true'
                disabled={loading}
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formikTemplate.values.idUnitBranch ? formikTemplate.values.idUnitBranch : '1'}
                onChange={formikTemplate.handleChange("idUnitBranch")}
              >
                <option value={'1'}>Selecione uma Unidade (Filial)</option>
                {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                  return (
                    <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                  )
                })}
                
              </select>

              <select
                className='form-select form-select-solid bg-white'
                data-kt-select2='true'
                disabled={loading}
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={formikTemplate.values.type_template ? formikTemplate.values.type_template : 'DETALHADO'}
                onChange={formikTemplate.handleChange("type_template")}
              >
                <option value={''}></option>
                <option value={'GERACAO'}>Geração</option>
                <option value={'DETALHADO'}>Detalhado</option>
                
              </select>
            </div>
          </div>
        </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <button
            type='button'
            className='btn btn-lg btn-light'
            disabled={loading}
            onClick={() => {
            setGenerateTemplate(false)
            }}
            >
            Cancelar
            {
                loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
            }
        </button>

        <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={() => submit(0)}
            >
            Gerar
            {
                loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
            }
        </button>
        </div>

    </div> 
  );
}