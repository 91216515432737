import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { ModalAddUpdateRulePlant } from './Components/ModalAddEditRulePlant';
import { ModalDeleteRulePlant } from './Components/ModalDeleteRulePlant';
import { useToast } from '../../../../../../../components/Toats';
import { getRulePlantAll } from '../../../../../../../../services/RulePlantService';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';

export function RulesPlant() {
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
    const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
    const [rulePlants, setRulesPlants] = useState<any[]>([]);

    const [myRulePlant, setMyRulePlant] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const { toggleToast } = useToast();


    const getRulePlants = async () => {
        try {
        setLoading(true)
        const myRulesPlants = await getRulePlantAll();
        console.log(myRulesPlants)
        setRulesPlants(myRulesPlants);

        } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as regras. ", "error")

        }finally{
        setLoading(false)
        }
    }

    const handleChange = (rulePlant: any) => {
        rulePlant.idsUnitBranchs = rulePlant.unitBranchs.length > 0 ? rulePlant.unitBranchs.map((unitBranch) => unitBranch.id) : null;
        setMyRulePlant(rulePlant);
        setShowCreateAppModal(true);
    }

    const handleChangeDelete = (rulePlant: any) => {
        setMyRulePlant(rulePlant);
        setShowCreateAppModalDelete(true)
    }

    const refresh = () => {
        getRulePlants()
    }

    useEffect(() => {
        getRulePlants()
    }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
        <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_rules_preferences'
            aria-expanded='true'
            aria-controls='kt_account_rules_preferences'
            >
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Gestão de Desempenho</h3>
            </div>
        </div>

        <div id='kt_account_rules_preferences' className='collapse show'>
            <div className='card-body border-top p-9'>
                <h3 className='card-title align-items-start flex-column' style={{display: 'flex'}}>
                    <span className='card-label fw-bold fs-3 mb-1'>Regra Geral</span>
                    <span className='text-muted fw-semibold fs-7'>Regra aplicada para todas as usinas</span>
                </h3>

                <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem', marginTop: '2rem'}}>               
                    {loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/> 
                    : rulePlants && rulePlants.length > 0 ? (
                        rulePlants.map((rulePlant: any) => {
                            if(rulePlant && rulePlant.rule_type && rulePlant.rule_type !== "DIAS_SEM_GERACAO"){
                                return (
                                    <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                                                {rulePlant.is_general === true ?
                                                    (
                                                        <KTSVG 
                                                            path="media/icons/duotune/arrows/arr016.svg" 
                                                            className="svg-icon-success svg-icon-2x" 
                                                        />
                                                    ) :
                                                    (
                                                        <KTSVG 
                                                            path="media/icons/duotune/arrows/arr015.svg" 
                                                            className="svg-icon-danger svg-icon-2x" 
                                                        />
                                                    )
                                                }
    
                                                <h3 className='card-title align-items-start flex-column pb-0' style={{display: 'flex', margin: '0rem'}}>
                                                    <span className='card-label fw-bold fs-5'>
                                                        Notificar quando o desempenho {
                                                            rulePlant && rulePlant.rule_type ? 
                                                            rulePlant.rule_type === "DESEMPENHO_30D" 
                                                            ? "30D" : rulePlant.rule_type === "DESEMPENHO_15D" ? "15D" 
                                                            : rulePlant.rule_type === "DESEMPENHO_12M" ? "12M" 
                                                            : "" 
                                                            : ""
                                                        } 
                                                        {" "}for menor que {rulePlant && rulePlant.min_value ? rulePlant.min_value : 0}% 
                                                        ou maior que {rulePlant && rulePlant.max_value ? rulePlant.max_value : 0}%.</span>
                                                </h3>
                                        </div>
    
                                        <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                                            <button
                                                onClick={(event: any) =>{
                                                    handleChange(rulePlant);
                                                }}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-4' />
                                            </button>
                                            <button
                                                onClick={(event: any) => {
                                                    handleChangeDelete(rulePlant)
                                                }}
                                                //disabled={unitConsumer.type_unit_consumer && unitConsumer.type_unit_consumer !== "GERADORA" ? false : true}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-4'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        
                        }))
                    :
                        (<div>
                            <button
                                className='btn btn-lg text-hover-primary'
                                style={{padding: '0rem'}}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setShowCreateAppModal(true)
                                }}
                                type="button"
                            >
                                <KTSVG className='svg-icon-muted svg-icon-2hx' path='/media/icons/duotune/general/gen035.svg' />
                            </button>
                        </div>)
                    }
            </div>
                
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={() => {setShowCreateAppModal(true)}} className='btn btn-primary'>
                    <span className='indicator-progress' style={{display: 'block'}}>
                        Criar Regra
                    </span>
                </button>
            </div>
        </div>

        <ModalAddUpdateRulePlant
          show={showCreateAppModal}
          id={myRulePlant && myRulePlant.id ? myRulePlant.id : undefined} 
          data={myRulePlant ? myRulePlant : undefined}
          handleClose={() => {
            setMyRulePlant(null);
            setShowCreateAppModal(false)
            refresh()
          }} 
        />

        <ModalDeleteRulePlant
          show={showCreateAppModalDelete}
          id={myRulePlant && myRulePlant.id ? myRulePlant.id : undefined} 
          data={myRulePlant ? myRulePlant : undefined}
          handleClose={() => {
            setMyRulePlant(null);
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />
    </div>
  );
}