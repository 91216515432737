/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
//import {useIntl} from 'react-intl'
import { useParams } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import { useAuth } from '../../../modules/auth';
import { getCredentialOne } from '../../../services/credentialService';
import { getPlantsImported, importedPlants, postLogin } from '../../../services/plantsService';
import { getUnitBranchAll } from '../../../services/unitBranchService';
import { Loading } from '../../components/Loadings/loading';
import { LoadingSpan } from '../../components/Loadings/loadingSpan';
import { useToast } from '../../components/Toats';
import { TablePlantImported } from './components/tablePlantsImported';
import { TablePlantNotImported } from './components/tablePlantsNotImported';
import { addLoadingCredential, removeLoadingCredential } from '../../../utils/LoadingCredentials';
import useSocket from '../../../hooks/useSocket';

const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Portais',
      path: '/portals',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

const PagePlantsImported: FC = () => {
    const user = useAuth()
    const { socket, on, off } = useSocket();

    const { toggleToast } = useToast();
    const { id } = useParams();
    const [credential, setCredential] = useState<any>();
    const [unitBranch, setUnitBranch] = useState<any[]>([]);
    const [plantsNotImported, setPlantsNotImported] = useState<any>([]);
    const [plantsImported, setPlantsImported] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [largura, setLargura] = useState(window.innerWidth);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [dataGlobal, setDataGlobal] = useState<any>({
      idCredential: id ? id : "",
      idUnitBranch: "",
      type_plano: "BASICA",
      plants_list: []
    });

    const addValuesData = (value: any, check: boolean) => {
      if(typeof value === 'string'){
        setDataGlobal((prevDataGlobal) => {
          const newPlantsList = [...prevDataGlobal.plants_list];

          if (check) {
            if (!newPlantsList.includes(value)) {
              newPlantsList.push(value);
            }
  
          } else {
            const index = newPlantsList.indexOf(value);
            if (index !== -1) {
              newPlantsList.splice(index, 1);
            }
          }
          return {
            ...prevDataGlobal,
            plants_list: newPlantsList,
          };
        });
      }else{
        setDataGlobal((prevDataGlobal) => {
          const newPlantsList = [...prevDataGlobal.plants_list];
      
          if (check) {
            if (!newPlantsList.includes(Number(value))) {
              newPlantsList.push(Number(value));
            }
  
          } else {
            const index = newPlantsList.indexOf(Number(value));
            if (index !== -1) {
              newPlantsList.splice(index, 1);
            }
          }
          return {
            ...prevDataGlobal,
            plants_list: newPlantsList,
          };
        });
      }

    }

    const addValuesDataAll = (idUnitBranch: string) => {
      setDataGlobal((prevDataGlobal: any) => ({
        ...prevDataGlobal,
        idUnitBranch: idUnitBranch
      }));
    }

    const addValuesDataAllTypePlano = (type_plano: string) => {
      setDataGlobal((prevDataGlobal: any) => ({
        ...prevDataGlobal,
        type_plano: type_plano
      }));
    }


    const getCredential = async () => {
      try {
        const myCredential = await getCredentialOne(id ? id : "")
        setCredential(myCredential)

        if(myCredential){
          var list_imports = sessionStorage.getItem('list_imports');
      
          if(list_imports){
            var my_list_imports_finaly = JSON.parse(list_imports);
            let search_item = my_list_imports_finaly.some((item : any) => item.id == myCredential.id)
            if(search_item){
              setLoadingSubmit(true)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const getUnitBranch = async () => {
      try {
        const unitBranch = await getUnitBranchAll()
        setUnitBranch(unitBranch)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as unidades filiais. ", "error")
      }
    }

    const getMyPlantsImported = async () => {
      try {
        setLoading(true)
        const myPlantsImported = //plantsImportedArray
        await getPlantsImported(id ? id : "")
        
        const plants_imported = myPlantsImported.filter((plant: any) => plant.imported === true)
        setPlantsImported(plants_imported)
        plants_imported.map((plant: any) => addValuesData(plant.plantId, true))

        setPlantsNotImported(myPlantsImported.filter((plant: any) => plant.imported === false))
      
      } catch (error: any) {
        toggleToast(
          error && error.response 
          && error.response.data 
          && error.response.data.message 
          ? error.response.data.message 
          : "Erro ao buscar usinas nesse credencial!", "error"
        )

      }finally{
        setLoading(false)
      }
    }

    const atualizarTamanhoDaTela = () => {
      setLargura(window.innerWidth);
    };
  
    const refresh = () => {
      getMyPlantsImported()
    }

    // const submit = async () => {
    //   try {
    //     setLoadingSubmit(true)
    //     toggleToast("Processo de importação em andamento. Aguarde! ", "alert")
        
    //     var list_imports = sessionStorage.getItem('list_imports');
        
    //     if(list_imports){
    //       var my_list_imports = JSON.parse(list_imports);
    //       my_list_imports.push({credential: credential.username, status: true})
    //       var arrayString = JSON.stringify(my_list_imports);
    //       sessionStorage.setItem('list_imports', arrayString);
        
    //     }else{
    //       let my_list_import: any[] = [];
    //       my_list_import.push({credential: credential.username, status: true})
    //       var arrayString = JSON.stringify(my_list_import);
    //       sessionStorage.setItem('list_imports', arrayString);
    //     }

    //     await postLogin(dataGlobal)
    //     toggleToast(`Sucesso ao importar suas usinas! credential -  ${credential.username}, portal - ${credential.mark.name}`, "success")

    //     var list_imports_finaly = sessionStorage.getItem('list_imports');

    //     if(list_imports_finaly){
    //       var my_list_imports_finaly = JSON.parse(list_imports_finaly);
    //       const list_filter = my_list_imports_finaly.filter((item: any) => item.credential != credential.username)
    //       var arrayString = JSON.stringify(list_filter);
    //       sessionStorage.setItem('list_imports', arrayString);
    //     }
    //     setLoadingSubmit(false)
    //     refresh()
      
    //   } catch (error: any) {

    //     toggleToast(
    //       error && error.response 
    //       && error.response.data 
    //       && error.response.data.message 
    //       ? error.response.data.message 
    //       : `Erro ao importar as usinas! credencial - ${credential.username}, portal -  ${credential.mark.name}`, "error"
    //     )
        
    //     //toggleToast(`Erro ao importar as usinas! credentail - ${credential.username}, portal -  ${credential.mark.name}`, "error")

    //     var list_imports_finaly = sessionStorage.getItem('list_imports');

    //     if(list_imports_finaly){
    //       var my_list_imports_finaly = JSON.parse(list_imports_finaly);
    //       const list_filter = my_list_imports_finaly.filter((item: any) => item.credential != credential.username)
    //       var arrayString = JSON.stringify(list_filter);
    //       sessionStorage.setItem('list_imports', arrayString);
    //     }
    //     setLoadingSubmit(false)
    //     refresh()

    //   }
    // }

    const handleEventSocketImported = (data: any) => {
      if(data.idCredential && data.idCredential === id){
        setLoadingSubmit(false)
        refresh()
      }
    }

    const submit = async () => {
      try {
        setLoadingSubmit(true)
        addLoadingCredential(credential)

        const result = await importedPlants(dataGlobal)
        if(result){
          toggleToast(`${result.message}`, "alert")
        }
      
      } catch (error: any) {

        toggleToast(
          error && error.response 
          && error.response.data 
          && error.response.data.message 
          ? error.response.data.message 
          : `Erro ao importar as usinas! credencial - ${credential.username}, portal -  ${credential.mark.name}`, "error"
        )

        removeLoadingCredential(credential.id)
        setLoadingSubmit(false)
        //refresh()
      }
    }

    useEffect(() => {
      getCredential()
      getMyPlantsImported()
      getUnitBranch()

      window.addEventListener('resize', atualizarTamanhoDaTela);
      return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if(socket){
        on("message", handleEventSocketImported);
      }

      return () => {
        off("message", handleEventSocketImported);
      };

    }, [socket, on, off]);

    if (!credential) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
        
      )
      //return <h1>Carrengando...</h1>;
    }
  return (
    <div className='row g-5 g-xl-8'>
        <div className='mt-xl-9 mb-xl-0 py-3' style={{display: 'flex',gap: '1rem', alignItems: largura < 1200 ? 'start' : 'center',justifyContent: 'space-between', flexDirection: largura < 1200 ? 'column' : 'row'}}>
            <div className='border-0' style={{display: 'flex', alignItems: 'center'}}>
                <div className='symbol symbol-70px me-5 bg-light'>
                {credential && credential.mark && credential.mark.link_imagem ? 
                  (<img
                      src={toAbsoluteUrl(`${credential.mark.link_imagem}`)}
                      className=''
                      alt=''
                  />) 
                  :
                    <KTSVG path="/media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-3hx" />}
                    {/*<img src={toAbsoluteUrl('/media/avatars/300-6.jpg')} className='' alt='' />*/}
                </div>
                <h3 style={{display: "flex", flexDirection: "column"}}>
                    <span className='card-label fw-bold fs-1 mb-1'>{credential.mark.name ? credential.mark.name : "Portal"}</span>
                    <span className='text-muted mt-1 fw-semibold fs-6'>{credential.username ? credential.username : "credencial@credencial"}</span>
                </h3>
            </div> 
            {loadingSubmit && (
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                <h3 style={{display: "flex", flexDirection: "column"}}>
                  <span className='text-gray-700 mt-1 fw-semibold fs-6'>
                    Importando usinas, aguarde! {" "}{" "}
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>  
                  </span>
                </h3>
              </div>
            )}
        </div>
        
        <div className='row g-5 g-xl-3'>
          {
            user.currentUser?.role.slug !== "CLIENTE" &&(
              <div className='col-xl-6'>
                <TablePlantNotImported 
                  className='card-xl-stretch mb-xl-8' 
                  plants={plantsNotImported}
                  plantsImported={plantsImported} 
                  loading={loading}
                  addValuesData={addValuesData}
                  submit={submit}
                  loadingSubmit={loadingSubmit}
                  plants_list={dataGlobal.plants_list}
                  unit_branchs={unitBranch}
                  addValuesDataAll={addValuesDataAll}
                  addValuesDataAllStatus={addValuesDataAllTypePlano}
                />
              </div>
            ) 
          }
            <div className={`col-xl-${user.currentUser?.role.slug === "CLIENTE" ? "12" : "6"}`}>
                <TablePlantImported className='card-xl-stretch mb-5 mb-xl-8' plants={plantsImported} loading={loading}/>
            </div>
      </div>

        {/*<div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 pt-5 flex-column'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Usinas (Importadas)</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Total: 5 usinas</span>
                </h3>
            </div>
            
            <div className='card-body py-3'>
                <h1></h1>
            </div>
  </div>*/}
    </div>
    
  )
}

const PagePlantsImportedByCredential: FC = () => {

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{`Importação de usinas`}</PageTitle>
      <PagePlantsImported />
    </>
  )
}

export {PagePlantsImportedByCredential}
