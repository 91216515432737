/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useSize from '../../../../../hooks/useSize';
import styles from './pagination.module.css';

type Paginations = {
    page: number;
    limit: number;
    total: number;
    qtdPages: number;
};

type SetPaginations = {
    start: number;
    end: number;
};

type Props = {
    pagination: Paginations;
    setPagination: React.Dispatch<React.SetStateAction<Paginations>>;
    qtd: SetPaginations;
};

export function PaginationTablePlants({ pagination, setPagination, qtd }: Props) {
    const { width } = useSize();
    const generatePageNumbers = () => {
        const pages: (number | "...")[] = [];
        const totalNumbersToShow = 5;

        if (pagination.qtdPages <= totalNumbersToShow) {
            return Array.from({ length: pagination.qtdPages }, (_, i) => i + 1);
        }

        if (pagination.page <= 3) {
            pages.push(1, 2, 3, "...", pagination.qtdPages);
        } else if (pagination.page >= pagination.qtdPages - 2) {
            pages.push(1, "...", pagination.qtdPages - 2, pagination.qtdPages - 1, pagination.qtdPages);
        } else {
            pages.push(1, "...", pagination.page - 1, pagination.page, pagination.page + 1, "...", pagination.qtdPages);
        }

        return pages;
    };

    return (
        <div className='d-flex flex-stack flex-wrap pt-5'>
            {width > 1200 && (
                <div className='fs-6 fw-bold text-gray-700'>
                    Mostrando {qtd.start} a {qtd.end} de {pagination.total} encontradas
                </div>
            )}

            <ul className='pagination'>
                <li className={`page-item previous ${pagination.page === 1 ? "disabled" : ""}`}>
                    <button
                        className='page-link'
                        onClick={() =>
                            setPagination((prev) => ({ ...prev, page: Math.max(prev.page - 1, 1) }))
                        }
                        disabled={pagination.page === 1}
                    >
                        <i className='previous'></i>
                    </button>
                </li>

                {generatePageNumbers().map((p, index) => (
                    <li
                        key={index}
                        className={`page-item ${p === pagination.page ? "active" : ""} ${p === "..." ? "disabled" : ""}`}
                    >
                        {p === "..." ? (
                            <span className='page-link'>...</span>
                        ) : (
                            <button
                                className={`${width < 1200 ? `page-link btn btn-sm btn-primary fs-8 ${styles.buttonPagination}` : "page-link"} p-1`}
                                onClick={() => setPagination((prev) => ({ ...prev, page: p as number }))}
                            >
                                {p}
                            </button>
                        )}
                    </li>
                ))}

                <li className={`page-item next ${pagination.page === pagination.qtdPages ? "disabled" : ""}`}>
                    <button
                        className='page-link'
                        onClick={() =>
                            setPagination((prev) => ({
                                ...prev,
                                page: Math.min(prev.page + 1, pagination.qtdPages),
                            }))
                        }
                        disabled={pagination.page === pagination.qtdPages}
                    >
                        <i className='next'></i>
                    </button>
                </li>
            </ul>
        </div>
    );
}
