/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { getServicesAll, getServicesQtd } from '../../../../../../services/servicesService';
import { Loading } from '../../../../../components/Loadings/loading';
import { SearchInput } from '../../../../../components/SearchInput';
import { useToast } from '../../../../../components/Toats';
import { ModalDeleteOS } from '../ModalDeleteOS';
import { ModalDetailsOS } from '../ModalDetailsOS';
import { ItemOrderOfService } from './components/ItemOrderOfService';
import { ModalAddUpdateOrderService } from './components/ModalAddEditOrderService';
import { useAuth } from '../../../../../../modules/auth';
import DateSelectorDays from '../../../../../components/DateInputDays';
import { getCollaboratosAll } from '../../../../../../services/collaboratorsService';
import { getClientAll } from '../../../../../../services/clientService';
import { ModalTemplateOS } from '../ModalTemplateOS';

import styles from './tableOrderOfService.module.css';
import useSize from '../../../../../../hooks/useSize';
import { PaginationTablePlants } from '../../../../../dashboard/TablePlants/components/Pagination';

type Props = {
    className: string;
    refreshPage : boolean; 
    setRefreshPage: any;
  }


type Paginations = {
  page: number,
  limit: number,
  total: number,
  qtdPages: number
}

type SetPaginations = {
  start: number;
  end: number;
}
  
  const TableOrderOfService: React.FC<Props> = ({className, refreshPage}) => {
    
    const [mySearch, setMySearch] = useState([]);
    const [control, setControl] = useState(false);
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  //   const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
    //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
    const [myOrderService, setMyOrderService] = useState<any | null>(null);
    const [orderOfServices, setOrderOfServices] = useState<any>([]);
    const [orderOfServicesQtd, setOrderOfServicesQtd] = useState<any>([]);
    const { toggleToast } = useToast();
    const [orderOfServiceLoading, setOrderOfServicesLoading] = useState<boolean>(false)
    const [orderOfServiceQtdLoading, setOrderOfServicesQtdLoading] = useState<boolean>(false)
    const [largura, setLargura] = useState(window.innerWidth);
    const [showModalDetails, setShowModalDetails] = useState<boolean>(false)
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
    const [showModalTemplate, setShowModalTemplate] = useState<boolean>(false)
    const [filterStatus, setFilterStatus] = useState<string>("")
    const [collaborators, setCollaborators] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [filterDateInit, setFilterDateInit] = useState<any>("");
    const [filterDateEnd, setFilterDateEnd] = useState<any>("");
    const [filterCollaborator, setFilterCollaborator] = useState<any>("");
    const [filterClient, setFilterClient] = useState<any>("");
    const {currentUser} = useAuth();
    const {height, width} = useSize();

    const [pagination, setPagination] = useState<Paginations>({
      page: 1,
      limit: 25,
      total: 0,
      qtdPages: 1
    });
  
    const [qtd, setQtd] = useState<SetPaginations>({
      start: 0,
      end: 25,
    });

  
  const getReportServices = async () => {
    try {
      setOrderOfServicesLoading(true)
      const myServices = await getServicesAll(
        filterStatus,
        "",
        "",
        filterCollaborator,
        filterDateInit,
        filterDateEnd,
        filterClient
      )
      setOrderOfServices(myServices)
  
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as ordens de serviço!", "error")
    }finally{
      setOrderOfServicesLoading(false)
    }
  }

  const getServicesQtdAll = async () => {
    try {
      setOrderOfServicesQtdLoading(true)
      const myServicesQtd = await getServicesQtd()
      setOrderOfServicesQtd(myServicesQtd)
  
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os status!", "error")
    }finally{
      setOrderOfServicesQtdLoading(false)
    }
  }

  const getUsersAllCollaborators = async () => {
    try {
      //setLoadingCollaborators(true)
      const collaboratos = await getCollaboratosAll()
      setCollaborators(collaboratos)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os colaboradores. ", "error")

    }finally{
      //setLoadingCollaborators(false)
    }
  }

  const getClients = async () => {
    try {
      const myClient = await getClientAll()
      setClients(myClient)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os clientes!", "error")
    }finally{
    }
  }
  
  
  const refresh = () => {
    getReportServices()
    //getReportServices()
  }
  
  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };
  
  useEffect(() => {
    getReportServices()
    
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //getReportServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  
  useEffect(() => {
    getReportServices()
    getServicesQtdAll()
    getUsersAllCollaborators()
    getClients()
    //getReportServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  
  useEffect(() => {
    getReportServices()
  }, [
    filterStatus, 
    filterCollaborator, 
    filterDateEnd, 
    filterDateInit, 
    filterClient
  ]);

  if (orderOfServiceQtdLoading) {
    return (
      <div className='spinnerLoadingDashboard'>
        <Loading myClass='spinner-border spinnerDashboard'/>
      </div>
    );
  }

    
    return (
      <div className={`${className}`}>
        {/* begin::Header */}
        <div className='border-0'>
          <div 
            style={{
              display: 'flex', 
              gap: '1rem', 
              alignItems: largura < 1200 ? 'start' : 'center', 
              width: "100%", 
              justifyContent: 'space-between',
              flexDirection: largura < 1200 ? 'column' : 'row',
            }}>
              <h3 className='align-items-start flex-column' style={{display: "flex",paddingBottom: '0.5rem', marginBottom: largura < 1200 ? '0rem' : '1rem'}}>
                  <span className='card-label fw-bold fs-4 mb-1'>Ordens de Serviço</span>
                  <span className='text-muted fw-semibold fs-7'>Todas as ordens de serviço criadas</span>
              </h3>
          </div>
          
        </div>

        <div className='py-3' 
          //style={largura < 1200 ? {overflow: 'auto', height: '30rem'}: {overflow: 'auto', minHeight: '20rem', maxHeight: '48vh'}}
          style={{
            overflow: 'auto', 
            height: width < 1200 ? 
              height <= 750 ? '15vh'
            : height < 750 ? '1vh' 
            : height > 750 && height <= 850 ? '24vh'
            : height > 850 && height <= 930 ? '37vh'
            : `45vh`

            : height <= 400 ? '20vh'
            : height < 750  && height > 400 ? '30vh' 
            : height > 750 && height <= 850 ? '40vh'
            : height > 850 && height <= 930 ? '44vh'
            : `50vh`,
        }} 
        >
          <div className='table-responsive'>
            <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                  {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                      />
                    </div>
                  </th> */}
                  <th className='min-w-250px' 
                    style={{
                      display:'flex',
                      flexDirection: largura < 1200 ? 'column' : 'row',
                      gap: '1rem', 
                      alignItems: largura < 1200 ? 'end' : 'center', 
                      justifyContent: 'space-between'
                    }}>
                    {largura > 1200 ? 
                      <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("")
                            //setFilterAlarms(-1)
                          }}
                        >
                          Total 
                          <span 
                            className={`badge badge-dark ${styles.spanFilter}`}
        
                          >
                              {orderOfServicesQtd && orderOfServicesQtd.total ? orderOfServicesQtd.total : 0}
                          </span>
                        </a>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("AGUARDANDO")
                            //setFilterAlarms(3)
                          }}
                        >
                          AGUARDANDO
                          <span 
                            className={`badge badge-primary ${styles.spanFilter}`}
                          >
                            {orderOfServicesQtd && orderOfServicesQtd.aguardando ? orderOfServicesQtd.aguardando : 0}
                          </span>
                        </a>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("AGENDADO")
                            //setFilterAlarms(3)
                          }}
                        >
                          AGENDADO
                          <span 
                            className={`badge badge-info ${styles.spanFilter}`}
                          >
                          {orderOfServicesQtd && orderOfServicesQtd.agendado ? orderOfServicesQtd.agendado : 0}
                          </span>
                        </a>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("EM ANDAMENTO")
                            //setFilterAlarms(3)
                          }}
                        >
                          EM ANDAMENTO
                          <span 
                            className={`badge badge-warning ${styles.spanFilter}`}
                          >
                            {orderOfServicesQtd && orderOfServicesQtd.emAndamento ? orderOfServicesQtd.emAndamento : 0}
                          </span>
                        </a>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("FINALIZADO")
                            //setFilterAlarms(0)
                          }}
                        >
                          FINALIZADO
                          <span 
                            className={`badge badge-success ${styles.spanFilter}`}
                          >
                            {orderOfServicesQtd && orderOfServicesQtd.finalizado ? orderOfServicesQtd.finalizado : 0}
                          </span>
                        </a>
                        <a
                          className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterStatus("CANCELADO")
                          //   setFilterAlarms(1)
                          }}
                        >
                          CANCELADO
                          <span 
                            className={`badge badge-danger ${styles.spanFilter}`}
                          >
                            {orderOfServicesQtd && orderOfServicesQtd.cancelado ? orderOfServicesQtd.cancelado : 0}
                          </span>
                        </a>
                        
                      </div>
                      :
                      <div style={{width: '100%', display: 'flex', gap: '0.5rem'}}>
                        <div className='me-0 dropdown' style={{width: '100%'}}>
                        <button
                            className='btn btn-sm btn-light buttonFilterMark'
                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                        >
                            <span>Buscar pelo Status </span>
                            <i className='bi bi-three-dots fs-3'></i>
                        </button>

                        <div className='dropdown-menu w-100px w-md-100px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                            <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("")
                                  //setFilterAlarms(-1)
                                }}
                              >
                                Total 
                                <span 
                                  className={`badge badge-dark ${styles.spanFilter}`}
              
                                >
                                    {orderOfServicesQtd && orderOfServicesQtd.total ? orderOfServicesQtd.total : 0}
                                </span>
                              </a>


                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("AGUARDANDO")
                                  //setFilterAlarms(3)
                                }}
                              >
                                AGUARDANDO
                                <span 
                                  className={`badge badge-primary ${styles.spanFilter}`}
                                >
                                  {orderOfServicesQtd && orderOfServicesQtd.aguardando ? orderOfServicesQtd.aguardando : 0}
                                </span>
                              </a>

                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("AGENDADO")
                                  //setFilterAlarms(3)
                                }}
                              >
                                AGENDADO
                                <span 
                                  className={`badge badge-info ${styles.spanFilter}`}
                                >
                                {orderOfServicesQtd && orderOfServicesQtd.agendado ? orderOfServicesQtd.agendado : 0}
                                </span>
                              </a>

                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("EM ANDAMENTO")
                                  //setFilterAlarms(3)
                                }}
                              >
                                EM ANDAMENTO
                                <span 
                                  className={`badge badge-warning ${styles.spanFilter}`}
                                >
                                  {orderOfServicesQtd && orderOfServicesQtd.emAndamento ? orderOfServicesQtd.emAndamento : 0}
                                </span>
                              </a>
                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("FINALIZADO")
                                  //setFilterAlarms(0)
                                }}
                              >
                                FINALIZADO
                                <span 
                                  className={`badge badge-success ${styles.spanFilter}`}
                                >
                                  {orderOfServicesQtd && orderOfServicesQtd.finalizado ? orderOfServicesQtd.finalizado : 0}
                                </span>
                              </a>

                              <a
                                className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  setFilterStatus("CANCELADO")
                                //   setFilterAlarms(1)
                                }}
                              >
                                CANCELADO
                                <span 
                                  className={`badge badge-danger ${styles.spanFilter}`}
                                >
                                  {orderOfServicesQtd && orderOfServicesQtd.cancelado ? orderOfServicesQtd.cancelado : 0}
                                </span>
                              </a>
                            </div>
                        </div>
                        </div>

                        <div className='text-lowercase'>
                          <div className="dropdown">
                            <button 
                              type="button" 
                              className="btn btn-lg btn-light btn-active-color-primary btn-sm dropdown-toggle" 
                              data-bs-toggle="dropdown" 
                              aria-expanded="false" 
                              data-bs-auto-close="outside"
                            >
                              <span><KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-3'/></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end p-4" style={{width: 'auto', minWidth: '375px', maxWidth: '400px'}}>
                              <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Outros Filtros</label>
                              <div className='separator my-2'></div>

                              <div style={{display: 'flex',  gap: '1rem', flexWrap: 'wrap'}}>
                                <div>
                                  <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Data de Inicio</label>
                                  <DateSelectorDays 
                                      selectedDate={filterDateInit !== "" ? new Date(filterDateInit) : new Date()}
                                      //selectedDate={new Date()}
                                      setSelectedDate={(date: any) => {
                                          //formik.setValues({...formik.values, initPeriod: date.toString()})
                                          setFilterDateInit(date.toString())
                                      }}
                                  />
                                </div>

                                <div>
                                  <label className='form-label' style={{marginBottom: '0.5rem'}}>Data de Encerramento</label>
                                  <DateSelectorDays 
                                      //selectedDate={new Date(formik.values.initPeriod)}
                                      //selectedDate={new Date()}
                                      selectedDate={filterDateEnd !== "" ? new Date(filterDateEnd) : new Date()}
                                      setSelectedDate={(date: any) => {
                                          //formik.setValues({...formik.values, initPeriod: date.toString()})
                                          setFilterDateEnd(date.toString())
                                      }}
                                  />
                                </div>
                              </div>

                              <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Colaborador</label>
                                <div>
                                  <select
                                    className='form-select form-select-solid'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    defaultValue={filterCollaborator !== "" ? filterCollaborator : ""}
                                    value={filterCollaborator}
                                    onChange={(e: any) => {
                                      setFilterCollaborator(e.target.value);
                                    }}
                                  >
                                    <option value={""}></option>
                                    {collaborators && collaborators.map((collaborator: any) => {
                                      return (
                                        <option key={collaborator.id} value={collaborator.id}>{collaborator.name}</option>
                                      )
                                    })}
                                    
                                  </select>
                                </div>
                              </div>

                              <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                                <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Cliente</label>
                                <div>
                                  <select
                                    className='form-select form-select-solid'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    defaultValue={filterClient !== "" ? filterClient : ""}
                                    value={filterClient}
                                    onChange={(e: any) => {
                                      setFilterClient(e.target.value);
                                    }}
                                  >
                                    <option value={""}></option>
                                    {clients && clients.length > 0 && clients.map((client: any) => {
                                      return (
                                        <option key={client.id} value={client.id}>{client.nameResponsible}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className='separator my-2'></div>

                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <a 
                                  href="#" 
                                  className='btn btn-color-gray-600 btn-active-color-primary' 
                                  onClick={() => {
                                    setFilterDateEnd("")
                                    setFilterDateInit("")
                                    setFilterStatus("")
                                    setFilterCollaborator("")
                                    setFilterClient("")
                                  }}>
                                  Limpar todos os filtros <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
                                </a>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <div 
                      style={{
                        display: 'flex', 
                        gap: '1rem', 
                        flexDirection: largura < 1200 ? 'column' : 'row',
                        alignItems: 'center'
                    }}>
                      {largura > 1200 && <div className='text-lowercase'>
                        <div className="dropdown">
                          <button 
                            type="button" 
                            className="btn btn-lg btn-light btn-active-color-primary btn-sm dropdown-toggle" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false" 
                            data-bs-auto-close="outside"
                          >
                            <span><KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-3'/></span>
                          </button>
                          <div className="dropdown-menu dropdown-menu-end p-4" style={{width: 'auto', minWidth: '375px', maxWidth: '400px'}}>
                            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Outros Filtros</label>
                            <div className='separator my-2'></div>

                            <div style={{display: 'flex',  gap: '1rem'}}>
                              <div>
                                <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Data de Inicio</label>
                                <DateSelectorDays 
                                    selectedDate={filterDateInit !== "" ? new Date(filterDateInit) : new Date()}
                                    //selectedDate={new Date()}
                                    setSelectedDate={(date: any) => {
                                        //formik.setValues({...formik.values, initPeriod: date.toString()})
                                        setFilterDateInit(date.toString())
                                    }}
                                />
                              </div>

                              <div>
                                <label className='form-label' style={{marginBottom: '0.5rem'}}>Data de Encerramento</label>
                                <DateSelectorDays 
                                    //selectedDate={new Date(formik.values.initPeriod)}
                                    //selectedDate={new Date()}
                                    selectedDate={filterDateEnd !== "" ? new Date(filterDateEnd) : new Date()}
                                    setSelectedDate={(date: any) => {
                                        //formik.setValues({...formik.values, initPeriod: date.toString()})
                                        setFilterDateEnd(date.toString())
                                    }}
                                />
                              </div>
                            </div>

                            <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                              <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Colaborador</label>
                              <div>
                                <select
                                  className='form-select form-select-solid'
                                  data-kt-select2='true'
                                  data-placeholder='Select option'
                                  data-allow-clear='true'
                                  defaultValue={filterCollaborator !== "" ? filterCollaborator : ""}
                                  value={filterCollaborator}
                                  onChange={(e: any) => {
                                    setFilterCollaborator(e.target.value);
                                  }}
                                >
                                  <option value={""}></option>
                                  {collaborators && collaborators.map((collaborator: any) => {
                                    return (
                                      <option key={collaborator.id} value={collaborator.id}>{collaborator.name}</option>
                                    )
                                  })}
                                  
                                </select>
                              </div>
                            </div>

                            <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                              <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Cliente</label>
                              <div>
                                <select
                                  className='form-select form-select-solid'
                                  data-kt-select2='true'
                                  data-placeholder='Select option'
                                  data-allow-clear='true'
                                  defaultValue={filterClient !== "" ? filterClient : ""}
                                  value={filterClient}
                                  onChange={(e: any) => {
                                    setFilterClient(e.target.value);
                                  }}
                                >
                                  <option value={""}></option>
                                  {clients && clients.length > 0 && clients.map((client: any) => {
                                    return (
                                      <option key={client.id} value={client.id}>{client.nameResponsible}</option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className='separator my-2'></div>

                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <a 
                                href="#" 
                                className='btn btn-color-gray-600 btn-active-color-primary' 
                                onClick={() => {
                                  setFilterDateEnd("")
                                  setFilterDateInit("")
                                  setFilterStatus("")
                                  setFilterCollaborator("")
                                  setFilterClient("")
                                }}>
                                Limpar todos os filtros <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
                              </a>
                            </div>

                          </div>
                        </div>
                      </div>}

                      <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                        <SearchInput 
                          className='form-control form-control-solid bg-body ps-14'
                          placeholder='Buscar por Nº O.S'
                          myDate={orderOfServices}
                          setMySearch={setMySearch}
                          control={control}
                          style={{width: largura < 1200 ? "100%" : "200px"}}
                          setControl={setControl}
                          searchParameter="number_order_service"
                        />
                      </div>

                      
                      {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' && 
                      
                      <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        //title='Click para adicionar uma unidade filial'
                        style={largura < 1200 ? {width: "100%", display: 'flex', justifyContent: 'end', marginBottom: '0.5rem'} : {marginBottom: '0.5rem'}}
                      >
                        <a
                          onClick={() =>{setShowCreateAppModal(true)}}
                          href='#'
                          className='btn btn-sm btn-light-primary'
                          //data-bs-target='#kt_modal_create_unitBranch'

                        >
                          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                          Nova O.S.
                        </a>
                      </div>}
                    </div>
                    
                  </th>
                  {/* <th className='min-w-80px'>Status</th>
                  <th className='min-w-80px'>Status</th> 
                  <th className='min-w-150px'>Cliente</th>
                  <th className='min-w-50px text-end'>Actions</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
                {
                  orderOfServiceLoading ? 
                    <>
                      Carregando....
                    </> 
                  
                  :
                  control === false ? (
                    // <TableBody 
                    //   reportServices={reportServices} 
                    //   setMyReportService={setMyReportService}
                    //   //setShowCreateAppModal={setShowCreateAppModal}
                    //   //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    //   setShowModalStatus={setShowModalStatus}
                    //   setShowCreateAppModalDelete={() => {}}
                    //   setShowModalDetails={setShowModalDetails}
                    // />
                    <tbody >
                      {orderOfServices && orderOfServices.length > 0 ?
                        orderOfServices.map((orderService: any) => {

                          if (orderService.dateInit) {
                            let date = new Date(orderService.dateInit);
                            date.setHours(date.getHours() + 3);
                            orderService.dateInit = date.toISOString();
                          }
                          
                          if (orderService.dateEnd) {
                            let date = new Date(orderService.dateEnd);
                            date.setHours(date.getHours() + 3);
                            orderService.dateEnd = date.toISOString();
                          }

                          return (
                          <tr onClick={() => {
                            setMyOrderService(orderService)
                            setShowModalDetails(true)
                          }}>
                            <ItemOrderOfService 
                              itemOrderOfService={orderService}
                              refresh={refresh}
                              setShowModalDelete={setShowModalDelete}
                              setMyOrderService={setMyOrderService}
                              setShowCreateAppModal={setShowCreateAppModal}
                              setShowModalTemplate={setShowModalTemplate}
                            />
                          </tr>
                          )
                        })
                      : 
                      <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                          <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                              <span className='text-muted fw-semibold text-muted d-block fs-4'>
                                  Ordens de serviços não encontradas!
                              </span>
                          </div>
                        </td>
                      </tr>
                    }
                        
                    </tbody>
                  )
                  : (
                    // <TableBody 
                    //   reportServices={mySearch} 
                    //   setMyReportService={setMyReportService}
                    //   //setShowCreateAppModal={setShowCreateAppModal}
                    //   //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    //   setShowModalStatus={setShowModalStatus}
                    //   setShowCreateAppModalDelete={() => {}}
                    //   setShowModalDetails={setShowModalDetails}
                    // />
                    <tbody>
                        {mySearch && mySearch.length > 0 ?
                        mySearch.map((orderService: any) => {
                          return (
                          <tr onClick={() => {
                            setShowModalDetails(true)
                          }}>
                            <ItemOrderOfService 
                                itemOrderOfService={orderService}
                                refresh={refresh}
                                setShowModalDelete={setShowModalDelete}
                                setMyOrderService={setMyOrderService}
                                setShowCreateAppModal={setShowCreateAppModal}
                                setShowModalTemplate={setShowModalTemplate}
                            />
                          </tr>
                          )
                        })
                      : 
                      <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                          <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                              <span className='text-muted fw-semibold text-muted d-block fs-4'>
                                  Ordens de serviços não encontradas!
                              </span>
                          </div>
                        </td>
                      </tr>
                    }
                    </tbody>
                  )
                  }
                
            </table>
          </div>
        </div>

        <div className='card-footer pt-0 pb-0'>
          <PaginationTablePlants 
            pagination={pagination}
            setPagination={setPagination}
            qtd={qtd}
          />
        </div>

          <ModalAddUpdateOrderService
            show={showCreateAppModal}
            id={myOrderService ? myOrderService.id : undefined} 
            data={myOrderService ? myOrderService : undefined}
            handleClose={() => {
              setMyOrderService(null)
              setShowCreateAppModal(false)
              refresh()
            }} 
          />
          <ModalDeleteOS
            show={showModalDelete}
            data={myOrderService ? myOrderService : undefined}
            id={myOrderService ? myOrderService.id : undefined} 
            handleClose={() => {
              setMyOrderService(null)
              setShowModalDelete(false)
              refresh()
            }} 
          />

          <ModalDetailsOS
            show={showModalDetails}
            data={myOrderService ? myOrderService : undefined}
            id={myOrderService ? myOrderService.id : undefined} 
            handleClose={() => {
              setMyOrderService(null)
              setShowModalDetails(false)
              refresh()
            }} 
          />
  
        {<ModalTemplateOS
          show={showModalTemplate}
          id={myOrderService ? myOrderService.id : undefined} 
          handleClose={() => {
            setMyOrderService(null)
            setShowModalTemplate(false)
            refresh()
          }} 
        />}
          {/* <ModalDetailsClient
            show={showCreateAppModalDetails}
            id={myClient ? myClient.id : undefined} 
            handleClose={() => {
              setMyClient(null)
              setShowCreateAppModalDetails(false)
              refresh()
            }} 
          /> */}
        {/* begin::Body */}
      </div>
    )
  }
  
  export {TableOrderOfService}