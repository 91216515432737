import api from "./api";

export const getClientAll = async (unitBranch?: string, linkedUser?: boolean, idUser?: string) => {
    const response = await api.get(`client/listAll?unitBranch=${unitBranch ? unitBranch : "" }${linkedUser !== undefined ? `&linkedUser=${linkedUser}` : ""}${idUser ? `&userId=${idUser}` : ""}`);
    return response.data;
};

export const getClientAllPagination = async (unitBranch?: string, linkedUser?: boolean, idUser?: string, limit?: number, page?: number) => {
    const response = await api.get(`client/listAllPagination?unitBranch=${unitBranch ? unitBranch : "" }${linkedUser !== undefined ? `&linkedUser=${linkedUser}` : ""}${idUser ? `&userId=${idUser}` : ""}${limit ? `&limit=${limit}` : ""}${page ? `&page=${page}` : ""}`);
    return response.data;
};

export const postClient = async (body: any) => {
    const response = await api.post(`client/`, body);
    return response.data;
};

export const postClientsAll = async (body: any) => {
    const response = await api.post(`client/createAll`, body);
    return response.data;
};

export const updateClient = async (body: any, id: string) => {
    const response = await api.put(`client/${id}`, body);
    return response.data;
};

export const deleteClient = async (id: string) => {
    const response = await api.delete(`client/${id}`);
    return response.data;
};

export const getClientOne = async (id: string) => {
    const response = await api.get(`client/${id}`);
    return response.data;
};

