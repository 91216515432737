/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import useSize from '../../../../../../hooks/useSize';
import { SearchInput } from '../../../../../components/SearchInput';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { getplantsAll } from '../../../../../../services/plantsService';
import { useToast } from '../../../../../components/Toats';
import { Loading } from '../../../../../components/Loadings/loading';

type Props = {
  className: string;
  formik: any
}

const ListPlantsLinked: React.FC<Props> = ({className, formik}) => {
  const [mySearch, setMySearch] = useState([]);
  const [control, setControl] = useState(false);
  const {width} = useSize();
  const [plants, setPlants] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { toggleToast } = useToast();

  const updateData = (value: string, checked: boolean) => {
    let valuesFormik = formik.values.idsPlants
    if(checked === true){
      valuesFormik.push(value)
    }
    // else{
    //   valuesFormik = valuesFormik.filter((id: string) => id !== value)
    // }
    formik.setFieldValue('idsPlants', valuesFormik)
  }

  const getPlants = async () => {
    try {
      setLoading(true)
      let plants = await getplantsAll(`?unitBranch=${formik.values.idUnitBranch ? formik.values.idUnitBranch : ""}`)
      plants = plants.map((plant: any) => {
        const exist = formik.values.idsPlants.some((id: string) => id === plant.id)
        plant.imported = exist ? true : false
        return plant
      })
      plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
      
      setPlants(plants)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    getPlants()
  }, []);

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5' style={{display: 'flex',flexDirection: 'row'}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Usina Vinculada à O.S</span>
        </h3>

        <SearchInput 
          className={`form-control form-control-solid bg-body ps-14 ${width < 1200 ? "fs-7" : "fs-7"}`}
          placeholder='Buscar pelo Nome'
          myDate={plants}
          setMySearch={setMySearch}
          control={control}
          style={{width: width < 1200 ? "100%" : "200px", height: '3rem'}}
          setControl={setControl}
          searchParameter="name"
        />

      </div>

      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {
          plants && plants.length > 0 ?
          control ?
            mySearch.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {plant.name}
                      </a>
                      <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idsPlants.some((id: string) => id === plant.id) ? 'bg-primary' : 'bg-secondary'}`}
                      type='checkbox'
                      checked={formik.values.idsPlants.some((id: string) => id === plant.id) ? true : false}
                      //checked={formik.values.idPlant === plant.id ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                    />
                  </div>
                </div>
              )
            })
          :
            plants.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {plant.name}
                      </a>
                      <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idsPlants.some((id: string) => id === plant.id) ? 'bg-primary' : 'bg-secondary'}`}
                      type='checkbox'
                      //checked={plant.imported}
                      //checked={formik.values.idPlant === plant.id ? true : false}
                      checked={formik.values.idsPlants.some((id: string) => id === plant.id) ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idPlant")}
                    />
                  </div>
                </div>
              )
            })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas vinculadas.</span>
            </h3>
          </div>
        }
      </div>
    </div>
  )
}

export {ListPlantsLinked}
