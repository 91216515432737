import React from 'react';




export function DropdownMenuDatesInformations({myTemplateSelected, linkImgGraphics}: any) {
    return (
        <div className='dropdown-menu w-250px w-md-500px dropdown-menu-end' aria-labelledby="dropdownMenuButton1">
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Dados do Relatório</div>
            </div>
    
            <div className='separator border-gray-200'></div>
            
            {
                !myTemplateSelected.type_template || myTemplateSelected.type_template === "DETALHADO" ?
                    <div className='px-7 py-5' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', overflow: 'auto', height: '40rem' , }}>
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Mês Referência</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{month}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Inicio Período</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{initPeriod}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Fim Período</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{endPeriod}}"}</span>
                    </label>
        
                    <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geração</label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Geração (Kwh)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{generation}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Performance (%)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{performance}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Consumo (KWh)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{consumption}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Economizado (R$)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{saved}}"}</span>
                    </label>



                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Total Economizado (R$)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{total_economy}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Faturado (R$)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{invoiced}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Retorno Financeiro Mensal (%)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{financial_feedback_month}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Retorno Financeiro Total (%)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{financial_feedback_year}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Arvores Salvas</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{arvores_salvas}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Deixado de produzir (CO2)</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{economia_co2}}"}</span>
                    </label>
                    
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Gráfico dos Dias</span>
                        <a 
                            className='form-check-label text-dark fs-7 fw-semibold text-hover-primary' 
                            href={linkImgGraphics && linkImgGraphics.graphic_days ? linkImgGraphics.graphic_days : "#"}
                            target='_blank' rel="noreferrer"
                            style={{width: '15rem', whiteSpace: "nowrap", overflow: "clip"}}
                        >
                            ({linkImgGraphics && linkImgGraphics.graphic_days ? linkImgGraphics.graphic_days : ""})
                        </a>
                    </label>


                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Gráfico do consumo</span>
                        <br />
                        <a 
                            className='form-check-label text-dark fs-7 fw-semibold text-hover-primary' 
                            href={linkImgGraphics && linkImgGraphics.chart_comsuption ? linkImgGraphics.chart_comsuption : "#"}
                            target='_blank' rel="noreferrer"
                            style={{width: '15rem', whiteSpace: "nowrap", overflow: "clip"}}
                        >
                            ({linkImgGraphics && linkImgGraphics.chart_comsuption ? linkImgGraphics.chart_comsuption : ""})
                        </a>
                    </label>
                    
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Gráfico dos Meses Anteriores</span>
                        <br />
                        <a 
                            className='form-check-label text-dark fs-7 fw-semibold text-hover-primary' 
                            href={linkImgGraphics && linkImgGraphics.graphic_months ? linkImgGraphics.graphic_months : "#"}
                            target='_blank' rel="noreferrer"
                            style={{width: '15rem', whiteSpace: "nowrap", overflow: "clip"}}
                        >
                            ({linkImgGraphics && linkImgGraphics.graphic_months ? linkImgGraphics.graphic_months : ""})
                        </a>
                    </label>

                    <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geradora / Unidade</label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Consumo Registrado</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{consumerRegister}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Consumo Instantâneo</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{consumerInstant}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Consumo Total</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{unitConsumerTotal}}"}</span>
                    </label>

                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Energia Injetada</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{energyInjected}}"}</span>
                    </label>
                    
        
                    <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa / Filial</label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Nome da Empresa</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{corporateName}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>CNPJ da Empresa</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{cnpj}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Nome da Filial</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{companyName}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>E-mail da Filial</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{emailCompany}}"}</span>
                    </label>
        
                    <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Usina</label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Nome da Usina</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{namePlant}}"}</span>
                    </label>
        
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Endereço da Usina</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{adressPlant}}"}</span>
                    </label>
        
                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                        <span className='form-check-label fs-4'>Portal da Usina</span>
                        <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{mark}}"}</span>
                    </label>
                    </div>
                :
                myTemplateSelected.type_template === "GERACAO" &&
                    <div className='px-7 py-5' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', overflow: 'auto', height: '40rem' , }}>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Mês Referência</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{month}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Inicio Período</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{initPeriod}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Fim Período</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{endPeriod}}"}</span>
                        </label>
            
                        <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geração</label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Geração (Kwh)</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{generation}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Geração - Ult. 12 meses (KWh)</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{generation_anual}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Performance (%)</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{performance}}"}</span>
                        </label>
            
            
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Performance - Ult. 12 meses (%)</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{prog_12_meses}}"}</span>
                        </label>
            
            
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Gráfico dos Dias</span>
                            <br />
                            <a 
                                className='form-check-label text-dark fs-7 fw-semibold text-hover-primary' 
                                href={linkImgGraphics && linkImgGraphics.graphic_days ? linkImgGraphics.graphic_days : "#"}
                                target='_blank' rel="noreferrer"
                                style={{width: '20rem', whiteSpace: "nowrap", overflow: "clip"}}
                            >
                                ({linkImgGraphics && linkImgGraphics.graphic_days ? linkImgGraphics.graphic_days : ""})
                            </a>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Gráfico dos ultimos 12 meses</span>
                            <br />
                            <a 
                                className='form-check-label text-dark fs-7 fw-semibold text-hover-primary' 
                                href={linkImgGraphics && linkImgGraphics.graphic_mensal_combination ? linkImgGraphics.graphic_mensal_combination : "#"}
                                target='_blank' rel="noreferrer"
                                style={{width: '20rem', whiteSpace: "nowrap", overflow: "clip"}}
                            >
                                ({linkImgGraphics && linkImgGraphics.graphic_mensal_combination ? linkImgGraphics.graphic_mensal_combination : ""})
                            </a>
                        </label>
                        
            
                        <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa / Filial</label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Nome da Empresa</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{corporateName}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>CNPJ da Empresa</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{cnpj}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Nome da Filial</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{companyName}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>E-mail da Filial</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{emailCompany}}"}</span>
                        </label>
            
                        <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Usina</label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Nome da Usina</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{namePlant}}"}</span>
                        </label>

                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Potência da Usina</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{capacity}}"}</span>
                        </label>
            
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Endereço da Usina</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{adressPlant}}"}</span>
                        </label>
            
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                            <span className='form-check-label fs-4'>Portal da Usina</span>
                            <span className='form-check-label text-dark fs-7 fw-semibold'>{"{{mark}}"}</span>
                        </label>
                    </div>
            }
        </div>
    );
}
    