/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import { ProfileHeaderSettings } from '../profileHeaderSettings';
import { BodySettingsUnitBranch } from './components/bodySettingsUnitBranch';
import { RulesPlant } from '../settingsCompany/components/bodySettingsCompany/components/RulesPlant';
//import { MyPortal } from './components/my-portal'

const settingsUnitBranchBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsUnitBranchPage: FC = () => {
  return (
    <div className='column g-5 g-xl-8'>
        <ProfileHeaderSettings />
        <BodySettingsUnitBranch />
        <RulesPlant />
        
    </div>
  )
}

const SettingsUnitBranchWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={settingsUnitBranchBreadcrumbs}>Configurações de Unidade (Filial)</PageTitle>
      <SettingsUnitBranchPage />
    </>
  )
}

export {SettingsUnitBranchWrapper}

