import api from "./api";

export const postRulePlantOne = async (body: any) => {
    const response = await api.post(`rulePlant/`, body);
    return response.data;
};

export const getRulePlantAll = async () => {
    const response = await api.get(`rulePlant/listAll`,);
    return response.data;
};

export const putRulePlantAll = async (id: string, body: any) => {
    const response = await api.put(`rulePlant/${id}`, body);
    return response.data;
};

export const deleteRulePlantAll = async (id: string) => {
    const response = await api.delete(`rulePlant/${id}`);
    return response.data;
};