/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'


import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
//import { defaultCreateAppData, ICreateAppData } from '../../../../../_metronic/partials/modals/create-app-stepper/IAppModels'

//import { postCredentialVerificad } from '../../../../services/credentialService'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
//import { getCompanyAll } from '../../../../services/companyService'
//import { postUnitBranch, updateUnitBranch } from '../../../../services/unitBranchService'
import { StepModal } from './components/stepModal'
//import { postClient, updateClient } from '../../../../services/clientService'
//import { postUnitConsumerAll, updateUnitConsumerAll } from '../../../../services/unitConsumerService'
import { updateInvoice } from '../../../../services/invoices'
import { DateCompleteBarra } from '../../../../utils/date'
import useSize from '../../../../hooks/useSize'

type DataForm = {
  mes: Date | null,
  data_vencimento: string | null,
  energia_faturada: string | null,
  energia_injetada: string | null,
  energia_registrada: string | null,
  energia_compensada: string | null,
  leitura_atual: string | null,
  leitura_anterior: string | null,
  tarifa: string | null,
  creditos: string | null,
  tipo_fornecimento: string | null,
  valor_faturado: string | null,
  confirm_payment: boolean | null,
  link_file: string | null,
  base64: string | null,
  status: string | null,
  idConcessionarie: string | null,
  idUnitConsumer: string | null,
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  mes: Yup.string().required("Informe o mês referencia"),
  data_vencimento: Yup.string().required("Informe a data de vencimento da fatura"),
  energia_faturada: Yup.string().required("Informe a energia faturada"),
  energia_injetada: Yup.string().required("Informe a energia injetada"),
  energia_registrada: Yup.string().required("Informe a energia registrada"),
  energia_compensada: Yup.string().required("Informe a energia compensada"),
  leitura_atual: Yup.string().required("Informe a leitura atual"),
  leitura_anterior: Yup.string().required("Informe a leitura anterior"),
  tarifa: Yup.string().required("Informe a tarifa"),
  creditos: Yup.string(),
  tipo_fornecimento: Yup.string().required("Informe o tipo de fornecimento"),
  valor_faturado: Yup.string().required("Informe o valor faturado"),
  confirm_payment: Yup.boolean(),
  link_file: Yup.string(),
  base64:  Yup.string(),
  status: Yup.string(),
  idConcessionarie: Yup.string().required("Informe a concessionária"),
  idUnitConsumer: Yup.string().required("Informe a unidade consumidora."),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateInvoice = ({show, handleClose, data, id}: Props) => {


  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const {width} = useSize()

  const [initialValues, setInitialValues] = useState<DataForm>({
    mes: new Date(),
    data_vencimento: null,
    energia_faturada: null,
    energia_injetada: null,
    energia_registrada: null,
    energia_compensada: null,
    leitura_atual: new Date().toISOString(),
    leitura_anterior: new Date().toISOString(),
    tarifa: null,
    tipo_fornecimento: null,
    valor_faturado: null,
    link_file: null,
    creditos: null,
    base64: null,
    status: "NÃO INFORMADO",
    idConcessionarie: null,
    idUnitConsumer: null,
    confirm_payment: false
  })
  const [hasError, setHasError] = useState(false)
  //const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.mes 
        || !formik.values.idUnitConsumer
        || !formik.values.energia_faturada
        || !formik.values.energia_registrada
        || !formik.values.leitura_anterior
        || !formik.values.tarifa
        || !formik.values.valor_faturado 
      ) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  // const updateUnitConsumerSubmit = async () => {
  //   try {
  //     setLoading(true)
  //     const myData = {
  //       mes: formik.values.mes,
  //       status: "INFORMADO",
  //       idUnitConsumer: formik.values.idUnitConsumer
  //     }
  //     Object.keys(myData).forEach((prop) => {
  //       if (myData[prop] === null) {
  //         delete myData[prop];
  //       }
  //     });

  //     const myFilial = await postUnitConsumerAll(myData)
  //     toggleToast("Sucesso ao cadastrar a unidade consumidora. ", "success")
  //     console.log(myFilial)

  //   } catch (error) {
  //     console.log('erro',error)
  //     toggleToast("Erro ao cadastrar o cliente. ", "error")
  //   }finally{
  //     setLoading(false)
  //     closed()
  //     // handleClose()
  //     // setTimeout(()=>{
  //     //   window.location.reload()
  //     // }, 2000);
  //   }
  // }

  const updateUnitConsumerSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        data_vencimento: formik.values.data_vencimento ? formik.values.data_vencimento : null,
        energia_faturada: formik.values.energia_faturada ? formik.values.energia_faturada : null,
        energia_injetada: formik.values.energia_injetada ? formik.values.energia_injetada : null,
        energia_registrada: formik.values.energia_registrada ? formik.values.energia_registrada : null,
        energia_compensada: formik.values.energia_compensada ? formik.values.energia_compensada : null,
        leitura_atual: formik.values.leitura_atual ? formik.values.leitura_atual : null,
        leitura_anterior: formik.values.leitura_anterior ? formik.values.leitura_anterior : null,
        tarifa: formik.values.tarifa ? formik.values.tarifa : null,
        tipo_fornecimento: formik.values.tipo_fornecimento ? formik.values.tipo_fornecimento : null,
        valor_faturado: formik.values.valor_faturado ? formik.values.valor_faturado : null,
        link_file: formik.values.link_file !== undefined ? formik.values.link_file : null,
        creditos: formik.values.creditos ? formik.values.creditos : null,
        base64: formik.values.base64 ? formik.values.base64 : null,
        confirm_payment: formik.values.confirm_payment ? formik.values.confirm_payment : false,
        idUnitConsumer: formik.values.idUnitConsumer ? formik.values.idUnitConsumer : null,
        status: 
          formik.values.energia_faturada && 
          formik.values.energia_registrada && 
          formik.values.leitura_atual &&
          formik.values.leitura_anterior &&
          formik.values.tarifa &&
          formik.values.valor_faturado
          ? "INFORMADO" : formik.values.status,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateInvoice(id, myData)
      toggleToast("Sucesso ao atualizar a fatura. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a fatura. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 3000);
    }
  }

  const submit = () => {
    if(id){
      updateUnitConsumerSubmit(id)
    }else{
      //postUnitConsumerSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        mes: data.mes ? data.mes :  new Date(),
        data_vencimento: data.data_vencimento ? data.data_vencimento : null,
        energia_faturada: data.energia_faturada ? data.energia_faturada : null,
        energia_injetada: data.energia_injetada ? data.energia_injetada : null,
        energia_registrada: data.energia_registrada ? data.energia_registrada : null,
        energia_compensada: data.energia_compensada ? data.energia_compensada : null,
        leitura_atual: data.leitura_atual ? data.leitura_atual : new Date().toString(),
        leitura_anterior: data.leitura_anterior ? data.leitura_anterior : new Date().toString(),
        tarifa: data.tarifa ? data.tarifa : null,
        tipo_fornecimento: data.tipo_fornecimento ? data.tipo_fornecimento : null,
        valor_faturado: data.valor_faturado ? data.valor_faturado : null,
        link_file: data.link_file ? data.link_file : null,
        base64: data.base64 ? data.link_file : null,
        status: data.status ? data.status : null,
        creditos: data.creditos ? data.creditos : null,
        idConcessionarie: data.idConcessionarie ? data.idConcessionarie : null,
        idUnitConsumer: data.idUnitConsumer ? data.idUnitConsumer : null,
        confirm_payment: data.confirm_payment ? data.confirm_payment : false
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])

  useEffect(() => {
    console.log(formik.values)
  }, [formik.values])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered ${width < 1200 ? "modal-fullscreen" : "modal-xl"}`}
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Fatura {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >

              <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} id={id}/>

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  {
                    // <div className='fv-plugins-message-container'>
                    //   <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    //     o CNPJ é obrigatório!
                    //   </div>
                    // </div>
                  }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateInvoice}
