import React from 'react';
import { Company } from '../../../../@types/companies';
import { Statistics } from '../../../../@types/statistics';
import { getStatisticsPlants } from '../../../../services/statiscsDateService';
import { Loading } from '../../../components/Loadings/loading';
import { StatisticsWidget5 } from '../../../../../_metronic/partials/widgets';
import { CardsRequestService } from '../../../../../_metronic/partials/widgets/_new/cards/CardsRequestService';
import { CardsReports } from '../../../../../_metronic/partials/widgets/_new/cards/CardsReports';
import { useQuery } from 'react-query';
import useSize from '../../../../hooks/useSize';

import styles from './statisticsMenuCompany.module.css';
import { CardsPlanosCompany } from '../../../../../_metronic/partials/widgets/_new/cards/CardsPlanosCompany';

type Props = {
  company?: Company | null;
}

export function StatisticsMenuCompany(props: Props) {
  const {width: largura} = useSize();
    
  const { data: statistics, isLoading: loading,  } = useQuery<Statistics>(
    "getStatistics",
    async () => {
      const myStatistics = await getStatisticsPlants();
      return myStatistics;
    },
  );

  if (loading) {
    return (
      <div className={styles.divSpinnerStatics}>
        <Loading myClass={`spinner-border ${styles.mySpinnerStatics}`}/>
      </div>
    );
  }

  return (
    <div
      className={`row g-5 g-xl-8 pt-1 card pb-${largura < 1200 ? '3 bg-transparent' : '7 bg-light'} ${styles.divGlobalStatistics}`}
    >
      <div
        className={`px-${largura < 1200 ? '5' : '9'} pt-${largura < 1200 ? '3' : '7'} card-rounded w-100 ${styles.divTitleStatistics} bg-primary`}   
      >
        <div 
          className={`text-dark fw-bold fs-2 mb-2 mt-${largura < 1200 ? '7' : '5'} ${styles.divTitleStatisticsV2}`}
        >
          {largura < 1200 ? 
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-black mt-1 fw-semibold fs-5'>
                {
                  props.company
                  ? props.company.corporateName
                  : "EMPRESA"
                }
              </span>
            </h3>
            : 
            <h1>
              {
                props.company
                ? props.company.corporateName
                : "EMPRESA"
              }
            </h1>
          }
        </div>
      </div>
      
      {largura < 1200 ? 
        <div className={styles.divCardStatistics}>
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" style={{padding: '0.5rem'}}>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className={styles.divCardStatisticsV2}>
                  <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/finance/fin010.svg'
                    color='dark'
                    iconColor='light'
                    title={`${statistics && statistics.incomeTotal && statistics.incomeTotal > 0 
                      ? statistics.incomeTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                      : '0,00'}`}
                    description='Economia Total (R$)'
                    style={{width: '100%'}}
                    descriptionColor='primary'
                    titleColor='muted'
                    largura={largura}
                  />

                  <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/arrows/arr059.svg'
                    color='dark'
                    iconColor='light'
                    descriptionColor='primary'
                    titleColor='muted'
                    style={{width: '100%'}}
                    title={`${statistics && statistics.financial_feedback ? statistics.financial_feedback.toFixed(2) : 0 }`}
                    description='Retorno Financeiro (%)'
                    largura={largura}
                  />

                  <StatisticsWidget5
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/general/gen032.svg'
                    color='dark'
                    iconColor='light'
                    title={`${statistics && statistics.energyTotal ? statistics.energyTotal.toFixed(2) : 0 }`}
                    description='Energia Total (KWh)'
                    style={{width: '100%'}}
                    descriptionColor='primary'
                    titleColor='muted'
                    largura={largura}
                  />
                </div>
              </div>
            </div>
          </div>

          <div id="carouselExampleControlsV2" className={`carousel carousel-dark slide ${styles.divSubCardsStatistics}`} data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active" style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                <div className={styles.divSubCardStatisticsV2}>
                  <CardsRequestService
                    type='cell' 
                    className={largura > 1200 ? 'pb-3 mb-5 mb-xl-10' : ''}
                    requestServices={statistics && statistics.request_service ? statistics.request_service : {}}
                    largura={largura} 
                  />
                  {largura > 1200 && 
                  <CardsReports 
                    className='pb-3 mb-5 mb-xl-10' 
                    reports={statistics && statistics.report ? statistics.report : {}}  
                    type='cell'
                    largura={largura} 
                  />}

                  {largura > 1200 &&
                    <CardsPlanosCompany 
                      className='pb-3 mb-5 mb-xl-10' 
                      planosCompany={statistics && statistics.planosCompany ? statistics.planosCompany : {}}
                      type='cell'  
                      largura={largura} 
                    />
                  }
                </div>


              </div>
            </div>
          </div>
        </div>
      :
        <div className={styles.divCardStatistics}>
          <div className={`g-5 py-3 px-6 ${styles.divCardStatisticsV2}`}>
            <div className={styles.divCardUnique}>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen001.svg'
                color='dark'
                iconColor='light'
                title={`${statistics && statistics.plantToday ? statistics.plantToday : 0 }`}
                description='Total de Usinas'
                descriptionColor='primary'
                titleColor='muted'
                style={{width: '100%'}}
                largura={largura}
              />
            </div>
            <div className={styles.divCardUnique}>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/coding/cod001.svg'
                color='dark'
                iconColor='light'
                descriptionColor='primary'
                titleColor='muted'
                title={`${
                  statistics && statistics.capacityTotal ? /*statistics.capacityTotal.toFixed(2)*/ 
                  statistics.capacityTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
                  : 0 }`
                }
                description='Capacidade Total (KWp)'
                style={{width: '100%'}}
                largura={largura}
              />
            </div>
            <div className={styles.divCardUnique}>
            <StatisticsWidget5
              className='card-xl-stretch mb-xl-8'
              svgIcon='/media/icons/duotune/general/gen032.svg'
              color='dark'
              iconColor='light'
              descriptionColor='primary'
              titleColor='muted'
              title={`${statistics && statistics.energyTotal ? 
                // statistics.energyTotal.toFixed(2) 
                statistics.energyTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                : 0 
              }`}
              description='Energia Total (KWh)'
              largura={largura}
            />
            </div>
            <div className={styles.divCardUnique}>
              <StatisticsWidget5
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/finance/fin010.svg'
                color='dark'
                iconColor='light'
                descriptionColor='primary'
                titleColor='muted'
                title={`${statistics && statistics.incomeTotal && statistics.incomeTotal > 0 
                  ? statistics.incomeTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                  : '0,00'}`}
                //title={`${statistics && statistics.incomeTotal && statistics.incomeTotal > 0 ? statistics.incomeTotal.toFixed(2) : 0 }`}
                description='Rendimento Total (R$)'
                largura={largura}
              />
            </div >
            <div className={styles.divCardUnique}>
              <StatisticsWidget5
                className='card-xl-stretch mb-5 mb-xl-8'
                svgIcon='/media/icons/duotune/arrows/arr059.svg'
                color='dark'
                iconColor='light'
                descriptionColor='primary'
                titleColor='muted'
                title={`${statistics && statistics.financial_feedback && statistics.financial_feedback > 0 ? statistics.financial_feedback.toFixed(2) : 0 }`}
                description='Retorno Financeiro (%)'
                largura={largura}
              />
            </div>
          </div>
          
          <div className={`px-6 ${styles.divSubCardStatisticsV2}`}>
            <div className={styles.divCardUnique}>
              <CardsRequestService
                type={largura > 1587 ? 'desk' : 'cell'}  
                className='pb-3 mb-5 mb-xl-10' 
                requestServices={statistics && statistics.request_service ? statistics.request_service : {}} 
                largura={largura}
              />
            </div>
            <div className={styles.divCardUnique}>
              <CardsReports 
                className='pb-3 mb-5 mb-xl-10' 
                reports={statistics && statistics.report ? statistics.report : {}}
                type={largura > 1590 ? 'desk' : 'cell'}    
                largura={largura}
              />
            </div>

            <div className={styles.divCardUnique}>
              <CardsPlanosCompany 
                className='pb-3 mb-5 mb-xl-10' 
                planosCompany={statistics && statistics.planosCompany ? statistics.planosCompany : {}}
                qtdPlantsImported={statistics && statistics.plantToday ? statistics.plantToday : 0}
                type={largura > 1590 ? 'desk' : 'cell'}   
                largura={largura}
              />
            </div>

          </div>
        </div>
      }
    </div>
  );
}