/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Plants } from '../../../@types/plants'
import { getAlarmStatus } from '../../../services/alarm'
import { getMark } from '../../../services/markService'
import { getplantsAllPagination } from '../../../services/plantsService'
import { searchSubstitut } from '../../../utils/search'
import { Loading } from '../../components/Loadings/loading'
import { useToast } from '../../components/Toats'
import useSize from '../../../hooks/useSize'
import { useQuery } from 'react-query'
import { AlarmStatus } from '../../../@types/alarmStatus'

import styles from './tablePlants.module.css';
import { SelectGlobal } from '../../components/SelectGlobal'
import { PaginationTablePlants } from './components/Pagination'
import { TablePlantsSearchComputer } from './components/TableV1'
import { TablePlantsSearchPhone } from './components/TableV2'
import { MenuFilterPlantsPhoneGlobal } from '../../components/MenuFilterPhonePlantsGlobal'
import { MenuFilterPlantsComputerGlobal } from '../../components/MenuFilterPlantsComputerGlobal'
import { SearchInputV2 } from '../../components/SearchInputV2'

type Props = {
  className: string;
}

type Paginations = {
  page: number,
  limit: number,
  total: number,
  qtdPages: number
}

type SetPaginations = {
  start: number;
  end: number;
}

const TablesPlants: React.FC<Props> = ({className}) => {
    const { toggleToast } = useToast();
    const [filterAlarms, setFilterAlarms] = useState<number>(-1);
    const [filterMarks, setFilterMarks] = useState<string>("");
    const [mySearch, setMySearch] = useState<Plants[]>([]);
    const [control, setControl] = useState<boolean>(false);
    const {height, width} = useSize();

    const [pagination, setPagination] = useState<Paginations>({
      page: 1,
      limit: 25,
      total: 0,
      qtdPages: 1
    });

    const [qtd, setQtd] = useState<SetPaginations>({
      start: 0,
      end: 25,
    });

    const { data: plants, isLoading: loadingMyPlants, refetch: refreshPlants } = useQuery<Plants[] | []>(
      ["getPlants", filterAlarms, filterMarks, pagination.page],
      async () => {
        const url = `?status=${filterAlarms !== -1 && filterAlarms !== -2 ? filterAlarms : ""}&mark=${filterMarks}&limit=${pagination.limit}&page=${pagination.page}&alert=${filterAlarms === -2 ? true : false}`
        const myPlants = await getplantsAllPagination(url)
        setPagination({...pagination, qtdPages: myPlants.lastPage, total: myPlants.total})

        const search_plants = mySearch && mySearch.length > 0 ? 
          searchSubstitut(myPlants.data, mySearch)
        : []
        setMySearch(search_plants)

        return myPlants.data
      },
      {
        onError: (error) => {
          console.log(error);
          toggleToast("Erro ao buscar as usinas. ", "error");
        },
      }
    )

    const { 
      data: alarmStatus = {
        attention: 0,
        alarmNum: 0,
        normalNum: 0,
        offlineNum: 0,
        total: 0,
        info: 0,
        alertCount: 0
      }, 
      isLoading: loadingSomeAlarmStatus 
    } = useQuery<AlarmStatus>(
      ["getSomeAlarmStatus"],
      async () => {
        const alarmStatusResult = await getAlarmStatus()
        return alarmStatusResult
      },
      {
        onError: (error) => {
          console.log(error);
          toggleToast("Erro ao buscar os status das usinas. ", "error")
        },
      }
    )

    const { data: marks = [], isLoading: loadingMyMarks } = useQuery<any>(
      ["getMarksAll"],
      async () => {
        const marks = await getMark(true)
        return marks
      },
      {
        onError: (error) => {
          console.log(error)
          toggleToast("Erro ao buscar os portais. ", "error")
        },
      }
    )

    const refresh = () => {
      refreshPlants()
    }

    useEffect(() => {
      const start = (pagination.page - 1) * pagination.limit + 1;
      const end = Math.min(pagination.page * pagination.limit, pagination.total);
  
      setQtd({start: start, end: end === 0 ? pagination.limit : end})
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);


    if (loadingSomeAlarmStatus || loadingMyMarks) {
      return (
        <div className={styles.divSpinnerTablePlants}>
          <Loading myClass={`spinner-border ${styles.spinnerTablePlants}`}/>
        </div>
      );
    }

    return (
      <div className={`card ${className} ${width < 1200 ? 'pb-2' : 'pb-2'}`}>
        <div className={`card-header border-0 pt-5 ${width < 1200 ? "pb-0" : "pb-1"} flex-column`}>
          <div className={styles.divH3Search}>
            <h3 className={`card-title align-items-start ${styles.h3Title}`}>
                <span className={`card-label fw-bold fs-${width < 1200 ? '6' : '3'} mb-1`}>
                    Usinas {width < 1200 ? 
                    `(${filterAlarms === -1 ?
                      alarmStatus.total
                    : filterAlarms === 0 ?
                      alarmStatus.normalNum
                    : filterAlarms === 2 ?
                      alarmStatus.attention
                    : filterAlarms === 1 ?
                      alarmStatus.offlineNum
                    : filterAlarms === 3 ?
                      alarmStatus.info
                    : filterAlarms === -2 ?
                      alarmStatus.alertCount
                    : 0
                    })` : ''}
                </span>
                {width > 1200 && 
                  <span className={`text-muted mt-1 fw-semibold fs-${width < 1200 ? '8' : '7'}`}>
                    <span className='text-dark'>{width > 1200 ?  'Total : ' : ''}</span>  
                    {filterAlarms === -1 ?
                      alarmStatus.total
                    : filterAlarms === 0 ?
                      alarmStatus.normalNum
                    : filterAlarms === 2 ?
                      alarmStatus.attention
                    : filterAlarms === 1 ?
                      alarmStatus.offlineNum
                    : filterAlarms === 3 ?
                      alarmStatus.info
                    : filterAlarms === -2 ?
                      alarmStatus.alertCount
                    : 0
                    } usinas
                  </span>
                }
              </h3>
            {
              width < 1200 && (
                <SearchInputV2
                  className='form-control form-control-sm bg-light'
                  placeholder={"Buscar por nome"}
                  myDate={plants}
                  setMySearch={setMySearch}
                  control={control}
                  style={{width: width < 1200 ? "100%" : "300px", flex: '1', height: '2.5rem'}}
                  setControl={setControl}
                  searchParameter={"name"}
                />
              )
            }
          </div>
          
          <div className={`border-0 flex-row ${styles.divGlobalTitles}`}>
            {width > 1200 && (
              <div className={`border-0 ${styles.divFiltersPlants}`}>
                <SearchInputV2
                  className='form-control form-control-sm bg-light'
                  placeholder={"Buscar por nome"}
                  myDate={plants}
                  setMySearch={setMySearch}
                  control={control}
                  style={{ 
                    width: width < 1200 ? "100%" : width < 1700 ? "200px" : "300px", 
                    height: '3.5rem'
                  }}
                  setControl={setControl}
                  searchParameter={"name"}
                />

                <MenuFilterPlantsComputerGlobal 
                  setFilterAlarms={setFilterAlarms}
                  alarmStatus={alarmStatus}
                />
            </div>
            )}

            <div className='card-toolbar'>
              <div className={`fv-row ${styles.divSelectPortal}`}>
                {width < 1200 ?
                  <div>
                    <select
                      className={`form-select form-select-solid form-select-sm fs-${width < 1200 ? '7' : '5'} ${styles.selectPortal}`}
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={''}
                      onChange={(e) => {
                        setFilterMarks(e.target.value)
                      }}
                    >
                      <option value={''} className="optionButtonFilterMark">Buscar pelo Portal</option>
                      {marks && marks.map((myMark: any) => {
                        return (
                          <option key={myMark.id} value={myMark.id}>{myMark.name}</option>
                        )
                      })}
                    </select>
                  </div> 
                :
                  <SelectGlobal 
                    itens={marks}
                    buttonAll={true}
                    textSearch={"Buscar portal"}
                    textButtonInit={"Filtrar por portal"}
                    labelKey='name'
                    valueKey='id'
                    typeIcon='img'
                    value={filterMarks ? filterMarks : ""}
                    className={styles.selectPortal}
                    onChange={(value: string) => {
                      setFilterMarks ? setFilterMarks(value) : console.log('Sem função de filtro')
                    }}
                  />
                }
              </div>
            </div>

            {width < 1200 &&
              <MenuFilterPlantsPhoneGlobal 
                setFilterAlarms={setFilterAlarms}
                alarmStatus={alarmStatus}
              />
            }
          </div> 
        </div>

        <div className='card-body py-3 pb-2'
          style={{
            overflow: 'auto', 
            height: width < 1200 ?
              height <= 750 ? '24vh' 
            : height > 750 && height <= 850 ? '27vh'
            : height > 850 && height <= 930 ? '30vh'
            : `42vh`
            : '',
          }}
        >
          <div className={`table-responsive ${styles.myTableResponsive}`}>
            {loadingMyPlants ? 
              <div className={styles.divSpinnerTablePlants}>
                <Loading myClass={`spinner-border ${styles.spinnerTablePlants}`}/>
              </div>
            :
              <div className={styles.divTable}>
                {width < 1200 ?
                  <TablePlantsSearchPhone
                    plants={control === false ? plants : mySearch}
                    refresh={refresh}
                  />
                :
                  <TablePlantsSearchComputer
                    plants={control === false ? plants : mySearch}
                    refresh={refresh}
                  />
                }
              </div> 
            }
          </div>
        </div>

        <div className='card-footer pt-1'>
          <PaginationTablePlants 
            pagination={pagination}
            setPagination={setPagination}
            qtd={qtd}
          />
        </div>
      </div>
    )
}

export {TablesPlants}
