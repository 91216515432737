import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import { useToast } from './pages/components/Toats'
import useSocket from './hooks/useSocket'
import { removeLoadingCredential } from './utils/LoadingCredentials'

const App = () => {
  const {currentUser} = useAuth()
  const { toggleToast } = useToast();
  const { socket, on, off } = useSocket();

  const handleEventsSocket = (data: any) => {
    if (data.success) {
      toggleToast(data.message, 'success');
    } else {
      toggleToast(data.message, 'error');
    }
    removeLoadingCredential(data.idCredential);
  }

  useEffect(() => {
    if(currentUser && currentUser.companies && currentUser.companies.length > 0){
      if(currentUser.companies[0].color_primary){
        document.documentElement.style.setProperty('--bs-primary', currentUser.companies[0].color_primary);
        document.documentElement.style.setProperty('--bs-text-primary', currentUser.companies[0].color_primary);
        document.documentElement.style.setProperty('--bs-secondary', currentUser.companies[0].color_primary_variet);
      }

      if(currentUser.companies[0].color_primary_variet){
        document.documentElement.style.setProperty('--bs-primary-active', currentUser.companies[0].color_primary_variet);
      }
    }

    if(currentUser && currentUser.id && socket){
      on("message", handleEventsSocket);
    }

    if(currentUser && currentUser.id && socket){
      return () => {
        off("message", handleEventsSocket);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, socket])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
