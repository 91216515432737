/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { deleteServiceOne, getServiceOne } from '../../../../../../services/servicesService';
import { ItemOrderOfService } from '../TableOrderOfService/components/ItemOrderOfService';
import { ItemOSDetails } from './components/intemOSDetails';
import { DescriptionOS } from './components/descriptionOS';
import { ServicesQtdOS } from './components/servicesQtdOS';
import { Loading } from '../../../../../components/Loadings/loading';

type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDetailsOS = ({show, handleClose, id, data}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [service, setService] = useState<any>(null);

  const getService = async () => {
    try {
      if(id){
        setLoading(true)
        const myService = await getServiceOne(id ? id : "");
        
        if (myService.dateInit) {
          let date = new Date(myService.dateInit);
          date.setHours(date.getHours() + 3);
          myService.dateInit = date.toISOString();
        }
        
        if (myService.dateEnd) {
          let date = new Date(myService.dateEnd);
          date.setHours(date.getHours() + 3);
          myService.dateEnd = date.toISOString();
        }
        setService(myService)
      }
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar a O.S. ", "error")
    }finally{
        setLoading(false)
    }
  }
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  // const deleteOSSubmit = async () => {
  //   try {
  //     setLoading(true)
    
  //     await deleteServiceOne(id)
  //     toggleToast("Sucesso ao deletar a O.S. ", "success")

  //   } catch (error) {
  //     console.log('erro',error)
  //     toggleToast("Erro ao deletar a O.S. ", "error")
  //   }finally{
  //     setLoading(false)
  //     closed()
  //     // handleClose()
  //   //   setTimeout(()=>{
  //   //     window.location.reload()
  //   //   }, 2000);
  //   }
  // }

  const refresh = () => {
    getService()
  }

  const submit = () => {
    //deleteOSSubmit()
  }

  useEffect(() => {
    getService()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (loading) {
    return (
      <div className='spinnerLoadingDashboard'>
        <Loading myClass='spinner-border spinnerDashboard'/>
      </div>
    );
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
      style={{minWidth: '1000px'}}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Ordem de Serviço Nº {service && service.number_order_service ? service.number_order_service : "0"}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10 bg-light'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                {/* <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Deseja deletar essa O.S permanentemente?</span>
                </h3> */}
                
                <ItemOSDetails 
                  itemOrderOfService={service}
                  refresh={refresh}
                />

                <DescriptionOS
                  itemOrderOfService={service} 
                  refresh={refresh}
                />
                <ServicesQtdOS 
                  itemOrderOfService={service} 
                  refresh={refresh}
                />

            </div>
            {/*begin::Form */}
            
            {/* <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-secondary'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                    
                </button>

                <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={submit}
                >
                    Salvar
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
            </div> */}

            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDetailsOS}
