import React, { useEffect, useRef, useState } from 'react';

import JSZip from "jszip";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FormGenerateTemplate } from './components/FormGenerateTemplate';
import { FormAtualizationTemplate } from './components/FormAtualizationsTemplate';
import { useAuth } from '../../../../../../modules/auth';
import { useToast } from '../../../../../components/Toats';
import { getInvoicePDFFile } from '../../../../../../services/invoices';
import { generateHexSequence } from '../../../../../../utils/generateNumberHex';
import { getfileUrl, uploadDocumentInvoice } from '../../../../../../services/uploadService';
import { postConvertPdfV3 } from '../../../../../../services/ConvertPDFService';
import { DateCompleteBarra } from '../../../../../../utils/date';
import { getUnitBranchAll } from '../../../../../../services/unitBranchService';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { postTemplateOne, putTemplateAll } from '../../../../../../services/templates';
import { DropdownMenuDatesInformations } from './components/DropdownInformation';
import html2canvas from 'html2canvas';
import ReactDOM from "react-dom/client";
//import { useCaptureGraphic } from '../../../../../../hooks/useCaptureGraphic.ts';

type Props = {
    formik: any;
    setLinkPDF: any;
    initialHtml: any;
    setMyTemplate: any;
    mySelectTemplate: any
    myTemplates: any;
    refresh: any
    setLoadingDocs: any;

    graphicMouth: any;
    graphicYearComsuption: any,
    graphicYearGeneration: any,
    graphicYear: any,

}


const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  idUnitBranch: Yup.string()
});

export function DocumentLayout({
  formik, 
  setLinkPDF, 
  initialHtml, 
  setMyTemplate, 
  mySelectTemplate, 
  myTemplates, 
  refresh, setLoadingDocs,

  graphicMouth,
  graphicYearComsuption,
  graphicYearGeneration,
  graphicYear,

}: Props) {
    const [loadingUploadWord, setLoadingUploadWord] = useState(false)
    const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo (.docx).");
    const fileInputRef : any = useRef(null);
    const [generateTemplate, setGenerateTemplate] = useState(false);
    const [atualizationTemplate, setAtualizationTemplate] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
    const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
    const user = useAuth();
    const { toggleToast } = useToast();
    // const { mutate: generateChartImage, data, error } = useCaptureGraphic();

    const [linkImgGraphics, setLinkImgGraphics] = useState({
      graphic_days: "",
      graphic_mensal_combination: "",
      chart_comsuption: "",
      graphic_months: "",
    });

    const [initialValues] = useState<any>({
      textHTML: initialHtml,
      description: null,
      type: "private",
      typeDoc: "docx",
      titleDocx: null,
      linkDocx: null,
      type_template: null,
      idUnitBranch : null
    })

    const formikTemplate = useFormik({
      initialValues,
      validationSchema,
      validateOnChange: false,
      onSubmit: () => {},
    });

    // const handleCapture = (meuDado: any, type_graphic: string) => {
    //   generateChartImage(
    //     { chartData: meuDado, type_graphic: type_graphic },
    //     {
    //       onSuccess: (url) => {
    //         if (url) {
    //           setLinkImgGraphics((prevState) => ({
    //             ...prevState,
    //             graphic_days: url,
    //           }));
    //         }
    //       },
    //     }
    //   );
    // };

    const captureGraphicAsImage = async (chartData: any, type_grapich: string, chartDataCombination?: any) => {
      try {
        const tempContainer = document.createElement("div");
        tempContainer.style.position = "absolute";
        tempContainer.style.left = "-9999px";
        tempContainer.style.top = "0";
        tempContainer.style.width = type_grapich === "graphic_days" ? "1000px" 
        : type_grapich === "graphic_months" ? "1000px" 
        : type_grapich === "chart_comsuption" ? "1000px" 
        : type_grapich === "graphic_mensal_combination" ? "1000px" 
        : "";

        tempContainer.style.height = type_grapich === "graphic_days" ? "170px" 
        : type_grapich === "graphic_months" ? "auto" 
        : type_grapich === "chart_comsuption" ? "15rem" 
        : type_grapich === "graphic_mensal_combination" ? "35rem" 
        : "";;
        tempContainer.style.border = "1px dashed #e1e3ea";
        tempContainer.style.borderRadius = "5px";
        tempContainer.style.backgroundColor = "white";
    
        document.body.appendChild(tempContainer);
    
        if(type_grapich === "graphic_days"){
          const root = ReactDOM.createRoot(tempContainer);
          root.render(graphicMouth(chartData));
        }else{
          if(type_grapich === "graphic_months"){
            const root = ReactDOM.createRoot(tempContainer);
            root.render(graphicYear(chartData, chartDataCombination));

          }else{
            if(type_grapich === "chart_comsuption"){
              const root = ReactDOM.createRoot(tempContainer);
              root.render(graphicYearComsuption(chartData));

            }else{
              if(type_grapich === "graphic_mensal_combination"){
                const root = ReactDOM.createRoot(tempContainer);
                root.render(graphicYearGeneration(chartData, chartDataCombination));

              }
            }
          }
        }
    
        await new Promise((resolve) => setTimeout(resolve, 500));
    
        const canvas = await html2canvas(tempContainer);
    
        canvas.toBlob(async (blob) => {
          if (blob) {
            const file = new File([blob], `chart.png`, { type: "image/png" });
    
            const formData = new FormData();
            formData.append("file", file);
    
            const response = await getfileUrl(formData);

            if(response && response.url){
              if(type_grapich === "graphic_days"){
                setLinkImgGraphics((prevState) => ({
                  ...prevState,
                  graphic_days: response.url,
                }));
              }else{
                if(type_grapich === "graphic_months"){
                  setLinkImgGraphics((prevState) => ({
                    ...prevState,
                    graphic_months: response.url,
                  }));
      
                }else{
                  if(type_grapich === "chart_comsuption"){
                    setLinkImgGraphics((prevState) => ({
                      ...prevState,
                      chart_comsuption: response.url,
                    }));
      
                  }else{
                    if(type_grapich === "graphic_mensal_combination"){
                      setLinkImgGraphics((prevState) => ({
                        ...prevState,
                        graphic_mensal_combination: response.url,
                      }));
      
                    }
                  }
                }
              }
            }
          }
        });
    
        document.body.removeChild(tempContainer);
      } catch (error) {
        console.error("Erro ao capturar o gráfico:", error);
        return null;
      }
    };
  
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
    
    const processDocx = async (fileOrUrl: any, placeholders: any) => {
      try {
        setLoadingDocs(true)

        let file;
    
        if (typeof fileOrUrl === "string") {
          const response: any = await getInvoicePDFFile(fileOrUrl);
          if (response) {
            const blob = response.data;
            file = new File([blob], mySelectTemplate && mySelectTemplate.titleDocx ? mySelectTemplate.titleDocx : "original-document.docx", { type: blob.type });
          }

        } else {
          file = fileOrUrl;

          const formData = new FormData();
          const key = `template-${generateHexSequence(10)}.docx`; 
          formData.append("file", file);
          formData.append('key', key);
          
          const upload_file = await uploadDocumentInvoice(formData)
      
          if(upload_file && upload_file.success === true){
            formikTemplate.setValues({
              ...formikTemplate.values, linkDocx: key});
          }
        }

        if(file){
          const fileName = file.name;
          setTextArquive(fileName);
        }
    
        const arrayBuffer = await file.arrayBuffer();
        const zip: any = await JSZip.loadAsync(arrayBuffer);
    
        const documentXml = await zip.file("word/document.xml").async("string");

        let cleanedXml = documentXml.replace(/<w:t[^>]*>({{)?<\/w:t>\s*<w:r[^>]*><w:t[^>]*>(.*?)<\/w:t>\s*<w:r[^>]*><w:t[^>]*>(}})?<\/w:t>/g, "{{$2}}");
        cleanedXml = cleanedXml.replace(/<w:proofErr[^>]*>/g, "");

        for (const [placeholder, value] of Object.entries(placeholders)) {
          const regex = new RegExp(`\\{\\{\\s*${placeholder}\\s*\\}\\}`, "g");
          cleanedXml = cleanedXml.replace(regex, value);
        }
    
        zip.file("word/document.xml", cleanedXml);
        const updatedDocx = await zip.generateAsync({ type: "blob" });
    
        const pdfFormData = new FormData();
        pdfFormData.append("file", updatedDocx, "processed-document.docx");
    
        const response = await postConvertPdfV3(pdfFormData);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
    
        setLinkPDF(url);
        
        console.log("Documento processado e convertido para PDF com sucesso!");
      } catch (error) {
        console.error("Erro ao processar o documento:", error);
      }
      setLoadingDocs(false)
    };

    const substituteValues = async (file: any) => {
      setLoadingUploadWord(true);
      if (file) {
        try {
          
          let placeholderData = {
            year: () => {
              const data = new Date(formik.values.data);
              const year = data.getFullYear();

              return year.toString();
            },
            month: () => {
              const mounthYear = [
                'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
              ];
      
              const data = new Date(formik.values.data);
              const month = data.getMonth() + 1;
              const nameMonth = mounthYear[month - 1];
      
              return nameMonth
            },
            generation: formik && formik.values && formik.values.generation ? formik.values.generation : "",
            performance: formik && formik.values && formik.values.performance ? formik.values.performance : "",
            consumption: formik && formik.values && formik.values.consumption ? formik.values.consumption : "" ,
            saved: formik && formik.values && formik.values.saved ? formik.values.saved : "" ,
            initPeriod: formik && formik.values && DateCompleteBarra(formik.values.initPeriod) ? formik.values.initPeriod : "" ,
            endPeriod: formik && formik.values && DateCompleteBarra(formik.values.endPeriod) ? formik.values.endPeriod : "" , 
            corporateName: formik && formik.values && formik.values.corporateName ? formik.values.corporateName : "" ,
      
            performance_anual: formik && formik.values && formik.values.performance_anual ? formik.values.performance_anual : "" ,
            generation_anual: formik && formik.values && formik.values.generation_anual ? formik.values.generation_anual : "" ,
            prog_12_meses: formik && formik.values && formik.values.prog_12_meses ? formik.values.prog_12_meses : "" ,
            capacity: formik && formik.values && formik.values.capacity ? formik.values.capacity : "",
      
            economia_co2: formik && formik.values && formik.values.economia_co2 ? formik.values.economia_co2 : "",
            arvores_salvas: formik && formik.values && formik.values.arvores_salvas ? formik.values.arvores_salvas : "",
      
            //invoiced: formik && formik.values && formik.values.invoiced ? formik.values.invoiced : "",

            invoiced: formik && formik.values.invoiced && Number(formik.values.invoiced) > 0 
                ? formik.values.invoiced.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
                : '0,00',

            //total_economy: formik && formik.values && formik.values.total_economy ? formik.values.total_economy : "",
            
            total_economy: formik && formik.values.total_economy && Number(formik.values.total_economy) > 0 
            ? formik.values.total_economy.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim() 
            : '0,00',

            financial_feedback_month: formik && formik.values && formik.values.financial_feedback_month ? formik.values.financial_feedback_month : "",
            financial_feedback_year: formik && formik.values && formik.values.financial_feedback_year ? formik.values.financial_feedback_year : "",
      
            consumerRegister: formik && formik.values && formik.values.consumerRegister ? formik.values.consumerRegister : "",
            consumerInstant: formik && formik.values && formik.values.consumerInstant ? formik.values.consumerInstant : "",
            unitConsumerTotal: formik && formik.values && formik.values.unitConsumerTotal ? formik.values.unitConsumerTotal : "",
            energyInjected: formik && formik.values && formik.values.energyInjected ? formik.values.energyInjected : "",
      
            cnpj: formik && formik.values && formik.values.cnpj ? formik.values.cnpj : "",
            companyName: formik && formik.values && formik.values.companyName ? formik.values.companyName : "" ,
            emailCompany: formik && formik.values && formik.values.emailCompany ? formik.values.emailCompany : "",
            namePlant: formik && formik.values && formik.values.namePlant ? formik.values.namePlant : "",
            adressPlant: formik && formik.values && formik.values.adressPlant ? formik.values.adressPlant : "",
            mark: formik && formik.values && formik.values.mark ? formik.values.mark : "" ,
      
            chart_comsuption: formik && formik.values && formik.values.chart_comsuption ? formik.values.chart_comsuption : "",
            graphic_mensal_combination: formik && formik.values && formik.values.graphic_mensal_combination ? formik.values.graphic_mensal_combination : "",
            graphic_days: formik && formik.values && formik.values.chart_mouth ? formik.values.chart_mouth : [],
            graphic_months: formik && formik.values && formik.values.graphic_months ? formik.values.graphic_months : "",
          }

          await processDocx(file, placeholderData);
          if(mySelectTemplate && mySelectTemplate.type_template === "DETALHADO"){

            await captureGraphicAsImage(
              formik && formik.values && formik.values.chart_mouth ? formik.values.chart_mouth : {}, 
              "graphic_days"
            );

            await captureGraphicAsImage(
              formik && formik.values && formik.values.chart_comsuption ? formik.values.chart_comsuption : {}, 
              "chart_comsuption"
            );

            await captureGraphicAsImage(
              formik && formik.values && formik.values.chart_year ? formik.values.chart_year : {}, 
              "graphic_months",
              formik && formik.values && formik.values.chart_comsuption ? formik.values.chart_comsuption : {},
            );
 
          }else{
            if(mySelectTemplate && mySelectTemplate.type_template === "GERACAO"){
              await captureGraphicAsImage(
                formik && formik.values && formik.values.chart_mouth ? formik.values.chart_mouth : {}, 
                "graphic_days"
              );
  
              await captureGraphicAsImage(
                formik && formik.values && formik.values.chart_year_generation ? formik.values.chart_year_generation : {}, 
                "graphic_mensal_combination",
                formik && formik.values && formik.values.chart_prognosis ? formik.values.chart_prognosis : {},
              );
            }
          }
          await captureGraphicAsImage(formik && formik.values && formik.values.chart_mouth ? formik.values.chart_mouth : [], "graphic_days");

        } catch (error) {
          console.error("Erro ao processar o documento:", error);
        }finally{
          setLoadingUploadWord(false);
        }
      }
    }


    const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        await substituteValues(file)
    };

    const postTemplate = async () => {
      try {
        setLoading(true)
        const myData = {
          textHTML: "<div >Crie o seu template ou escolha um template disponivel. </div>",
          description: formikTemplate.values.description,
          type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
          typeDoc: "docx",
          titleDocx: formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : null,
          linkDocx: formikTemplate.values.linkDocx ? formikTemplate.values.linkDocx : null,
          type_template: formikTemplate.values.type_template ? formikTemplate.values.type_template : null,
          idUnitBranch : formikTemplate.values.idUnitBranch
        }

        const myTemplate = await postTemplateOne(myData)
  
        if(myTemplate){
          let formik_templates = formik.values.idsTemplates;
          const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
  
          const index = result.findIndex(template => template.type_template === myTemplate.type_template);
          if(index === -1){
            formik_templates.push(myTemplate.id)
          }else{
            formik_templates[index] = myTemplate.id
          }
          formik.setValues({...formik.values, idsTemplates: formik_templates})
  
          //formikReport.setValues({...formik.values, idTemplate: template.id})
  
          setMyTemplate(myTemplate)
        }
  
        toggleToast("Sucesso ao cadastrar o template. ", "success")
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao cadastrar template. ", "error")
      }finally{
        setLoading(false)
        refresh()
        setGenerateTemplate(false)
      }
    }

    const putTemplate = async (id: string) => {
      try {
        setLoading(true)
        const myData = {
          description: formikTemplate.values.description,
          titleDocx: formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : null,
          linkDocx: formikTemplate.values.linkDocx ? formikTemplate.values.linkDocx : null,
          type_template: formikTemplate.values.type_template ? formikTemplate.values.type_template: null,
          idUnitBranch : formikTemplate.values.idUnitBranch
        }
   
        await putTemplateAll(id, myData)
        toggleToast("Sucesso ao atualizar o template. ", "success")
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao atualizar o template. ", "error")
      }finally{
        setLoading(false)
        setAtualizationTemplate(false)
        refresh()
      }
    }

    const getAllUnitBranchs = async () => {
      try {
        setLoadingUnitBranchs(true)
        const unitBranchs = await getUnitBranchAll()
        setUnitBranchs(unitBranchs)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar a lista de unidades. ", "error")
  
      }finally{
        setLoadingUnitBranchs(false)
      }
    }

    const submit = (type: number) => {
      if(type === 1){
        if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
          putTemplate(mySelectTemplate.id)
        }
      }else if (type === 0){
        postTemplate()
      }
    }

    useEffect(() => {
      if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
        const data = {
          textHTML: "<h1>Não encontrado.. </h1>",
          description: mySelectTemplate.description ? mySelectTemplate.description : null,
          type: mySelectTemplate.type ? mySelectTemplate.type : null,
          type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
          idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null,
          typeDoc: "docx",
          titleDocx: mySelectTemplate.titleDocx ? mySelectTemplate.titleDocx.id : null,
          linkDocx: mySelectTemplate.linkDocx ? mySelectTemplate.linkDocx.id : null,
        }
        formikTemplate.setValues(data)

        if(mySelectTemplate.linkDocx){
          substituteValues(mySelectTemplate.linkDocx)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySelectTemplate]);

    useEffect(() => {
      console.log(formikTemplate.values)
    }, [formikTemplate.values])

    useEffect(() => {
      getAllUnitBranchs()
    }, []);

  return (
    <div className='bg-light' style={{ display: 'flex', gap: '1rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
        <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
            <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                <span className='card-label fw-bold fs-3 mb-1'>Template em documento</span>
                <span className='text-muted fw-semibold fs-7'>Suba um documento .docx como template</span>
            </h3>

            <div className='me-0 dropdown'>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                type="button" 
                id="dropdownMenuButton1" 
                data-bs-toggle="dropdown" 
                aria-expanded="false"
              >
                <i className='bi bi-three-dots fs-3'></i>
              </button>
              <DropdownMenuDatesInformations 
                myTemplateSelected={mySelectTemplate}
                linkImgGraphics={linkImgGraphics}
              />
            </div>
        </div>

        <div className='border border-gray-300 border-dashed rounded'>
          <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '41rem', flexDirection: 'row'}}>
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              disabled={loadingUploadWord}
              onChange={handleWordUpload}
              style={{ display: 'none' }}
            />
            {/* <Button 
              onClick={handleButtonClick}
            >
              Escolher Arquivo
            </Button> */}

            <button
              onClick={() =>{handleButtonClick()}}
              disabled={loadingUploadWord}
              style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}
              className='btn btn-sm btn-light-primary text-hover-white'
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2x' />
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                  <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                </h3>
              </div>

              {loadingUploadWord && (
                <div style={{marginRight: '1rem'}}>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
            )}
            </button>

          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <button
            type='button'
            className='btn btn-outline-success'
            onClick={() => {
              setAtualizationTemplate(false)
              setGenerateTemplate(true)
            }}
          >
            Gerar Template
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
          </button>

          <button
              type='button'
              className='btn btn-outline-primary'
              disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
              //data-kt-stepper-action='submit'
              onClick={() => {
                  setGenerateTemplate(false)
                  setAtualizationTemplate(true)
                  //submit(1)
              }}
              >
              Atualizar Template
              {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
              }
          </button>
      </div>

      {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <FormGenerateTemplate 
          loading={loading}
          formikTemplate={formikTemplate}
          unitBranchs={unitBranchs}
          setGenerateTemplate={setGenerateTemplate}
          submit={submit}
        />

      : 
      atualizationTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <FormAtualizationTemplate 
          loading={loading}
          formikTemplate={formikTemplate}
          unitBranchs={unitBranchs}
          setGenerateTemplate={setGenerateTemplate}
          submit={submit}
        />
        : <></>
    }
    </div>
  );
}