/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getUnitBranchAll } from "../../../../../../../../../../../../services/unitBranchService";
import { Loading } from "../../../../../../../../../../../components/Loadings/loading";
import { useToast } from "../../../../../../../../../../../components/Toats";
import { ListUnitBranchLinked } from "../listUnitBranchLinked";


const StepModal = ({data, hasError, formik}: any) => {
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { toggleToast } = useToast();

  const getUsersAllUnitBranchs = async () => {
    try {
      setLoading(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsersAllUnitBranchs();
  }, []);

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>
        <div>
            <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Tipo de Regra:</span>
                    <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Selecione para qual unidade filial essa credencial será vinculada. '
                    ></i>
                </label>
                <div>
                <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.rule_type ? formik.values.rule_type : '1'}
                    onChange={formik.handleChange("rule_type")}
                >
                    <option value={undefined}></option>
                    <option value={'DESEMPENHO_15D'}>15D</option>
                    <option value={'DESEMPENHO_30D'}>30D</option>
                    <option value={'DESEMPENHO_12M'}>12M</option>
                </select>
                {!data.rule_type && hasError && (
                <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Selecione um tipo de regra!
                    </div>
                </div>
                )}
                </div>
            </div>
        </div>

        {formik.values.rule_type && formik.values.rule_type === "DESEMPENHO_15D" && (
            <div>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Avise quando o desempenho 15D for:</span>
                </label>
                
                <div style={{display: 'flex', gap: '1rem'}}>
                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Menor que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='min_value'
                            placeholder=''
                            value={formik.values.min_value}
                            onChange={formik.handleChange("min_value")}
                        />
                        {/* {!formik.values.min_value && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um valor mínimo
                            </div>
                        </div>
                        )} */}
                    </div>

                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Maior que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='max_value'
                            placeholder=''
                            value={formik.values.max_value}
                            onChange={formik.handleChange("max_value")}
                        />
                        {/* {!formik.values.max_value && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um valor máximo
                            </div>
                        </div>
                        )} */}
                    </div>
                </div>
            </div>
        )}

        {formik.values.rule_type && formik.values.rule_type === "DESEMPENHO_30D" && (
            <div>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Avise quando o desempenho 30D for:</span>
                </label>
                
                <div style={{display: 'flex', gap: '1rem'}}>
                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Menor que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='min_value'
                            placeholder=''
                            value={formik.values.min_value}
                            onChange={formik.handleChange("min_value")}
                        />
                        {/* {!formik.values.companyName && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um nome para o cliente
                            </div>
                        </div>
                        )} */}
                    </div>

                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Maior que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='max_value'
                            placeholder=''
                            value={formik.values.max_value}
                            onChange={formik.handleChange("max_value")}
                        />
                        {/* {!formik.values.companyName && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um nome para o cliente
                            </div>
                        </div>
                        )} */}
                    </div>
                </div>
            </div>
        )}

        {formik.values.rule_type && formik.values.rule_type === "DESEMPENHO_12M" && (
            <div>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Avise quando o desempenho 12M for:</span>
                </label>
                
                <div style={{display: 'flex', gap: '1rem'}}>
                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Menor que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='min_value'
                            placeholder=''
                            value={formik.values.min_value}
                            onChange={formik.handleChange("min_value")}
                        />
                        {/* {!formik.values.companyName && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um nome para o cliente
                            </div>
                        </div>
                        )} */}
                    </div>

                    <div className='fv-row mb-10' style={{display: 'flex', gap: '0.5rem'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2' style={{flex: '1'}}>
                            <span className='required' style={{whiteSpace: 'nowrap'}}>Maior que {"(%)"}:</span>
                        </label>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            name='max_value'
                            placeholder=''
                            value={formik.values.max_value}
                            onChange={formik.handleChange("max_value")}
                        />
                        {/* {!formik.values.companyName && hasError && (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe um nome para o cliente
                            </div>
                        </div>
                        )} */}
                    </div>
                </div>
            </div>
        )}

        {formik.values.rule_type && (
            <div>
                <ListUnitBranchLinked 
                    className=""
                    unitBranchs={unitBranchs}
                    formik={formik}
                />
            </div>
        )}

        {formik.values.rule_type && (
            <div className='fv-row' style={{display: 'flex', gap: '1rem'}}>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                    <span>Ativar Regra: </span>
                    <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Utilizar valores passados manualmente/padrão da unidade consumidora'
                    ></i>
                </label>
                <div className='form-check form-check-solid form-switch fv-row'>
                <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    id='allowmarketing'
                    defaultChecked={formik.values.is_general}
                    onChange={(event) => {
                    if(event.target.checked === true){
                        formik.setValues({...formik.values, is_general: true})
                    }else{
                        formik.setValues({...formik.values, is_general: false})
                    }
                    }}
                />
                <label className='form-check-label'></label>
                </div>
            </div>
        )}
      </div>
    </div>
  )
}

export {StepModal}

