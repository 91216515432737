import React, { useState } from 'react';
import { useAuth } from '../../../modules/auth';
import useSize from '../../../hooks/useSize';
import { GraphicsDahsboardPlant } from '../ GraphicsModalPlant';
import { TablesPlants } from '../TablePlants';
import { Maps } from '../Maps';
import { Plants } from '../../../@types/plants';
import { Report } from '../../../@types/report';

import styles from './paginationsDashboard.module.css';

type Props = {
  loadingPlants: boolean;
  setLoadingPlants: React.Dispatch<React.SetStateAction<boolean>>;
  myPlant: Plants | undefined;
  loadingCharts: boolean;
  dataFilter: Plants[] | [];
  idPlantSelected: string | null;
  handleSeletedPlant: (plant: string) => void;
  loadingReport: boolean;
  refresh: () => Promise<void>;
  myReport: Report[] | []
}

export function PaginationDashboard({
  myPlant,
  loadingCharts,
  dataFilter,
  idPlantSelected,
  handleSeletedPlant,
  loadingReport,
  refresh,
  myReport
} : Props) {

  const [pages, setPages] = useState<number>(1);
  const user = useAuth();
  const {width} = useSize();
  
  return (
    <div>
      {
        <ul className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-${width < 1200 ? '7' : '5'} fw-bolder flex-nowrap mb-${width < 1200 ? '2' : '5'} ${styles.ulGlobal}`}>
          <li className="nav-item">
            <a
              className="nav-link active text-active-primary me-6 border-active-primary border-hover-primary"
              data-bs-toggle="tab"
              href="#kt_tab_pane_1"
              onClick={() => setPages(1)}
            >
              {user.currentUser?.role.slug === "CLIENTE" ? "Gráficos" : "Tabela"}
            </a>
          </li>
          {user.currentUser?.role.slug === "CLIENTE" &&
            <li className="nav-item">
              <a
                className="nav-link text-active-primary me-6"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
                onClick={() => setPages(2)}
              >
                Tabela
              </a>
            </li>
          }
          <li className="nav-item">
            <a
              className="nav-link text-active-primary me-6 border-active-primary border-hover-primary"
              data-bs-toggle="tab"
              href="#kt_tab_pane_3"
              onClick={() => setPages(3)}
            >
              Mapa
            </a>
          </li>
        </ul>
      }
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane active fade show"
          id="kt_tab_pane_1"
          role="tabpanel"
        >
          {user.currentUser?.role.slug === "CLIENTE" ? 
            <div className={styles.divClassGraphicsClient}>
              <GraphicsDahsboardPlant
                typeGraphc='grapich'
                myProg={myPlant && myPlant.prognosis ? myPlant.prognosis : null}
                charts={myPlant && myPlant.charts ? myPlant.charts : null} 
                loading={loadingCharts}
                plantsFilter={dataFilter}
                dataFilter={idPlantSelected ? idPlantSelected : null}
                setDataFilter={handleSeletedPlant}
                data={myPlant} 
                loadingReport={loadingReport}
                refresh={refresh}
                report={myReport && myReport.length === 1 ? myReport[0] : null}
                prognostico={myPlant && myPlant.annual_generation_forecast ? myPlant.annual_generation_forecast : null}
              />
            </div>
          :
            <div className={styles.divClassTablePlants}>
              <TablesPlants 
                className='mb-5 mb-xl-8' 
              />
            </div>            
          }
        </div>
        {user.currentUser?.role.slug === "CLIENTE"  &&
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            <div className={styles.divClassTablePlants}>
              <TablesPlants 
                className='mb-5 mb-xl-8' 
              />
            </div>
          </div>
        }

        <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
          <div className={styles.divMaps}>
            <Maps page={pages}/>
          </div>
        </div>

      </div>
    </div>
  );
}