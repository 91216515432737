/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
//import { Dropdown1 } from '../../../../../../_metronic/partials/content/dropdown/Dropdown1'
import { Loading } from '../../../../components/Loadings/loading';
//import {KTSVG, toAbsoluteUrl} from '../../../helpers'
//import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string;
  plants: any[];
  loading: boolean;
}

const TablePlantImported: React.FC<Props> = ({className, plants, loading}) => {

  const [largura, setLargura] = useState(window.innerWidth);

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);
  
  return (
    <div className={`card ${className}`} style={{paddingRight: '1rem', paddingBottom: '2rem', paddingTop: largura < 1200 ? '1rem' : ''}}>
      {/* begin::Header */}
      <div className='card-header border-0 mt-xl-7'>
        <h3 style={{display: "flex", flexDirection: "column"}}>
            <span className='card-label fw-bold fs-3 mb-1'>Usinas - Importadas <KTSVG path="/media/icons/duotune/arrows/arr016.svg" className="svg-icon-success svg-icon-2hx" /></span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total: {plants.length} usinas importadas</span>
        </h3>
        
        <div className='card-toolbar'>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {
        loading ? 
          (
            <div className='card-body pt-2' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{display: "flex", flexDirection: "column"}}>
                <Loading myClass='spinner-border text-secondary'/>
                {/* <span className='text-muted mt-1 fw-semibold fs-7'>Carregando...</span> */}
              </h3>
            </div>
          ) 
        : 
          plants.length > 0 
            ?
              (<div className='card-body pt-2' style={{overflowY: "auto", height: '25rem'}}>
                <form noValidate>
                  {plants.map((plant : any) =>{
                    return (
                      <div className='d-flex align-items-center mb-7'>
                        <div className='symbol symbol-50px me-5'>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                        <div className='flex-grow-1'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {plant.plantName}
                          </a>
                          <span className='text-muted d-block fw-semibold'>ID Usina: {plant.plantId}</span>
                        </div>
                        <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                          <input 
                            className='form-check-input' 
                            type='checkbox'
                            checked={plant.imported}
                            value={plant.plantId}
                            /*onChange={(e) =>{
                              updateData(e.target.value, e.target.checked)
                            }}*/ 
                          />
                        </div>
                      </div>
                    )
                  })}
                </form>
              </div>)
            :
              (
                <div className='card-body pt-2' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <h3 style={{display: "flex", flexDirection: "column"}}>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas importadas.</span>
                  </h3>
                </div>
              )
      }
      {/* end::Body */}
    </div>
  )
}

export {TablePlantImported}
