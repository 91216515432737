/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'


import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { postUsersAll, updateUsersAll } from '../../../../services/usersService'
import { useAuth } from '../../../../modules/auth'
import { ModalSaveRedefinationPassword } from './components/stepModal/Components/ModalSaveRedefinationPassword'
import { useToastModal } from '../../../components/ToastModal'

type DataForm = {
  email: string | null,
  name: string | null,
  role: string | null,
  idCompany?: string | null,
  idUnitBranch?: string | null,
  idClient?: string | null
}

type Props = {
  show: boolean;
  id: any;
  data: any;
  handleClose: () => void;

  setMyUserPassword?: any;
  setTypeModalPassword?: any;
  setShowCreateAppModalPassword?: any;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Informe um e-mail. "),
  name: Yup.string().required("Informe um nome para o usuáro"),
  role: Yup.string().required("Informe um tipo de usuário"),
  idCompany: Yup.string(),
  idUnitBranch: Yup.string(),
  idClient: Yup.string()
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateUser = ({
  show, 
  handleClose, 
  data, 
  id,
  setMyUserPassword,
  setTypeModalPassword,
  setShowCreateAppModalPassword
}: Props) => {

  const { toggleToast } = useToast();
  const { toggleToastModal } = useToastModal();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const {currentUser} = useAuth()

  const [initialValues, setInitialValues] = useState<DataForm>({
    email: null,
    name:  null,
    role: null,
    idCompany: currentUser?.companies && currentUser?.companies?.length > 0 ? currentUser.companies[0].id : null,
    idUnitBranch: null,
    idClient: null,
  })
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  // const [showCreateAppModalPassword, setShowCreateAppModalPassword] = useState<boolean>(false);
  // const [myUser, setMyUserPassword] = useState<any>(null);
  // const [typeModalPassword, setTypeModalPassword] = useState<string | null>(null);
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.name
        || !formik.values.email 
        || !formik.values.role) {
      return false
    }
    if(formik.values.role === 'CLIENTE'){
      if(!formik.values.idClient){
        return false
      }
    }
    if(formik.values.role === 'FILIAL'){
      if(!formik.values.idUnitBranch){
        return false
      }
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postUserSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        email: formik.values.email,
        name: formik.values.name,
        role: formik.values.role,
        idCompany: formik.values.idCompany,
        idUnitBranch: formik.values.idUnitBranch,
        idClient: formik.values.idClient
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      const result = await postUsersAll(myData)
      if(result){
        const myResult = {
          email: result.email,
          senha: result.senha ? result.senha : ""
        }
        setMyUserPassword(myResult)
        setTypeModalPassword('cadastro')
        setShowCreateAppModalPassword(true)
      }
      toggleToast("Sucesso ao cadastrar o usuário. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar o usuário. ", "error")

    }finally{
      setLoading(false)
      //closed()

      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 2000);
    }
  }

  const updateUserSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        email: formik.values.email,
        name: formik.values.name,
        role: formik.values.role,
        idCompany: formik.values.idCompany,
        idUnitBranch: formik.values.idUnitBranch,
        idClient: formik.values.idClient
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateUsersAll(id, myData)
      toggleToast("Sucesso ao atualizar o usuário. ", "success")
    
    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar o usuário. ", "error")
    }finally{
      setLoading(false)
      closed()

      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 3000);
    }
  }

  const submit = () => {
    
    if(id){
      updateUserSubmit(id)
    }else{
      postUserSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        email: data.email ? data.email :  null,
        name: data.name ? data.name : null,
        role: data.role ? data.role : null,
        idCompany: data.idCompany ? data.idCompany : null,
        idUnitBranch: data.idUnitBranch ? data.idUnitBranch : null,
        idClient: data.idClient ? data.idClient : null
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      //dialogClassName='modal fade show d-block'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Usuário {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal 
                data={initialValues} 
                updateData={updateData} 
                hasError={hasError} 
                formik={formik} 
                handleClose={handleClose}
                id={id}
                setMyUserPassword={setMyUserPassword}
                setTypeModalPassword={setTypeModalPassword}
                setShowCreateAppModalDelete={setShowCreateAppModalPassword}
              />

              
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end', marginBottom :'1rem'}}>
                <div style={{display: 'flex', gap: '1rem'}}>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                
                </div>
              </div>
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}

        {/* <ModalSaveRedefinationPassword 
          show={showCreateAppModalPassword}
          data={myUser}
          type={typeModalPassword ? typeModalPassword : undefined}
          handleClose={() => {
            setLoading(false)
            closed();

            setShowCreateAppModalPassword(false);
            myUser(null);
            setTypeModalPassword(null);


        }} 
      /> */}

      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateUser}
