/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { StepperComponent } from '../../../../../../_metronic/assets/ts/components';
import { useToast } from '../../../Toats';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../Loadings/loadingSpan';
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";

type Props = {
    data: any;
    show: boolean;
    plantData: any;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalExportCharts = ({show, handleClose, data, plantData}: Props) => {


  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null); 

  const [dateAntigaMetaDates, setDateAntigaMetaDates] = useState<string>("");
  const [dateAtualMetaDates, setDateAtualMetaDates] = useState<string>(""); 
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const handleSubmitExport = (newStartDate: any, newEndDate: any) => {
    console.log('start: ', newStartDate, 'stop: ', newEndDate);

    const start = newStartDate.toISOString().split('T')[0];
    const stop = newEndDate.toISOString().split('T')[0];

    const filteredData = data.dias
        .map((dia, index) => ({
            dia,
            valor: data.valores[index]
        }))
        .filter(({ dia }) => dia >= start && dia <= stop);

    const csvContent = "data:text/csv;charset=utf-8," +
        "Dia,Gerado(KWh)\n" +
        filteredData.map(({ dia, valor }) => `${dia},${valor}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `geracao-${plantData && plantData.name ? plantData.name : "usina"}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  const handleChartDeploy = () => {
    if (data && Object.keys(data).length > 0) {
      const myMetaDates =  data["dias"];
  
      if(myMetaDates.length > 0){
        const mostRecentDate = myMetaDates.reduce((a, b) => (a > b ? a : b));
        const oldestDate = myMetaDates.reduce((a, b) => (a < b ? a : b));

        setDateAntigaMetaDates(oldestDate)
        setDateAtualMetaDates(mostRecentDate)
      }
    } 
  };


  const submit = () => {
    handleSubmitExport(startDate, endDate)
    //deleteClientSubmit()
  }

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    handleChartDeploy()
  }, [data, startDate, endDate])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Exportação de dados (geração)</span>
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          <div className='flex-row-fluid py-lg-0'>
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                
                <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Selecione o período para a exportação: </span>
                </h3>

                <DatePicker
                  showIcon
                  isClearable
                  className="form-control form-control-sm"
                  selected={startDate}
                  onChange={handleChange}
                  selectsRange
                  showPreviousMonths
                  startDate={startDate}
                  endDate={endDate}
                  locale={ptBR}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').setDate(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').getDate() + 1))}
                  maxDate={new Date(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').setDate(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').getDate() + 1))}
                />
            </div>

          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <button
              type='button'
              className='btn btn-lg btn-light'
              disabled={loading}
              onClick={() => {
                  closed()
              }}
          >
              <KTSVG
                path='/media/icons/duotune/arrows/arr063.svg'
                className='svg-icon-3 ms-2 me-0'
              />
              <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
              
          </button>

          <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={loading}
              onClick={submit}
          >
              Exportar
              {
                loading ? 
                <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
              }
          </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalExportCharts}
