/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getClientAllPagination } from '../../services/clientService';
import { Loading } from '../components/Loadings/loading';
import { TableClients } from './components/TableClients';
//import { MyPortal } from './components/my-portal'

type Paginations = {
  page: number,
  limit: number,
  total: number,
  qtdPages: number
}

type SetPaginations = {
  start: number;
  end: number;
};

const clientsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClientsPage: FC = () => {
    const [clients, setClients] = useState<any>([]);
    const [clientsLoading, setclientsLoading] = useState<any>(true);
    const [pagination, setPagination] = useState<Paginations>({
      page: 1,
      limit: 25,
      total: 0,
      qtdPages: 1
    });

    const [qtd, setQtd] = useState<SetPaginations>({
      start: 0,
      end: 25,
    });

    const getClients = async () => {
        try {
          setclientsLoading(true)
          const myClient = await getClientAllPagination(
            undefined,
            undefined,
            undefined,
            pagination.limit,
            pagination.page
          )
          if(myClient){
            setPagination({...pagination, qtdPages: myClient.lastPage, total: myClient.total})
            setClients(myClient.data)
          }

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os clientes!", "error")
        }finally{
          setclientsLoading(false)
        }
    }

    const refresh = () => {
      getClients()
    }

    useEffect(() => {
      getClients()
    }, [pagination.page]);

    useEffect(() => {
      const start = (pagination.page - 1) * pagination.limit + 1;
      const end = Math.min(pagination.page * pagination.limit, pagination.total);
  
      setQtd({start: start, end: end === 0 ? pagination.limit : end})
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.page]);



  return (
    <div className='column g-5 g-xl-8'>
        {clientsLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            // 
            <TableClients
              className='card-xl-stretch mb-xl-8'
              clients={clients && clients.length > 0 ? clients : []}
              refresh={refresh} 
              pagination={pagination}
              setPagination={setPagination}
              qtd={qtd}
            />
        )
    }
    </div>
  )
}

const ClientsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsBreadcrumbs}>Gerenciamento de Clientes</PageTitle>
      <ClientsPage />
    </>
  )
}

export {ClientsWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

