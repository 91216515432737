/* eslint-disable jsx-a11y/anchor-is-valid */
//import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import React, { useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Plants } from '../../../../../@types/plants';
import { ModalPlant } from '../../../../components/modalPlant';
import useSize from '../../../../../hooks/useSize';
import { ModalArquivePlant } from '../ModalArquivePlant';
import { useAuth } from '../../../../../modules/auth';
import { ModalDeletePlant } from '../ModalDeletePlant';
import { formatDateInverter } from '../../../../../utils/date';
import styles from './tablePlantsSearchComputer.module.css';

type Props = {
  plants?: Plants[] | []
  refresh: () => void;
}

export function TablePlantsSearchComputer({plants, refresh}: Props) {
  const [myPlant, setMyPlant] = useState<string>("");
  const [myPlantObject, setMyPlantObject] = useState<Plants | null>(null);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false);
  const [showCreateAppModalDeleteV2, setShowCreateAppModalDeleteV2] = useState<boolean>(false);
  const {width} = useSize();
  const user = useAuth();

  const closedModal = (active: boolean) => {
    setMyPlant("")
    setShowCreateAppModal(active)
  }

  return (
    <table className={`table table-hover align-middle ${width < 1200 ? '' : 'gs-0 gy-4'} `}>
      <thead>
        <tr className='fw-bold text-muted bg-light '>
          <th className='ps-4 min-w-325px rounded-start'>Name</th>
          <th className='min-w-250px text-start'>Marca</th>
          <th className='min-w-200px'>Endereço</th>
          <th className='min-w-80px'>Capacidade</th>
          <th className='min-w-70px text-center'>1D</th>
          <th className='min-w-70px text-center'>15D</th>
          <th className='min-w-70px text-center'>30D</th>
          <th className='min-w-70px text-center'>12M</th>

          <th className='min-w-50px'>Alarmes</th>
          <th className='min-w-100px text-end rounded-end'></th>
        </tr>
      </thead>
      <tbody>
          {
            plants && plants.length > 0 ?
              plants.map((plant: Plants, index: number) => {
                return (
                  <tr
                    key={index}
                    className={
                      (plant.arquive !== 'ARQUIVADA') && ((plant.mark && plant.mark.active === true) 
                      || 
                      (plant.credential && plant.credential.mark && plant.credential.mark.active === true)) 
                      ? "" 
                      : styles.disabled
                    }
                    onClick={() => {
                      setMyPlant(plant.id)
                      setShowCreateAppModal(true)
                    }} 
                  >
                    <td>
                      <div className='d-flex align-items-center'>
                          <span
                            className={`bullet bullet-vertical h-50px bg-${
                              plant.status !== null
                                ? plant.status === 0
                                ? "success"
                                : plant.status === 1
                                ? "danger"
                                : plant.status === 2
                                ? "warning"
                                : plant.status === 7 || plant.status === 3
                                ? "info"
                                : "secondary"
                              : "secondary"
                            } ${styles.spanMarcador}`}
                          />

                          <div className='symbol symbol-50px me-5'>
                            <img
                                src='https://res.cloudinary.com/deq3ehxe3/image/upload/v1735238752/plant2_tuwshh.png'
                                className=''
                                alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {plant && plant.name ? plant.name : "Usina"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {plant && plant.createDate ? formatDateInverter(plant.createDate) : "0000-00-00"}
                            </span>
                          </div>

                          <div className={styles.divTdPlano}>
                            <span className={`badge badge-light-primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                              {plant.plano
                              ? plant.plano : ""
                              }
                            </span>
                          </div>
                      </div>
                    </td>

                    <td>
                      <div className={styles.divMarkImg}>
                        <div className='symbol symbol-30px me-4 p-1 bg-light' >
                          {plant && plant.mark && plant.mark.link_imagem ? 
                            (
                              <img
                                src={toAbsoluteUrl(`${plant.mark.link_imagem}`)}
                                className=''
                                alt=''
                              />
                            ) 
                            :
                            (
                              <KTSVG path='/media/icons/duotune/coding/cod009.svg' className='svg-icon-2x svg-icon-dark'/>
                            )
                          }
                        </div>
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {
                              plant && plant.mark 
                              ? plant.mark.name 
                              : plant.credential && plant.credential.mark 
                              ? plant.credential.mark.name 
                              : "Não encontrado. "
                            }
                        </a>
                      </div>
                    </td>

                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {plant && plant.addressReduce ? plant.addressReduce : plant.address ? plant.address : "Não encontrado. "}
                      </a>
                    </td>

                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {plant && plant.capacity ? plant.capacity : "Usina"}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>KWp</span>
                    </td>

                    <td>
                      <div className={styles.divTdPercent}>
                        <span className={`badge badge-light-${plant.percentage_day ? plant.percentage_day >= 100 ? "success" : "danger" : "dark"} primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                          
                          {plant.percentage_day ? 
                            plant.percentage_day >= 100 
                            ? <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className={`svg-icon-3 svg-icon-success`} /> 
                            : <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className={`svg-icon-3 svg-icon-danger`} />  
                            : <KTSVG path='/media/icons/duotune/general/gen052.svg' className={`svg-icon-3 svg-icon-dark`} />  
                          }
                          <span className={styles.spanTdPercent}> {plant.percentage_day ? plant.percentage_day : 0}%</span>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className={styles.divTdPercent}>
                        <span className={`badge badge-light-${plant.percentage_15days ? plant.percentage_15days >= 100 ? "success" : "danger" : "dark"} primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                          
                          {plant.percentage_15days ? 
                            plant.percentage_15days >= 100 
                            ? <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className={`svg-icon-3 svg-icon-success`} /> 
                            : <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className={`svg-icon-3 svg-icon-danger`} />  
                            : <KTSVG path='/media/icons/duotune/general/gen052.svg' className={`svg-icon-3 svg-icon-dark`} />  
                          }
                          <span className={styles.spanTdPercent}> {plant.percentage_15days ? plant.percentage_15days : 0}%</span>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className={styles.divTdPercent}>
                        <span className={`badge badge-light-${plant.percentage_30days ? plant.percentage_30days >= 100 ? "success" : "danger" : "dark"} primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                          
                          {plant.percentage_30days ? 
                            plant.percentage_30days >= 100 
                            ? <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className={`svg-icon-3 svg-icon-success`} /> 
                            : <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className={`svg-icon-3 svg-icon-danger`} />  
                            : <KTSVG path='/media/icons/duotune/general/gen052.svg' className={`svg-icon-3 svg-icon-dark`} />  
                          }
                          <span className={styles.spanTdPercent}> {plant.percentage_30days ? plant.percentage_30days : 0}%</span>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div className={styles.divTdPercent}>
                        <span className={`badge badge-light-${plant.percentage_12mouth ? plant.percentage_12mouth >= 100 ? "success" : "danger" : "dark"} primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                          
                          {plant.percentage_12mouth ? 
                            plant.percentage_12mouth >= 100 
                            ? <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className={`svg-icon-3 svg-icon-success`} /> 
                            : <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className={`svg-icon-3 svg-icon-danger`} />  
                            : <KTSVG path='/media/icons/duotune/general/gen052.svg' className={`svg-icon-3 svg-icon-dark`} />  
                          }
                          <span className={styles.spanTdPercent}> {plant.percentage_12mouth ? plant.percentage_12mouth : 0}%</span>
                        </span>
                      </div>
                    </td>

                    <td>
                      {plant.alarmsCount && plant.alarmsCount > 0 ? (
                        <div className={styles.divTdAlarm}>
                          <a 
                            href="#"
                            data-bs-toggle='tooltip'
                            title={`Alarmes: ${plant.alarmsCount}`}
                          >
                            <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-danger svg-icon-2hx"/>
                          </a>
                        </div>
                      ) : <></>}
                    </td>

                    <td className={`text-end ${styles.divTdButtons}`}>
                      {user.currentUser?.role.slug !== "CLIENTE" && 
                        <a href='#' 
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={(event) => {
                            event.stopPropagation();
                            setMyPlantObject(plant)
                            setShowCreateAppModalDelete(true)
                          }}
                        >
                            <KTSVG path='/media/icons/duotune/files/fil012.svg' className='svg-icon-3' />
                        </a>
                      }
                      {user.currentUser?.role.slug !== "CLIENTE" && 
                        <a href='#' 
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={(event) => {
                            event.stopPropagation();
                            setMyPlantObject(plant)
                            setShowCreateAppModalDeleteV2(true)
                          }}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                        </a>
                      }
                    </td>
                  </tr>
                )
              })
            : 
              <tr>
                <td>
                  <div className={styles.divNotPlants}>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='text-muted mt-1 fw-semibold fs-5'>Usinas não encontradas!</span>
                    </h3>
                  </div>
                </td>
              </tr>
          }
      </tbody>

      <tfoot>
        <ModalPlant 
          show={showCreateAppModal} 
          handleClose={() => {
            closedModal(false)
            refresh()
          }} 
          plant={myPlant}
        />

        <ModalArquivePlant 
          show={showCreateAppModalDelete}
          data={myPlantObject}
          id={myPlantObject ? myPlantObject.id : undefined} 
          handleClose={() => {
            setMyPlantObject(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />

        <ModalDeletePlant 
          show={showCreateAppModalDeleteV2}
          data={myPlantObject}
          id={myPlantObject ? myPlantObject.id : undefined} 
          handleClose={() => {
            setMyPlantObject(null)
            setShowCreateAppModalDeleteV2(false)
            refresh()
          }} 
        />
      </tfoot>
    </table>
  );
}