/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styles from './menuFilter.module.css';

type AlarmStatatus = {
  attention: number,
  alarmNum: number,
  normalNum: number,
  offlineNum: number,
  total: number,
  info: number,
  alertCount: number
}

type Props = {
  alarmStatus: AlarmStatatus;
  setFilterAlarms: React.Dispatch<React.SetStateAction<number>>;
}

export function MenuFilterPlantsComputerGlobal({setFilterAlarms, alarmStatus} : Props) {
  return (
    <div className={styles.divPlantsFiltersV1}>
      <div className={`card-toolbar nav nav-tabs ${styles.divPlantsFiltersV2}`} data-kt-buttons='true'>
        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(-1)
          }}
        >
          Total 
          <span 
            className={`badge badge-dark ${styles.spanFilter}`}
          >
            {alarmStatus.total}
          </span>
        </a>

        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(0)
          }}
        >
          Normais
          <span 
            className={`badge badge-success ${styles.spanFilter}`}
          >
            {alarmStatus.normalNum}
          </span>
        </a>

        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(2)
          }}
        >
          Em alerta
          <span 
            className={`badge badge-warning ${styles.spanFilter}`}
          >
            {alarmStatus.attention}
          </span>
        </a>

        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(1)
          }}
        >
          Offline
          <span 
            className={`badge badge-danger ${styles.spanFilter}`}
          >
            {alarmStatus.offlineNum}
          </span>
        </a>

        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(3)
          }}
        >
          Informativo
          <span 
            className={`badge badge-info ${styles.spanFilter}`}
          >
            {alarmStatus.info}
          </span>
        </a>

        <a
          className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 nav-item'
          data-bs-toggle="tab"
          href="#"
          onClick={()=> {
            setFilterAlarms(-2)
          }}
        >
          Com Alarme
          <span 
            className={`badge badge-primary ${styles.spanFilter}`}
          >
            {alarmStatus.alertCount}
          </span>
        </a>
      </div>
    </div>
  );
}