/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getClientAll } from "../../../../../../services/clientService";
import { getRoleAll } from "../../../../../../services/roles";
import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
import { Loading } from "../../../../../components/Loadings/loading";
import { getCompanyAll } from "../../../../../../services/companyService";
import { LoadingSpan } from "../../../../../components/Loadings/loadingSpan";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { updatePasswordOne } from "../../../../../../services/usersService";
import { useToast } from "../../../../../components/Toats";


const StepModal = ({
  data, 
  updateData, 
  hasError, 
  formik, 
  handleClose, 
  id,
  setMyUserPassword,
  setTypeModalPassword,
  setShowCreateAppModalDelete,

}: any) => {
  const { toggleToast } = useToast();
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);

  const [roles, setRoles] = useState<any[]>([]);
  const [roleSelect, setRoleSelect] = useState<any>(formik.values.role ? formik.values.role : "");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPassword, setLoadingPassword] = useState<boolean>(false);
  
  const getUsersAllClients = async () => {
    try {
      setLoading(true)
      const users = await getClientAll(
        "",
        false,
        id
      )
      setClients(users)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de usuarios. ", "error")
      
    }finally{
      setLoading(false)
    }
  }

  const getUsersAllUnitBranchs = async () => {
    try {
      setLoading(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }

  const getCompanies = async () => {
    try {
      setLoading(true)
      const myCompanies = await getCompanyAll()
      setCompanies(myCompanies)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de empresas. ", "error")

    }finally{
      setLoading(false)
    }
  }

  const getRoles = async () => {
    try {
      setLoading(true)
      const roles = await getRoleAll()
      setRoles(roles)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os tipos de usuário. ", "error")

    }finally{
      setLoading(false)
    }
  }

  const postReenvioSenha = async (email: string) => {
    try {
      setLoadingPassword(true)
      const body = {
        email: email
      }
      const result = await updatePasswordOne(body)
      // const result = {
      //   senha: 'abcde'
      // }

      if(result){
        const myResult = {
          email: email,
          senha: result.senha ? result.senha : ""
        }
        setMyUserPassword(myResult)
        setTypeModalPassword('reenvio')
        setShowCreateAppModalDelete(true)
      }
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao redefinir a senha do usuário. ", "error")

    }finally{
      setLoadingPassword(false)
    }
  }



  // const getPlants = async () => {
  //   try {
  //     setLoading(true)
  //     let plants = await getplantsAll()
  //     plants = plants.map((plant: any) => {
  //       const exist = formik.values.idsPlants.some((id: string) => id === plant.id)
  //       plant.imported = exist ? true : false
  //       return plant
  //     })
  //     plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
      
  //     setPlants(plants)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar a lista de unidades. ", "error")

  //   }finally{
  //     setLoading(false)
  //   }
  // }

  useEffect(() => {
    const fuction_async = async () =>{
      await getUsersAllClients()
      await getUsersAllUnitBranchs()
      await getRoles()
      await getCompanies()
      //await getPlants()
    }
    fuction_async()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRoleSelect(formik.values.role)
  }, [formik.values.role])

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>
        {/*begin::Form Group */}
        {/* <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>Usuário</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Avatar do usuário'
          ></i>
        </label> */}
        {/*<div
          className='image-input image-input-outline'
          data-kt-image-input='true'
          style={{backgroundImage: `url('${blankImg}')`}}
      >*/}
              {/* <div
                className='image-input-wrapper w-125px h-125px'
                style={{backgroundImage: `url('${userAvatarImg}')`}}
              ></div> */}
              {/* end::Preview existing avatar */}

              {/* begin::Label */}
              {/* <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='bi bi-pencil-fill fs-7'></i>

              <input type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label> */}
              {/* end::Label */}

              {/* begin::Cancel */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* end::Cancel */}

              {/* begin::Remove */}
              {/* <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
            >
              <i className='bi bi-x fs-2'></i>
            </span> */}
              {/* end::Remove */}
       {/* </div>*/}        
        <div style={{display: 'flex', gap: '1rem', marginTop: '0rem'}}>
          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Name</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um nome para a unidade(filial)'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='name'
              placeholder=''
              value={formik.values.name}
              onChange={formik.handleChange("name")}
            />
            {!formik.values.companyName && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe um nome para o usuário
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{flex: '1'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>E-mail</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite um e-mail para o9 usuário'
              ></i>
            </label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='email'
              placeholder='name@example.com'
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              
            />
            {!formik.values.emailCompany && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  O e-mail é obrigatório!
                </div>
              </div>
            )}
          </div>

          {id && <div className='fv-row mb-10' style={{display: 'flex', alignItems: 'end'}}>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={loadingPassword}
              //data-kt-stepper-action='submit'
              onClick={() => {
                postReenvioSenha(formik.values.email ? formik.values.email : "")
              }}
            >
              Reenviar Senha
              {
                loadingPassword ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
              }
          </button>
          </div>}
        </div>

        <div className='mb-7'>
          <label className='required fw-bold fs-6 mb-5'>Role</label>
          {roles && roles.length > 0  && (
            roles.map((role: any) => {
              return (
                <div className=''>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('role')}
                      name='role'
                      type='radio'
                      value={role.slug}
                      id='kt_modal_update_role_option_0'
                      onChange={(e: any) => {
                        setRoleSelect(e.target.value)
                        formik.handleChange("role")(e)
                      }}
                      checked={formik.values.role == `${role.slug}`}
                      //disabled={formik.isSubmitting || isUserLoading}
                    />
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800'>{role.slug}</div>
                      <div className='text-gray-600'>
                        {role.description}
                      </div>
                    </label>
                  </div>
                  <div className='separator separator-dashed my-5'></div>
                </div>
              )
            })
          )}
          
          
        </div>
        

        {(roleSelect === "CLIENTE" || roleSelect === "FILIAL") && (
          <div style={{marginTop: '3.5rem'}}>
            <div style={{marginBottom: '2rem'}}>
              <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                  <span className='card-label fw-bold fs-3 mb-1'>Unidade (filial) {" "}</span>
                  <span className='text-muted mt-1 fw-semibold fs-5'>Unidade filial vinculada ao usuário</span>
              </h3>
            </div>

            <div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Lista de Unidades (Filiais)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                    onChange={formik.handleChange("idUnitBranch")}
                  >
                    <option value={'1'}></option>
                    {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                      return (
                        <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                      )
                    })}
                    
                  </select>
                  {!formik.values.idUnitBranch && hasError ? (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A unidade filial é obrigatória!
                        </div>
                      </div>
                    ) : <></>}
                </div>
              </div>
            </div>
          </div>
        )}

        {roleSelect === "EMPRESA" && (
          <div style={{marginTop: '3.5rem'}}>
            <div style={{marginBottom: '2rem'}}>
              <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                  <span className='card-label fw-bold fs-3 mb-1'>Empresa {" "}</span>
                  <span className='text-muted mt-1 fw-semibold fs-5'>Empresa vinculada ao usuário</span>
              </h3>
            </div>

            <div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Lista de Empresas</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    //title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idCompany ? formik.values.idCompany : '1'}
                    onChange={formik.handleChange("idCompany")}
                  >
                    <option value={'1'}></option>
                    {companies && companies.map((company: any) => {
                      return (
                        <option key={company.id} value={company.id}>{company.corporateName}</option>
                      )
                    })}
                    
                  </select>
                  {/* {!formik.values.idCompany && hasError ? (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          A empresa é obrigatória!
                        </div>
                      </div>
                    ) : <></>} */}
                </div>
              </div>
            </div>
          </div>
        )}

        {roleSelect === "CLIENTE" && (
          <div style={{marginTop: '3.5rem'}}>
              <div style={{marginBottom: '2rem'}}>
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                    <span className='card-label fw-bold fs-3 mb-1'>Clientes {" "}</span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Cliente vinculado ao usuário</span>
                </h3>
              </div>

              <div>
                <div className='fv-row mb-10'>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span>Lista de Clientes</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Selecione para qual unidade filial essa credencial será vinculada. '
                    ></i>
                  </label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={formik.values.idClient ? formik.values.idClient : '1'}
                      onChange={formik.handleChange("idClient")}
                      // onChange={(event: any) => {
                      //   const idClient = event.target.value;

                      // }}
                    >
                      <option value={'1'}></option>
                      {clients && clients.length > 0 && clients.map((client: any) => {
                        return (
                          <option key={client.id} value={client.id}>{client.nameResponsible}</option>
                        )
                      })}
                      
                    </select>
                    {!formik.values.idClient && hasError && (
                      <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                          O cliente é obrigatório!
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
          </div>
        )}

        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {StepModal}

